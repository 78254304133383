import React, { useEffect, useMemo, useState } from 'react';
import Plot, { Figure } from 'react-plotly.js';
import AutoSizer from 'react-virtualized-auto-sizer';
import { Data } from 'plotly.js';
import { generateUUID } from 'three/src/math/MathUtils';

import { getLayout } from './utils';

interface PolarPlotProps {
  plotData: Partial<Data>[];
  isSmallHeightScreen: boolean;
  dbAxisRange: number[];
  onInitialized?: (figure: Readonly<Figure>) => void;
  onUpdate?: (figure: Readonly<Figure>) => void;
}

export const PolarPlot: React.FC<PolarPlotProps> = ({
  plotData,
  isSmallHeightScreen,
  dbAxisRange,
  onInitialized,
  onUpdate,
}) => {
  const [plotKey, setPlotKey] = useState(generateUUID());

  const layout = useMemo(
    () => getLayout(dbAxisRange, isSmallHeightScreen),
    [plotData, dbAxisRange, isSmallHeightScreen]
  );

  /** Hacky hack to make sure the radial axis is correct on initial render as well as after zooming or resetting the view */
  useEffect(() => {
    setPlotKey(generateUUID());
  }, [layout]);

  return (
    <AutoSizer>
      {({ height, width }) => (
        <Plot
          key={plotKey}
          data={plotData}
          layout={layout}
          config={{ displaylogo: false, responsive: true, scrollZoom: true }}
          style={{ height, width }}
          useResizeHandler={true}
          onInitialized={onInitialized}
          onUpdate={onUpdate}
        />
      )}
    </AutoSizer>
  );
};
