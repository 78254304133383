import { toast } from 'react-toastify';

import { getAudioSettings } from '@/components/AuralizerStandalone/utils/getAudioSettings';
import { getFirstSimNormMax } from '@/components/AuralizerStandalone/utils/getFirstSimNormMax';
import { AudioEngine } from '../AudioEngine';
import { ActionType, useAuralizerContext } from '../AuralizerContext';
import { getURLsFromSimID } from './useGetURLsFromSimId';

import { createConvolverNodesConcurrent } from '../utils/createConvolverNodesConcurrent';

import { AudioNodesDict, AudioSettings, RecUrl, SimSrcRecHash } from '../types';
import { Simulation } from '@/types';

export const useConnectNewIRS = () => {
  const { dispatch, audioNodesDict } = useAuralizerContext();

  const connectNewIRS = async (
    sim: Simulation,
    originalSim: Simulation,
    currentSourceIdList: string[],
    currentReceiverIdList: string[],
    simSrcRecHash: SimSrcRecHash | null
  ) => {
    try {
      let recUrls: RecUrl = {};
      const audioEngine = AudioEngine.getInstance();

      if ((sim && audioEngine.recUrlsObject[sim.id] === undefined) || audioEngine.recUrlsObject[sim.id] === null) {
        recUrls = await getURLsFromSimID(sim.id, sim?.lastSimulationRun?.taskType ?? '');
        audioEngine.recUrlsObject[sim.id] = recUrls;
      } else {
        recUrls = audioEngine.recUrlsObject[sim.id];
      }

      if (simSrcRecHash) {
        let newAudioSettings: AudioSettings = {
          ...audioEngine.audioSettings,
          [sim.id]: getAudioSettings(recUrls, simSrcRecHash, sim.id, originalSim.id),
        };
        newAudioSettings = getFirstSimNormMax(newAudioSettings, originalSim.id);
        audioEngine.audioSettings = newAudioSettings;

        if (Object.keys(recUrls).length > 0) {
          const sourceIdArray = currentSourceIdList;
          const receiverList = currentReceiverIdList;
          if (audioNodesDict === null || audioNodesDict[sim.id] === undefined) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const sourceRecPairs: any[] = [];
            // go ahead
            // create source receiver couples
            sourceIdArray.forEach((sourceId: string) =>
              receiverList.forEach((recId: string) => sourceRecPairs.push({ sId: sourceId, rId: recId }))
            );

            const batchedPromises = [];

            for (const pair of sourceRecPairs) {
              // Create promises to batch and use for Promise.all
              const convolverNodePromise = createConvolverNodesConcurrent(
                recUrls,
                sim.id,
                originalSim,
                simSrcRecHash,
                pair
              );
              batchedPromises.push(convolverNodePromise);
            }

            Promise.all(batchedPromises).then((audioNodes) => {
              const newAudioNodesDict: AudioNodesDict = audioNodes.reduce((acc, curr) => {
                return {
                  convolvers: {
                    ...acc.convolvers,
                    [curr.sourceId]: {
                      ...acc.convolvers?.[curr.sourceId],
                      [curr.receiverId]: curr.convolverNodeList,
                    },
                  },
                  receiverGains: {
                    ...acc.receiverGains,
                    [curr.sourceId]: {
                      ...acc.receiverGains?.[curr.sourceId],
                      [curr.receiverId]: curr.inputNodeList,
                    },
                  },
                  mergerNodes: {
                    ...acc.mergerNodes,
                    [curr.sourceId]: {
                      ...acc.mergerNodes?.[curr.sourceId],
                      [curr.receiverId]: curr.mergerNodeList,
                    },
                  },
                  convolverIds: {
                    ...acc.convolverIds,
                    [curr.sourceId]: {
                      ...acc.convolverIds?.[curr.sourceId],
                      [curr.receiverId]: curr.convolverIds,
                    },
                  },
                };
              }, {} as AudioNodesDict);

              dispatch({
                type: ActionType.SET_AUDIO_NODE_DICT,
                audioNodesDict: newAudioNodesDict,
                simId: sim.id,
              });

              dispatch({
                type: ActionType.SET_FETCHING,
                isFetching: false,
                simId: sim.id,
              });
            });
          }
        }
      } else {
        toast.error('No source results files available');
      }
    } catch (error) {
      toast.error('No source results files available');
    }
  };

  return connectNewIRS;
};
