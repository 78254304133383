import { AuralizerPanel } from './components/AuralizerPanel';
import { AuralizerProvider } from './AuralizerContext';
import { PresetProvider } from './PresetContext';

export const Auralizer = () => {
  return (
    <AuralizerProvider>
      <PresetProvider>
        <AuralizerPanel />
      </PresetProvider>
    </AuralizerProvider>
  );
};
