import React, { MouseEventHandler, useCallback, useRef, useState } from 'react';
import Plot, { Figure } from 'react-plotly.js';
import AutoSizer from 'react-virtualized-auto-sizer';
import { Layout } from 'plotly.js';

import { ReflectionDetails } from '@/components/Results/context/ReflectogramResultsContext';

import { PlotHeader } from './PlotHeader';

import { useReflectionsPlotData } from './hooks/useReflectionsPlotData';

import { TimeOfArrivalGroup } from '../../SubHeaders/ReflectogramResultsHeader/constants';

import { convertToDataCoordinates, getLayoutConfig } from './utils';

import { AxisType } from './types';

import styles from './styles.module.scss';

type ReflectionsPlotProps = {
  data: ReflectionDetails[];
  selectedReflectionIndex: number | null;
  selectedFrequencyBandIndex: number | null;
  timeOfArrivalGroups: TimeOfArrivalGroup[];
  onSelectReflection: (index: number) => void;
};

export const ReflectionsPlot: React.FC<ReflectionsPlotProps> = ({
  data,
  selectedReflectionIndex,
  selectedFrequencyBandIndex,
  timeOfArrivalGroups,
  onSelectReflection,
}) => {
  const plotContainerRef = useRef<HTMLDivElement>(null);
  const [layout, setLayout] = useState<Partial<Layout> | null>(null);

  const [selectedXAxisType, setSelectedXAxisType] = useState<AxisType>('ordered');
  const plotData = useReflectionsPlotData({
    data,
    selectedReflectionIndex,
    selectedFrequencyBandIndex,
    timeOfArrivalGroups,
    selectedXAxisType,
  });

  const handlePlotClick: MouseEventHandler<HTMLDivElement> = (event) => {
    const plotContainerElement = plotContainerRef.current;
    const plotAreaElement = plotContainerElement?.querySelector('.nsewdrag.drag') as HTMLElement;
    if (!plotAreaElement || !layout) return;

    const boundingRect = plotAreaElement.getBoundingClientRect();
    const clickX = event.clientX - boundingRect.left;
    const clickY = event.clientY - boundingRect.top;

    // Access the axis ranges from the layout
    const xaxisRange = layout.xaxis?.range as [number, number];
    const yaxisRange = layout.yaxis?.range as [number, number];

    if (!xaxisRange || !yaxisRange) return;

    // Convert the clicked pixel coordinates to data coordinates
    const { clickedX, clickedY } = convertToDataCoordinates(clickX, clickY, boundingRect, xaxisRange, yaxisRange);

    let closestIndex = -1;
    let minDistanceX = Infinity;
    let minDistanceY = Infinity;

    data.forEach((d, index) => {
      const xValue = selectedXAxisType === 'linear' ? d.timeOfArrivalRelative * 1000 : index + 1;
      const yValue = d.spl; // Ensure this is correctly matched with the plot's Y-axis data
      const distanceX = Math.abs(xValue - clickedX);
      const distanceY = Math.abs(yValue - clickedY);

      // Prioritize x-distance, then y-distance
      if (distanceX < minDistanceX || (distanceX === minDistanceX && distanceY < minDistanceY)) {
        minDistanceX = distanceX;
        minDistanceY = distanceY;
        closestIndex = index;
      }
    });

    if (closestIndex !== -1) {
      onSelectReflection(closestIndex);
    }
  };

  const handleInitializePlot = useCallback((figure: Readonly<Figure>) => {
    setLayout(figure.layout);
  }, []);

  const handleUpdatePlot = useCallback((figure: Readonly<Figure>) => {
    setLayout((prevLayout) => {
      if (JSON.stringify(prevLayout) !== JSON.stringify(figure.layout)) {
        return figure.layout;
      }
      return prevLayout;
    });
  }, []);

  return (
    <div className={styles['plot-container']}>
      <PlotHeader selectedXAxisType={selectedXAxisType} onXAxisTypeChange={setSelectedXAxisType} />
      <div className={styles['plot']} onClick={handlePlotClick} ref={plotContainerRef}>
        <AutoSizer>
          {({ height, width }) => (
            <Plot
              data={plotData}
              layout={getLayoutConfig(selectedXAxisType, selectedFrequencyBandIndex)}
              style={{ width, height }}
              config={{ displaylogo: false, responsive: true }}
              onInitialized={handleInitializePlot}
              onUpdate={handleUpdatePlot}
              useResizeHandler={true}
            />
          )}
        </AutoSizer>
      </div>
    </div>
  );
};
