import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { generateUUID } from 'three/src/math/MathUtils';

import { useAppContext } from '@/context/AppContext';
import { useGeometryImportContext } from '@/context/GeometryImportContext';

import { TrblTooltip } from '@/components/Shared';
import { BufferLoadingScreen } from '@/components/Shared/BufferLoadingScreen';
import { PrimaryButton } from '@/components/Shared/Buttons';
import { TrblUploadIcon } from '../../Icons';
import { SelectOption } from '../../Shared/TrblSelect';
import { SpaceInfo } from '../MultiSpaceImportStep2';

import { uploadFileToTreble, useCreateModel, useCreateModelBase, useCreateSpace, useSetImportedModelId } from '@/hooks';

import { GeometryInfo } from '../types';

import styles from '../styles.module.scss';

export const MultiSpaceUploadBox = ({
  spacesChecked,
  allSpacesInfo,
  projectName,
  modelName,
  filename,
  availableSpacesInProject,
  uploadFinished,
}: {
  spacesChecked: number[];
  allSpacesInfo: SpaceInfo[];
  projectName: string;
  modelName: string;
  filename: string;
  availableSpacesInProject: SelectOption[];
  uploadFinished: (spaceId?: string) => void;
}) => {
  const {
    appState: { availableSpaces },
  } = useAppContext();

  const { modelsGeometryInfo } = useGeometryImportContext();

  const [currentIndex, setCurrentIndex] = useState<number | null>(null);
  const [currentSpace, setCurrentSpace] = useState<SpaceInfo | null>(null);

  const [spaceIdForUpload, setSpaceIdForUpload] = useState<string>();
  const [modelBaseIdForUpload, setModelBaseIdForUpload] = useState<string | null>(null);

  const { mutate: createSpace } = useCreateSpace();
  const { mutate: createModelBase } = useCreateModelBase();
  const { mutate: createModel } = useCreateModel();
  const { mutate: setImportedModelId } = useSetImportedModelId();

  const checkSpacesAndModelsAreOk = () => {
    for (const spaceIndex of spacesChecked) {
      const existingSpace = checkIfSpaceExistInProject(allSpacesInfo[spaceIndex].spaceName);

      if (existingSpace) {
        const modelsInSpace = availableSpaces
          .filter((space) => space.id == existingSpace)[0]
          .modelBases.map((model) => {
            return { id: model.id, name: model.name };
          });

        const thisModelName = allSpacesInfo[spaceIndex].modelName || modelName;

        if (doesModelNameExistInSpace(thisModelName, modelsInSpace)) {
          toast.warning(
            "Model with this name already exists in '" +
              allSpacesInfo[spaceIndex].spaceName +
              "'. Please choose another model name."
          );
          return false;
        }
      }
    }
    return true;
  };

  const handleStartUploadSpaces = (index: number) => {
    if (spacesChecked.length && projectName) {
      // check if any of the Spaces chosen already have a model with same name
      if (!checkSpacesAndModelsAreOk()) return;

      startNextUploadSpace(index);
    }
  };
  const startNextUploadSpace = (index: number) => {
    const thisSpace = allSpacesInfo[spacesChecked[index]];
    const existingSpace = checkIfSpaceExistInProject(thisSpace.spaceName);
    setCurrentSpace(thisSpace);
    setCurrentIndex(index);

    // if space already exists the upload model to that Space, else create a new Space
    if (existingSpace) {
      setSpaceIdForUpload(existingSpace);
    } else {
      handleCreateSpace(thisSpace);
    }
  };

  const handleCreateSpace = (info: SpaceInfo) => {
    createSpace(
      {
        name: info.spaceName,
        // eslint-disable-next-line no-prototype-builtins
        description: info.hasOwnProperty('description') ? info.description : '',
        tag: projectName,
      },
      {
        onSuccess: (newSpace) => {
          setSpaceIdForUpload(newSpace.id);
        },
      }
    );
  };

  // if SpaceIdForUpload has value, then createModelBase
  useEffect(() => {
    if (spaceIdForUpload) {
      handleCreateModelBase(spaceIdForUpload);
    }
  }, [spaceIdForUpload]);

  const handleCreateModelBase = (spaceId: string) => {
    const thisSpace = allSpacesInfo[spacesChecked[currentIndex!]];
    const thisModelName = thisSpace.modelName || modelName;

    createModelBase(
      {
        spaceId,
        modelName: thisModelName,
      },
      {
        onSuccess: (newModelBase) => {
          setModelBaseIdForUpload(newModelBase.id);
        },
      }
    );
  };

  // if ModelBaseIdForUpload has value, then createModel
  useEffect(() => {
    if (modelBaseIdForUpload && currentSpace !== null && modelsGeometryInfo[`model_${currentSpace.meshUploadId}`]) {
      handleCreateModel(modelBaseIdForUpload, modelsGeometryInfo[`model_${currentSpace.meshUploadId}`]);
    }
  }, [modelBaseIdForUpload, modelsGeometryInfo]);

  const handleCreateModel = async (modelBaseId: string, geometryInfo: GeometryInfo) => {
    if (currentSpace === null) return;

    // save thumbnail to Treble S3 bucket and get upload id
    const thumbNailUploadId =
      currentSpace.thumbnailUploadId ||
      (currentSpace.thumbnailFile
        ? await uploadFileToTreble(new File([new Blob([currentSpace.thumbnailFile.fileData])], 'model_thumbnail.png'))
        : '');

    const fileType = filename.split('.').pop();

    createModel(
      {
        modelBaseId: modelBaseId,
        modelName: 'Revision',
        externalId: generateUUID(),
        fileUploadId: currentSpace.meshUploadId,
        sourceModelUploadId: currentSpace.sourceModelUploadId,
        sourceModelSha256: 'noSha256',
        application: 'WebClient|' + fileType,
        applicationVersion: 'test-0.0.1',
        nonWaterTight: !geometryInfo.watertight,
        thumbnailUploadId: thumbNailUploadId,
        fileType: fileType,
      },
      {
        onSuccess: (model) => {
          if (currentIndex !== null) {
            // Temporary endpoint so that we can mark a SpaceExtraction result as imported, for now only call this for IFC files
            if (spaceIdForUpload && filename.includes('.ifc')) {
              setImportedModelId({
                spaceExtractionTaskResultId: allSpacesInfo[currentIndex].id,
                importedModelId: model.id,
              });
            }
            // if there are more spaces in spacesChecked then upload the next one
            if (currentIndex < spacesChecked.length - 1) {
              startNextUploadSpace(currentIndex + 1);

              // if all spaces have been uploaded then finish
            } else if (currentIndex === spacesChecked.length - 1) {
              // if IFC file was uploaded then go to home page,
              if (filename.includes('.ifc')) uploadFinished();
              // if single space uploaded than go to that space page using the spaceId
              else uploadFinished(spaceIdForUpload);

              setCurrentIndex(null);
            }
          }
        },
      }
    );
  };

  const doesModelNameExistInSpace = (name: string, modelsInSpace: SelectOption[]) => {
    return modelsInSpace.some((model) => model.name.toLowerCase() === name.toLowerCase());
  };

  const checkIfSpaceExistInProject = (name: string) => {
    return availableSpacesInProject
      .filter((space) => space.name.toLowerCase() === name.toLowerCase())
      .map((space) => space.id)[0];
  };

  return (
    <>
      <div className={`${styles['panel-box']} ${styles['dark-box']}`}>
        <TrblTooltip
          placement="top"
          title={
            !projectName
              ? 'Select a project to import into'
              : !spacesChecked.length
              ? 'Select at least one space to import'
              : ''
          }>
          <span>
            <PrimaryButton
              label={
                allSpacesInfo.length > 1 ? 'Import selected spaces (' + spacesChecked.length + ')' : 'Import model'
              }
              disabled={!spacesChecked.length || !projectName}
              sx={{ height: '32px' }}
              onClick={() => handleStartUploadSpaces(0)}
              icon={<TrblUploadIcon fill="#171717" />}
            />
          </span>
        </TrblTooltip>
      </div>
      {currentSpace !== null && (
        <BufferLoadingScreen
          top={2}
          message={spacesChecked.length > 1 ? 'Importing ' + spacesChecked.length + ' models' : 'Importing model'}
        />
      )}
    </>
  );
};
