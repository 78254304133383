import { createContext, ReactNode, useContext, useEffect, useReducer, useState } from 'react';

import { useGetAllSimulationsForSpaces } from '../hooks/useGetAllSimulationsForSpaces';

import { comparisonsColors, MAX_COMPARISONS } from '../constants';

import { NewComparion, ResultComparison, SpaceSimulationsDto } from '../types';

type ResultsProviderProps = { children: ReactNode; isInResultsMode?: boolean };

enum ActionType {
  SET_SELECTED_COMPARISON_INDEX = 'SET_SELECTED_COMPARISON_INDEX',
  ADD_COMPARISON = 'ADD_COMPARISON',
  REMOVE_COMPARISON = 'REMOVE_COMPARISON',
  UPDATE_COMPARISON = 'UPDATE_COMPARISON',
  RESET_STATE = 'RESET_STATE',
  SET_SURFACE_RECEIVERS_IS_INTERPOLATED = 'SET_SURFACE_RECEIVERS_IS_INTERPOLATED',
  SET_SURFACE_RECEIVERS_PARAMETER = 'SET_SURFACE_RECEIVERS_PARAMETER',
  SET_SURFACE_RECEIVERS_FREQUENCY = 'SET_SURFACE_RECEIVERS_FREQUENCY',
  SET_SURFACE_RECEIVERS_NC_CURVE = 'SET_SURFACE_RECEIVERS_NC_CURVE',
  SET_TARGET_VALUE = 'SET_TARGET_VALUE',
  SET_TARGET_VALUE_MAX = 'SET_TARGET_VALUE_MAX',
  SET_TARGET_TYPE = 'SET_TARGET_TYPE',
  SET_TARGET_PERCENTAGE = 'SET_TARGET_PERCENTAGE',
}

type ResultsContextAction =
  | { type: ActionType.RESET_STATE }
  | { type: ActionType.SET_SELECTED_COMPARISON_INDEX; selectedComparisonIndex: number }
  | { type: ActionType.ADD_COMPARISON; newComparison: NewComparion }
  | { type: ActionType.REMOVE_COMPARISON; color: string }
  | { type: ActionType.UPDATE_COMPARISON; update: ResultComparison }
  | { type: ActionType.SET_SURFACE_RECEIVERS_IS_INTERPOLATED; isInterpolated: boolean }
  | { type: ActionType.SET_SURFACE_RECEIVERS_PARAMETER; parameter: string | null }
  | { type: ActionType.SET_SURFACE_RECEIVERS_FREQUENCY; frequency: string | null }
  | { type: ActionType.SET_SURFACE_RECEIVERS_NC_CURVE; payload: string | null }
  | { type: ActionType.SET_TARGET_VALUE; payload: number | undefined }
  | { type: ActionType.SET_TARGET_VALUE_MAX; payload: number | undefined }
  | { type: ActionType.SET_TARGET_TYPE; payload: string }
  | { type: ActionType.SET_TARGET_PERCENTAGE; payload: number };

type State = {
  selectedComparisonIndex: number;
  availableComparisons: ResultComparison[];
  surfaceReceiversIsInterpolated: boolean;
  surfaceReceiversSelectedParameter: string | null;
  surfaceReceiversSelectedFrequency: string | null;
  surfaceReceiversSelectedNcCurve: string | null;
  targetValue: number | undefined;
  targetValueMax: number | undefined;
  targetType: string;
  targetPercentage: number;
  dispatch: (action: ResultsContextAction) => void;
  allSpacesWithSims: SpaceSimulationsDto[];
};

const initialState: State = {
  selectedComparisonIndex: 0,
  availableComparisons: [{ color: comparisonsColors[0], formState: null }],
  surfaceReceiversIsInterpolated: true,
  surfaceReceiversSelectedParameter: null,
  surfaceReceiversSelectedFrequency: null,
  surfaceReceiversSelectedNcCurve: null,
  targetValue: undefined,
  targetValueMax: undefined,
  targetType: 'Above',
  targetPercentage: 0,
  dispatch: () => {},
  allSpacesWithSims: [] as SpaceSimulationsDto[],
};

const ResultsContext = createContext(initialState);

function handleUnknownAction(action: never): never;
function handleUnknownAction(action: ResultsContextAction) {
  throw new Error(`Unhandled action type: ${action.type}`);
}

const resultsReducer = (state: State, action: ResultsContextAction): State => {
  switch (action.type) {
    case ActionType.RESET_STATE: {
      return {
        ...initialState,
      };
    }

    case ActionType.SET_SURFACE_RECEIVERS_IS_INTERPOLATED: {
      return {
        ...state,
        surfaceReceiversIsInterpolated: action.isInterpolated,
      };
    }

    case ActionType.SET_SURFACE_RECEIVERS_FREQUENCY: {
      return {
        ...state,
        surfaceReceiversSelectedFrequency: action.frequency,
      };
    }

    case ActionType.SET_SURFACE_RECEIVERS_PARAMETER: {
      return {
        ...state,
        surfaceReceiversSelectedParameter: action.parameter,
      };
    }

    case ActionType.SET_SURFACE_RECEIVERS_NC_CURVE: {
      return {
        ...state,
        surfaceReceiversSelectedNcCurve: action.payload,
      };
    }

    case ActionType.SET_SELECTED_COMPARISON_INDEX: {
      return {
        ...state,
        selectedComparisonIndex: action.selectedComparisonIndex,
      };
    }

    case ActionType.SET_TARGET_VALUE: {
      return {
        ...state,
        targetValue: action.payload,
      };
    }

    case ActionType.SET_TARGET_VALUE_MAX: {
      return {
        ...state,
        targetValueMax: action.payload,
      };
    }

    case ActionType.SET_TARGET_TYPE: {
      return {
        ...state,
        targetType: action.payload,
      };
    }

    case ActionType.SET_TARGET_PERCENTAGE: {
      return {
        ...state,
        targetPercentage: action.payload,
      };
    }

    case ActionType.ADD_COMPARISON: {
      const { selectedSimulation, modelName, spaceName } = action.newComparison;
      const index = state.availableComparisons.length;

      // There can only be max 6 comparisons
      if (index < MAX_COMPARISONS) {
        const colorsInUse = state.availableComparisons.map((comp) => comp.color);
        const nextColor = comparisonsColors.filter((color) => !colorsInUse.includes(color));
        const newComparison: ResultComparison = {
          color: nextColor[0],
          formState: {
            simulationId: selectedSimulation.id,
            selectedSimulation: selectedSimulation,
            resultType: '',
            title: selectedSimulation.name,
            modelName,
            spaceName,
          },
        };
        return {
          ...state,
          availableComparisons: [...state.availableComparisons, newComparison],
          selectedComparisonIndex: state.availableComparisons.length,
        };
      }
      return { ...state };
    }

    case ActionType.REMOVE_COMPARISON: {
      const color = action.color;
      const newComparisons = [...state.availableComparisons];
      const deletedComparisonIndex = newComparisons.findIndex((comp) => comp.color === color);
      if (deletedComparisonIndex > -1) {
        newComparisons.splice(deletedComparisonIndex, 1);
      }

      return {
        ...state,
        availableComparisons: newComparisons,
        selectedComparisonIndex:
          state.selectedComparisonIndex === deletedComparisonIndex
            ? 0
            : state.selectedComparisonIndex > deletedComparisonIndex
            ? state.selectedComparisonIndex - 1
            : state.selectedComparisonIndex,
      };
    }

    case ActionType.UPDATE_COMPARISON: {
      const { color, formState } = action.update;
      const index = state.availableComparisons.findIndex((comp) => comp.color === color);
      if (index !== -1) {
        const newComparisons = [...state.availableComparisons];
        newComparisons[index] = {
          color,
          formState,
        };

        return {
          ...state,
          availableComparisons: [...newComparisons],
        };
      }

      return {
        ...state,
      };
    }

    default: {
      handleUnknownAction(action);
    }
  }
};

const ResultsProvider = ({ children, isInResultsMode }: ResultsProviderProps) => {
  const [state, dispatch] = useReducer(resultsReducer, initialState);
  const [allSpacesWithSims, setAllSpacesWithSims] = useState<SpaceSimulationsDto[]>([] as SpaceSimulationsDto[]);

  // Not triggered on render, but only when entering results mode
  const { data: allSimulationsForSpaces, refetch } = useGetAllSimulationsForSpaces(false);

  useEffect(() => {
    if (allSimulationsForSpaces && allSimulationsForSpaces.length > 0) {
      getAllSimulations(allSimulationsForSpaces);
    }
  }, [allSimulationsForSpaces]);

  useEffect(() => {
    if (isInResultsMode) {
      refetch();
    }
  }, [isInResultsMode, refetch]);

  const getAllSimulations = async (allSimulationsForSpaces: SpaceSimulationsDto[]) => {
    const orderedSpaces = [...allSimulationsForSpaces];
    orderedSpaces.sort((spaceSimA: SpaceSimulationsDto, spaceSimB: SpaceSimulationsDto) => {
      const tagNameA = spaceSimA.spaceTag.toLowerCase();
      const tagNameB = spaceSimB.spaceTag.toLowerCase();
      return tagNameA < tagNameB ? -1 : tagNameA > tagNameB ? 1 : 0;
    });
    orderedSpaces.forEach((space) => {
      space.simulations.sort((simA, simB) => {
        return new Date(simB.createdAt) > new Date(simA.createdAt) ? 1 : -1;
      });
    });
    setAllSpacesWithSims(orderedSpaces);
  };

  const value = { ...state, allSpacesWithSims, dispatch };

  return <ResultsContext.Provider value={value}>{children}</ResultsContext.Provider>;
};

// Custom Context hook to easily access the state and dispatch actions
const useResultsContext = () => {
  const context = useContext(ResultsContext);
  if (context === undefined) {
    throw new Error('useResultsContext must be used within ResultsProvider');
  }
  return context;
};

export { ActionType, ResultsProvider, useResultsContext };
