/* eslint-disable no-prototype-builtins */
import { useEditorContext } from '@/context/EditorContext';
import { useSimulationContext } from '@/context/SimulationContext';

import { AudioEngine } from '../AudioEngine';
import { useAuralizerContext } from '../AuralizerContext';

export const useUpdatedReverb = () => {
  const { simsAurObject, audioNodesDict } = useAuralizerContext();
  const {
    simulationState: { originalSim },
  } = useSimulationContext();
  const { isCameraInsideModel } = useEditorContext();
  const audioEngine = AudioEngine.getInstance();

  const updateReverb = (currentRecId: string | undefined, selAurSimId: string, isInsideModel?: boolean) => {
    const insideModel = isInsideModel ?? isCameraInsideModel;

    if (insideModel && originalSim && simsAurObject && simsAurObject[originalSim.id] && currentRecId) {
      const receiver = simsAurObject[originalSim.id].receivers.find((receiver) => receiver.id == currentRecId);
      if (receiver) {
        const rec_id = receiver.id.toLowerCase();
        if (selAurSimId) {
          audioEngine.originalSourceIdArray.forEach((source_key: string) => {
            updateConvolversBySource(source_key, rec_id, selAurSimId);
          });
        }
      }
    }
  };

  // disconnect old convolver from decoder
  // disconnect source from old receiver gain
  // connect source to new receiver gain
  // connect new convolver to decoder
  const updateConvolversBySource = (source_key: string, rec_id: string, selAurSimId: string) => {
    if (audioNodesDict?.[selAurSimId]?.convolverIds) {
      if (audioNodesDict[selAurSimId].convolverIds?.[source_key]) {
        if (audioNodesDict[selAurSimId].convolverIds?.[source_key][rec_id] !== undefined) {
          if (!audioEngine.storedReceiverGain[source_key]) {
            // not sure this condition is happening in any case with the current version
            audioEngine.storedReceiverGain[source_key] = audioEngine.bypassInputGainDict[source_key];
          }

          if (audioEngine.storedConvolverNode.hasOwnProperty(source_key)) {
            // if the convolver node was connected to the source, disconnect
            audioEngine.storedConvolverNode[source_key].disconnect(audioEngine.soaRenderer.input);

            if (audioEngine.storedReceiverGain.hasOwnProperty(source_key)) {
              // if the receiver gain was connected to the source, disconnect
              audioEngine.sourceGainDictionary[source_key].disconnect(audioEngine.storedReceiverGain[source_key]);
            }
          } else {
            // if the receiver gain and convolver nodes were not connected to the source and the renderer
            // we only have to disconnect the anechoic convolver (otherwise it will play when clicking a receiver)
            audioEngine.sourceGainDictionary[source_key].disconnect(audioEngine.bypassInputGainDict[source_key]);
          }
          audioEngine.sourceGainDictionary[source_key].connect(
            audioNodesDict[selAurSimId]['receiverGains'][source_key][rec_id]
          );

          // note that the receiver gain node should be already connected to the respective merger node when created
          audioNodesDict[selAurSimId]['mergerNodes'][source_key][rec_id].connect(audioEngine.soaRenderer.input);

          audioEngine.storedConvolverNode[source_key] = audioNodesDict[selAurSimId]['mergerNodes'][source_key][rec_id];
          audioEngine.storedReceiverGain[source_key] = audioNodesDict[selAurSimId]['receiverGains'][source_key][rec_id];
        } else {
          console.error(`Receiver id ${rec_id} not found.`);
        }
      } else {
        console.error(`Source id ${source_key} not found.`);
      }
    }
  };

  return updateReverb;
};
