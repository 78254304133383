import { FC } from 'react';
import { createBrowserRouter, Navigate, RouterProvider } from 'react-router-dom';
import { AppState, Auth0Provider, useAuth0 } from '@auth0/auth0-react';
import { datadogRum } from '@datadog/browser-rum';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { QueryCache, QueryClient, QueryClientProvider } from '@tanstack/react-query';

import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';

import {
  AdministrationPage,
  BaseWrapper,
  Dashboard,
  EditorPage,
  ErrorUnauhorizedPage,
  InformationPage,
  MainWrapper,
  OrganizationPage,
  OrganizationsPage,
  ProjectOverview,
  ServiceError,
  SpaceOverview,
  SubscriptionExpiredPage,
  UnexpectedErrorPage,
} from '@/pages';
import { darkTheme } from '@/theme';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
    },
  },
  queryCache: new QueryCache({
    onError: (error, query) => {
      if (query.state.data !== undefined) {
        Sentry.captureException(error);
        // TODO: handle network errors UI/UX
      }
    },
  }),
});

type RestrictedRouteProps = {
  requiredRoles: string[];
  children: React.ReactNode;
};

const RestrictedRoute: FC<RestrictedRouteProps> = ({ children, requiredRoles }) => {
  const { user } = useAuth0();
  const userRoles: string[] = user ? user['https://treble.tech/roles'] : [];

  if (!requiredRoles.every((role) => userRoles.includes(role))) {
    return <Navigate to={'/401'} />;
  }

  return <>{children}</>;
};

const router = createBrowserRouter([
  {
    path: '/',
    element: <BaseWrapper />,
    errorElement: <UnexpectedErrorPage />,
    children: [
      {
        path: '/',
        element: <MainWrapper />,
        children: [
          {
            path: '/',
            element: <Dashboard />,
          },

          {
            path: 'projects',
            element: <Dashboard />,
          },
          {
            path: 'project/:id',
            element: <ProjectOverview />,
          },
          {
            path: 'space/:id',
            element: <SpaceOverview />,
          },
          {
            path: 'results',
            element: <EditorPage showResults={true} />,
          },
          {
            path: 'auralizer',
            element: <EditorPage showAuralizer={true} />,
          },
          {
            path: 'editor',
            element: <EditorPage />,
          },
          // we need to keep this to be backwards compatible with Sketchup
          {
            path: 'editor/:id',
            element: <EditorPage />,
          },
        ],
      },
      {
        // TODO: this is resulting in a double refresh,
        // because Auth0 also using onRedirectCallback,
        // need to take a better look to bypass this
        path: 'authentication/login-callback',
        element: <Navigate to="/" replace />,
      },
      {
        path: '/401',
        element: <ErrorUnauhorizedPage />,
      },
      {
        path: '/offline',
        element: <ServiceError />,
      },
      {
        path: '/subscription',
        element: <InformationPage />,
      },
      {
        path: '/subscription-expired',
        element: <SubscriptionExpiredPage />,
      },
      {
        path: '/access-disabled',
        element: <InformationPage accessDisabled={true} />,
      },
      {
        path: 'administration',
        element: (
          <RestrictedRoute requiredRoles={['Admin']}>
            <AdministrationPage />
          </RestrictedRoute>
        ),
      },
      {
        path: 'organizations',
        element: (
          <RestrictedRoute requiredRoles={['Superuser']}>
            <OrganizationsPage />
          </RestrictedRoute>
        ),
      },
      {
        path: 'organizations/:id',
        element: (
          <RestrictedRoute requiredRoles={['Superuser']}>
            <OrganizationPage />
          </RestrictedRoute>
        ),
      },
    ],
  },
]);

const App = () => {
  const location = window.location;
  // Redirect to the current url in state
  const onRedirectCallback = (appState?: AppState) => {
    if (appState && appState.returnTo) {
      location.replace(appState.returnTo);
    }
  };

  const testString = location.hostname.toLowerCase();
  let environment = '';
  let ddEnvironment = '';
  const env = import.meta.env;

  if (testString === 'app.treble.tech') {
    environment = 'production';
    ddEnvironment = 'prod';
  } else if (testString === 'app.staging.treble.tech') {
    environment = 'staging';
    ddEnvironment = 'staging';
  } else if (testString === 'app.dev.treble.tech') {
    environment = 'development';
    ddEnvironment = 'dev';
  }

  if (environment && environment !== '') {
    Sentry.init({
      dsn: env.VITE_SENTRY_DSN,
      integrations: [new BrowserTracing()],

      // To set a uniform sample rate
      // 0.6 means to send 60% of transactions
      tracesSampleRate: 0.6,
      environment: environment,
      release: env.VITE_APP_VERSION,
    });
    datadogRum.init({
      applicationId: env.VITE_DATADOG_ID_TROOM,
      clientToken: env.VITE_DATADOG_CLIENT_TOKEN_TROOM,
      site: 'datadoghq.eu',
      proxy: 'https://drum.treble.tech',
      service: 'treble-room-react',
      env: ddEnvironment,
      allowedTracingUrls: env.VITE_DATADOG_TRACING_URLS.split(';'),

      // Specify a version number to identify the deployed version of your application in Datadog
      version: env.VITE_APP_VERSION,
      sessionSampleRate: 100,
      sessionReplaySampleRate: environment === 'production' ? 0 : 20,
      trackUserInteractions: true,
      trackFrustrations: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: 'mask-user-input',
      enableExperimentalFeatures: ['clickmap'],
    });

    if (environment !== 'production') {
      datadogRum.startSessionReplayRecording();
    }
  }

  return (
    <Auth0Provider
      onRedirectCallback={onRedirectCallback}
      domain={env.VITE_AUTH0_DOMAIN}
      clientId={env.VITE_AUTH0_CLIENT_ID}
      redirectUri={env.VITE_AUTH0_REDIRECT_URI}
      audience={env.VITE_AUTH0_AUDIENCE}
      useRefreshTokens
      cacheLocation="localstorage">
      <QueryClientProvider client={queryClient}>
        <ThemeProvider theme={darkTheme}>
          <StyledEngineProvider injectFirst>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <RouterProvider router={router} />
            </LocalizationProvider>
          </StyledEngineProvider>
        </ThemeProvider>
      </QueryClientProvider>
    </Auth0Provider>
  );
};

export default Sentry.withProfiler(App);
