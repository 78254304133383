import React, { FC } from 'react';

import { Text } from '@/components/Shared/Text';

import styles from './styles.module.scss';

type CheckboxProps = Omit<
  React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>,
  'onChange'
> & {
  id: string;
  label?: string | React.ReactNode;
  labelAlignment?: 'left' | 'right';
  spaceBetween?: boolean;
  isChecked: boolean;
  onChange?: (value: boolean) => void;
  disabled?: boolean;
};

export const Checkbox: FC<CheckboxProps> = ({
  id,
  label,
  labelAlignment = 'right',
  spaceBetween = false,
  onChange,
  isChecked,
  disabled,
  ...props
}) => {
  return (
    <div
      className={`${styles['checkbox-wrapper']} ${label && labelAlignment === 'right' ? styles['label-right'] : ''} ${
        spaceBetween ? styles['space-between'] : ''
      } ${disabled ? styles['disabled'] : ''}`}>
      {label && (
        <label
          style={{ display: 'flex', alignItems: 'center' }}
          htmlFor={id}
          onClick={onChange ? undefined : (e) => e.preventDefault()}>
          {React.isValidElement(label) ? label : <Text type="regular-11px">{label}</Text>}
        </label>
      )}
      <input
        id={id}
        type="checkbox"
        readOnly={onChange ? false : true}
        checked={isChecked}
        onChange={onChange ? () => onChange(!isChecked) : undefined}
        disabled={disabled}
        {...props}
      />
    </div>
  );
};
