import { FC, useRef } from 'react';

import styles from '../styles.module.scss';

type TextFieldProps = {
  autoFocus?: boolean;
  onChange?: (value: string) => void;
  onBlur?: (value: string) => void;
  label?: string;
  placeholder?: string;
  title?: string;
  value: string;
  maxLength?: number;
  blurOnEnter?: boolean;
  className?: string;
};

export const TextField: FC<TextFieldProps> = ({
  placeholder,
  value,
  label,
  title,
  onChange,
  onBlur,
  autoFocus,
  maxLength,
  blurOnEnter,
  className,
}) => {
  const internalInputElement = useRef<HTMLInputElement>(null);
  const handleKeyPress: React.KeyboardEventHandler<HTMLInputElement> = (event) => {
    if (blurOnEnter && event.key === 'Enter') {
      internalInputElement.current?.blur();
      internalInputElement.current?.focus();
    }
  };
  return (
    <div className={styles['input-div']}>
      {label && <span className={styles['label-top']}>{label}</span>}
      <input
        ref={internalInputElement}
        autoFocus={autoFocus}
        type="text"
        className={`${styles['text-field']} ${label ? styles['has-label-top'] : ''} ${className ?? ''}`}
        placeholder={placeholder}
        value={value}
        maxLength={maxLength}
        title={title}
        onChange={onChange ? (event) => onChange(event.target.value) : undefined}
        onBlur={onBlur ? (event) => onBlur(event.target.value) : undefined}
        onKeyDown={handleKeyPress}
      />
    </div>
  );
};
