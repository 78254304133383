import { ActionType, useEditorContext } from '@/context/EditorContext';
import { useModelContext } from '@/context/ModelContext';
import { useSimulationContext } from '@/context/SimulationContext';

import { EMPTY_GUID } from '@/components/Shared/constants';
import { useConfirmEdit } from '@/components/EditSimulation/hooks/useConfirmEdit';
import { useSaveUpdatedSimulation } from '@/components/EditSimulation/hooks/useSaveUpdatedSimulation';
import { ActionType as LibraryActionType, useLibraryPanelContext } from '@/components/LibraryPanel/LibraryPanelContext';
import { useShareAndUpdateSourceDefinition } from './useShareAndUpdateSourceDefinition';

import { SOURCE_DEFINITION_SAVE_TEXT } from '../constants';

import { Source, SourceParams } from '@/context/EditorContext/types';
import { Simulation, Source as SimSource, SourceDefinition, SourceParameter } from '@/types';

export const useAssignNewSourceDefinition = () => {
  const { sources, dispatch } = useEditorContext();
  const { dispatch: libDispatch } = useLibraryPanelContext();
  const confirmEdit = useConfirmEdit();
  const {
    simulationState: { selectedSimulation },
  } = useSimulationContext();
  const { modelInformation } = useModelContext();
  const saveSimulation = useSaveUpdatedSimulation();
  const shareAndUpdateSourceDefinition = useShareAndUpdateSourceDefinition();

  const assignNewSourceDefinition = async (
    simulation: Simulation | null,
    selectedSource: Source | null,
    sourceDefinition: SourceDefinition,
    userId?: string
  ) => {
    const canContinue = await confirmEdit();
    return new Promise<Simulation | null>((resolve) => {
      if (simulation && selectedSource) {
        const sourceIndex = sources.findIndex((source) => source.id === selectedSource.id);
        if (sourceIndex === -1) return;
        const newSources = [...sources];
        const newParams: SourceParams =
          sourceDefinition.id !== EMPTY_GUID
            ? {
                ...newSources[sourceIndex].params,
                directivityPattern: sourceDefinition.id,
                eq: null,
                overallGain: null,
                correctIrByOnAxisSpl: sourceDefinition.correctIrByOnAxisSplDefault,
                useSplForSti: false,
              }
            : {
                directivityPattern: sourceDefinition.id,
                azimuth: 0,
                elevation: 0,
                rotation: 0,
                eq: null,
                overallGain: null,
                correctIrByOnAxisSpl: false,
                useSplForSti: false,
              };

        newSources[sourceIndex] = {
          ...newSources[sourceIndex],
          params: newParams,
        };
        const newSimSources: SimSource[] = sources.map((s, index) => ({
          id: s.id,
          label: s.label,
          x: s.x!,
          y: s.y!,
          z: s.z!,
          orderNumber: index,
        }));

        const newSourceParameters: SourceParameter[] = newSources.map((s) => ({
          sourceId: s.id,
          directivityPattern: s.params?.directivityPattern || EMPTY_GUID,
          azimuth: s.params?.azimuth || 0,
          elevation: s.params?.elevation || 0,
          rotation: s.params?.rotation || 0,
          eq: s.params?.eq || null,
          overallGain: s.params?.overallGain || null,
          correctIrByOnAxisSpl: s.params?.correctIrByOnAxisSpl || false,
          useSplForSti: s.params?.useSplForSti || false,
        }));

        const updatedSimulation = {
          ...selectedSimulation,
          sources: [...newSimSources],
          sourceParameters: [...newSourceParameters],
        };
        if (canContinue) {
          dispatch({
            type: ActionType.SET_SOURCES,
            sources: newSources,
          });
          libDispatch({
            type: LibraryActionType.SET_SELECTED_SOURCE,
            source: newSources[sourceIndex],
            sourceDefinition: sourceDefinition,
          });
          // if the model is in a shared project and source definition is created by the user and not shared with the organization
          // then share the source definition
          if (
            modelInformation?.isProjectShared &&
            sourceDefinition.userId == userId &&
            !sourceDefinition.isSharedWithOrganization
          ) {
            shareAndUpdateSourceDefinition(sourceDefinition);
          }

          // @ts-expect-error Type 'string | undefined' is not assignable to type 'string'.
          saveSimulation(updatedSimulation, SOURCE_DEFINITION_SAVE_TEXT);
          resolve(null);
        }
        // @ts-expect-error extra?: any; }' is not assignable to type 'Simulation'.
        resolve(updatedSimulation);
      }
    });
  };

  return assignNewSourceDefinition;
};
