import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';

import { getTimeStringByDuration } from '@/utils/trebleFunctions';

import { SimulationRunDetails } from './types';
import { RunStatus, SimulationRunDto, SimulationRunStatusDto, TaskRunStatus } from '@/types';

export const getIsInProgressByStatus = (runStatus: RunStatus | null) => {
  switch (runStatus) {
    case RunStatus.Created:
    case RunStatus.InProgress:
    case RunStatus.Queued:
    case RunStatus.ProcessingResults:
      return true;
    default:
      return false;
  }
};

export const getSimulationStatusTextByRunStatus = (runStatus: RunStatus | null) => {
  switch (runStatus) {
    case RunStatus.Queued:
      return 'Queued';
    case RunStatus.Created:
    case RunStatus.InProgress:
      return 'Running';
    case RunStatus.Completed:
      return 'Completed';
    case RunStatus.ProcessingResults:
      return 'Processing results';
    case RunStatus.Cancelled:
      return 'Cancelled';
    case RunStatus.InsufficientSimulationTime:
      return 'Insufficient tokens';
    case RunStatus.Error:
    case RunStatus.TaskError:
      return 'Error';
    default:
      return 'Error';
  }
};

export const getSourceStatusTextByTaskRunStatus = (taskRunStatuses: TaskRunStatus[]) => {
  if (taskRunStatuses.length) {
    if (taskRunStatuses.some((s) => s === 'Error' || s === 'Invalid')) {
      return 'Error';
    } else if (taskRunStatuses.some((s) => s === 'Cancelling' || s === 'Cancelled')) {
      return 'Cancelled';
    } else if (taskRunStatuses.some((s) => s === 'Queued')) {
      return 'Queued';
    } else if (taskRunStatuses.every((s) => s === 'Completed')) {
      return 'Completed';
    }
  }

  return '';
};

export const getIsInProgressByTaskRunStatus = (taskRunStatuses: TaskRunStatus[]) => {
  if (taskRunStatuses.some((s) => ['Created', 'Pending', 'InProgress', 'ProcessingResults'].includes(s))) {
    return true;
  }

  return false;
};

export const getProgressBarColorByStatus = (runStatus: RunStatus | null) => {
  switch (runStatus) {
    case RunStatus.Cancelled:
    case RunStatus.InsufficientSimulationTime:
      return 'warning';
    case RunStatus.Error:
    case RunStatus.TaskError:
      return 'error';
    default:
      return 'primary';
  }
};

export const getProgressBarColorByTaskStatus = (runStatus: TaskRunStatus | null) => {
  switch (runStatus) {
    case 'Cancelled':
    case 'Cancelling':
      return 'warning';
    case 'Error':
    case 'Invalid':
      return 'error';
    default:
      return 'secondary';
  }
};

export const getSimulationRemainingEstimate = (percentage: number | null, timeEst: number | null) => {
  if (percentage && timeEst) {
    dayjs.extend(duration);

    // we use the percentage to adjust remaining time, since timeEst can sometimes a bit wrong
    const difference = timeEst * (1 - percentage / 100) * 1000;
    const differenceDuration = dayjs.duration(difference);

    if (difference > 0) {
      return getTimeStringByDuration(differenceDuration);
    } else {
      return null;
    }
  }

  return null;
};

export const mapSimulationRunToSimulationRunDetails = (simulationRun: SimulationRunDto): SimulationRunDetails => {
  const inProgress = getIsInProgressByStatus(simulationRun?.status);

  const runDetails: SimulationRunDetails = {
    id: simulationRun.id,
    inProgress,
    statusText: getSimulationStatusTextByRunStatus(simulationRun.status),
    showEllipsis: simulationRun.status === RunStatus.ProcessingResults,
    createdAt: simulationRun.createdAt || null,
    completedAt: null,
    percentage: simulationRun.progressPercentage || null,
    progressBarColor: getProgressBarColorByStatus(simulationRun.status),
    timeRemainingText: null,
    showRemaining:
      inProgress && simulationRun.status !== RunStatus.ProcessingResults && simulationRun.status !== RunStatus.Queued,
    sourceDetails: simulationRun.sources
      .sort((a, b) => a.orderNumber - b.orderNumber)
      .map((s, index) => ({
        label: s.label || `Source ${index + 1}`,
        progressBarColor: 'secondary',
        inProgress: false,
        statusText: '',
        percentage: null,
        orderNumber: s.orderNumber,
      })),
  };

  if (runDetails.statusText === 'Running' && !runDetails.percentage) {
    runDetails.statusText = 'Initializing solver';
    runDetails.showEllipsis = true;
    runDetails.showRemaining = false;
  }

  return runDetails;
};

export const mapSimulationRunStatusToSimulationRunDetails = (
  simulationRunStatus: SimulationRunStatusDto,
  taskType: string | null
): SimulationRunDetails => {
  const inProgress = getIsInProgressByStatus(simulationRunStatus.status);

  let percentage = simulationRunStatus.progressPercentage;
  if (inProgress && percentage === 100) percentage = 99;

  const runDetails: SimulationRunDetails = {
    id: simulationRunStatus.id,
    inProgress: getIsInProgressByStatus(simulationRunStatus.status),
    statusText: getSimulationStatusTextByRunStatus(simulationRunStatus.status),
    createdAt: simulationRunStatus.createdAt,
    completedAt: simulationRunStatus.completedAt,
    percentage: percentage,
    progressBarColor: getProgressBarColorByStatus(simulationRunStatus.status),
    showEllipsis: simulationRunStatus.status === RunStatus.ProcessingResults,
    timeRemainingText: getSimulationRemainingEstimate(
      simulationRunStatus.progressPercentage,
      simulationRunStatus.timeEstimate
    ),
    showRemaining:
      inProgress &&
      simulationRunStatus.status !== RunStatus.ProcessingResults &&
      simulationRunStatus.status !== RunStatus.Queued,
    sourceDetails:
      simulationRunStatus.sources
        ?.sort((a, b) => a.orderNumber - b.orderNumber)
        .map((s, index) => {
          const statusText = getSourceStatusTextByTaskRunStatus(
            s.taskStatuses ? s.taskStatuses.map((s) => s.status) : []
          );

          return {
            label: s.label || `Source ${index + 1}`,
            statusText: statusText,
            inProgress: getIsInProgressByTaskRunStatus(s.taskStatuses ? s.taskStatuses.map((s) => s.status) : []),
            progressBarColor: statusText === 'Cancelled' ? 'warning' : statusText === 'Error' ? 'error' : 'secondary',
            percentage: s.progressPercentage,
            orderNumber: s.orderNumber,
          };
        }) || [],
  };

  if (runDetails.statusText === 'Running' && !runDetails.percentage && taskType !== 'GA') {
    runDetails.statusText = 'Initializing solver';
    runDetails.showEllipsis = true;
    runDetails.showRemaining = false;
  }

  return runDetails;
};
