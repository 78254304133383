import { TrblTooltip } from '@/components/Shared';
import { TrblNumberInput } from '@/components/Shared/NumberInput';
import { Text } from '@/components/Shared/Text';
import { TrblInfoIcon } from '@/components/Icons';
import { MAX_VALUE, MIN_VALUE } from '@/components/MaterialDetailsPopup/constants';

import { FREQUENCY_RANGE_FULL_OCTAVE, FULL_OCTAVES, INITIAL_VALUES_FULL_OCTAVE } from '../constants';

import { Frequency } from '../types';
import { FitMaterial } from '@/types';

import styles from '../styles.module.scss';

type SimpleInputTableProps = {
  data: Record<number, number | undefined>;
  setData: (targetCoefficients: Record<number, number | undefined>) => void;
  materialFit: FitMaterial | null;
  disableEdit: boolean;
};

export const SimpleInputTable: React.FC<SimpleInputTableProps> = ({ data, setData, materialFit, disableEdit }) => {
  const handleOnBlur = (value: number | undefined, freq: Frequency) => {
    const newTargetCoefficients = { ...data };
    if (value !== undefined) {
      newTargetCoefficients[freq] = value;
    } else {
      newTargetCoefficients[freq] = INITIAL_VALUES_FULL_OCTAVE[freq];
    }
    setData(newTargetCoefficients);
  };

  const handleOnChange = (value: number | undefined, freq: Frequency) => {
    const newTargetCoefficients = { ...data };
    newTargetCoefficients[freq] = value;
    setData(newTargetCoefficients);
  };

  const fittedStyling = (index: number, freq: number): React.CSSProperties | undefined => {
    let color: string | undefined;

    if (materialFit?.material) {
      const difference = Math.abs(materialFit.material.FittedAbsorptionCoefficients[index] - data[freq]!);
      if (difference >= 0.05) {
        color = '#f58a69';
      } else if (difference >= 0.02) {
        color = '#fae387';
      }
    }

    return {
      color,
      paddingRight: '8px',
    };
  };

  return (
    <table className={styles.material_input_table}>
      <tbody>
        <tr>
          <th className={styles.first_child}>Freq (Hz) </th>
          {FREQUENCY_RANGE_FULL_OCTAVE.map((freq: string) => (
            <th key={freq} className={styles.cell_center}>
              {freq}
            </th>
          ))}
        </tr>
        <tr>
          <td className={`${styles.position_relative} ${styles.first_child}`}>
            <TrblTooltip title="Target values for the random incidence absorption coefficient.">
              <span>
                <Text type="regular-11px">Target</Text>
                <span className={styles.info_icon}>
                  <TrblInfoIcon width="12" height="12" />
                </span>
              </span>
            </TrblTooltip>
          </td>
          {FULL_OCTAVES.map((freq, index) => (
            <td key={freq + index}>
              <TrblNumberInput
                toFixed={false}
                center
                value={data[freq]}
                step={MIN_VALUE}
                min={MIN_VALUE}
                disabled={disableEdit}
                max={MAX_VALUE}
                onChange={(value) => handleOnChange(value, freq)}
                onBlur={(value) => handleOnBlur(value, freq)}
                alignment="center"
                style={{ width: 50, margin: '0 auto', justifyContent: 'center' }}
              />
            </td>
          ))}
        </tr>
        <tr>
          <td className={`${styles.position_relative} ${styles.first_child}`}>
            <TrblTooltip title="The result of Treble’s material conversion engine showing the resulting random incidence absorption coefficients based on the estimated surface impedance of the material.">
              <span>
                <Text type="regular-11px">Result</Text>
                <span className={styles.info_icon}>
                  <TrblInfoIcon width="12" height="12" />
                </span>
              </span>
            </TrblTooltip>
          </td>
          {FULL_OCTAVES.map((freq, index) => (
            <td key={freq + index} className={styles['cell-center']}>
              <Text numberFontStyleEnabled type="regular-11px" style={fittedStyling(index, freq)}>
                {materialFit != null ? materialFit.material?.FittedAbsorptionCoefficients[index] : '--'}
              </Text>
            </td>
          ))}
        </tr>
      </tbody>
    </table>
  );
};
