import { useEffect, useState } from 'react';

import { useAppContext } from '@/context/AppContext';

import { Box, Stack } from '@mui/material';

import { TrblTooltip } from '@/components/Shared';
import { TrblNumberInput } from '@/components/Shared/NumberInput';
import { TextField } from '@/components/Shared/TextField';
import { SelectOption, TrblSelect } from '@/components/Shared/TrblSelect';
import { TrblInfoIcon } from '@/components/Icons';
import { MAX_VALUE, MIN_VALUE } from '@/components/MaterialDetailsPopup/constants';
import { TextArea } from '../../Shared/TextArea';

import { FORM_INITIAL } from '../constants';

import styles from '../styles.module.scss';

type MaterialDetails = {
  formValues: {
    name: string;
    category: string;
    description: string;
    defaultScattering: number;
  };
  setFormValues: React.Dispatch<
    React.SetStateAction<{
      name: string;
      category: string;
      description: string;
      defaultScattering: number;
    }>
  >;
  shouldReset: boolean;
};

export const MaterialDetails = ({ formValues, setFormValues, shouldReset }: MaterialDetails) => {
  const [menuItems, setMenuItems] = useState<SelectOption[]>([]);
  const {
    appState: { materialCategories },
  } = useAppContext();

  useEffect(() => {
    if (shouldReset) {
      setFormValues(FORM_INITIAL);
    }
  }, [shouldReset]);

  useEffect(() => {
    if (materialCategories.length > 0) {
      const sorted = materialCategories.sort((a, b) => a.localeCompare(b));
      const menuItem = sorted.map((item) => {
        return {
          name: item,
          id: item,
        };
      });
      setMenuItems(menuItem);
    }
  }, [materialCategories]);

  return (
    <Box component={'div'} display="flex" flexGrow={1} gap="20px" mb="20px">
      <Stack gap={1} flex={'1 1 0'}>
        <TextField
          autoFocus
          placeholder="Material name (required)"
          value={formValues.name}
          onChange={(value) => setFormValues({ ...formValues, name: value })}
        />

        <TrblSelect
          value={formValues.category || ''}
          setValue={(value) => setFormValues({ ...formValues, category: value })}
          menuItems={menuItems}
          placeholder="Select category (required)"
        />
        <div style={{ position: 'relative' }}>
          <TrblNumberInput
            label="Default scattering"
            toFixed={false}
            value={formValues.defaultScattering}
            step={MIN_VALUE}
            min={MIN_VALUE}
            max={MAX_VALUE}
            onChange={(value) => {
              // @ts-expect-error Type 'number | undefined' is not assignable to type 'number'.
              setFormValues({ ...formValues, defaultScattering: value });
            }}
            onBlur={(value) => {
              if (value !== undefined) {
                setFormValues({ ...formValues, defaultScattering: Number(value?.toFixed(2)) });
              }
            }}
            alignment="center"
          />
          <TrblTooltip
            title={
              <div>
                <p>
                  For the best results when setting a scattering coefficient we recommend consulting the{' '}
                  <a
                    target="_blank"
                    style={{ textDecoration: 'underline' }}
                    href="https://docs.treble.tech/user-guide/getting-started/best_practises#geometrical-acoustics-solver">
                    <b>following guide</b>
                  </a>
                  .
                </p>
              </div>
            }>
            <span className={styles.scatter_info}>
              <TrblInfoIcon width="12" height="12" />
            </span>
          </TrblTooltip>
        </div>
      </Stack>
      <Stack gap={1} flex={'1 1 0'}>
        <TextArea
          placeholder="Material description"
          value={formValues.description}
          onChange={(value) => setFormValues({ ...formValues, description: value })}
          style={{ minHeight: ' 112px' }}
        />
      </Stack>
    </Box>
  );
};
