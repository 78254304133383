import { Dispatch, SetStateAction } from 'react';
import { datadogRum } from '@datadog/browser-rum';

import { FileUpload } from '@/components/Shared/FileUpload';

import { VALID_FILE_TYPES } from '../constants';

type MaterialFileUploadProps = {
  errorMessage: null | string;
  setErrorMessage: React.Dispatch<React.SetStateAction<string | null>>;
  setUploadedFile: Dispatch<
    SetStateAction<{ file: File; contents: string | ArrayBuffer | null | undefined } | undefined>
  >;
  shouldReset: boolean;
  disabled: boolean | undefined;
};

export const MaterialFileUpload: React.FC<MaterialFileUploadProps> = ({
  errorMessage,
  setErrorMessage,
  setUploadedFile,
  shouldReset,
  disabled,
}) => {
  const onChangeFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.currentTarget.files) {
      const file = Array.from(event.currentTarget.files)[0];
      parseFile(file);
    }
  };

  const onDropFileUpload = (event: React.DragEvent<HTMLElement>) => {
    if (event.dataTransfer.files) {
      const file = Array.from(event.dataTransfer.files)[0];
      parseFile(file);
    }
  };

  const parseFile = (file: File) => {
    if (VALID_FILE_TYPES.includes(file?.type)) {
      const reader = new FileReader();

      reader.onload = (event: ProgressEvent<FileReader>) => {
        const contents = event.target?.result;
        setUploadedFile({ file: file, contents });
      };

      reader.readAsText(file);
    } else {
      datadogRum.addError(`Invalid file type: ${file?.type}`);
      setErrorMessage(`Invalid file type: ${file?.type}`);
    }
  };

  return (
    <div style={{ marginTop: '10px' }}>
      <FileUpload
        small
        label="Drop file here to import (optional)"
        disabled={disabled}
        errorMessage={errorMessage}
        accept={'.csv, .txt'}
        acceptText=".csv or .txt files"
        onChange={onChangeFileUpload}
        onDropFile={onDropFileUpload}
        shouldReset={shouldReset}
      />
    </div>
  );
};
