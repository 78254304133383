import { useEffect } from 'react';

import { ActionType, useReflectogramResultsContext } from '@/components/Results/context/ReflectogramResultsContext';

import { useGetReflectionDataByDownloadUrl } from './useGetReflectionDataByDownloadUrl';

import { mapReflectionData } from '../utils';

export const useReflectionData = (downloadUrl: string | null, id: number | null) => {
  const { dispatch } = useReflectogramResultsContext();
  const { data, isFetched } = useGetReflectionDataByDownloadUrl(downloadUrl, id);

  useEffect(() => {
    if (isFetched) {
      const reflectionDetails = data ? mapReflectionData(data) : [];
      dispatch({
        type: ActionType.SET_REFLECTIONS_DATA,
        reflectionsData: reflectionDetails,
      });
    }
  }, [isFetched, data, dispatch]);
};
