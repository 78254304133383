import { useEffect, useState } from 'react';
import { datadogRum } from '@datadog/browser-rum';

import { BufferLoadingScreen } from '@/components/Shared/BufferLoadingScreen';
import { Label } from '@/components/Shared/Label';
import { DarkBox } from '@/components/Shared/Popup';
import {
  ABS_PLOT_TITLE,
  ABS_RANGE,
  REF_PLOT_TITLE,
  REF_RANGE,
  REF_X_VALS,
  TICK_VALS,
} from '@/components/MaterialDetailsPopup/constants';
import { MaterialDetailsPlot } from '@/components/MaterialDetailsPopup/MaterialDetailsPlot';
import { MaterialFileUpload } from './MaterialFileUpload';
import { MaterialTextInput } from './MaterialTextInput';
import { MissingKeys } from './MissingKeys';
import { SimpleInputTable } from './SimpleInputTable';
import { UploadFileToggle } from './UploadFileToggle';

import {
  FREQUENCY_RANGE_FULL_OCTAVE,
  FULL_OCTAVES,
  INITIAL_VALUES_FULL_OCTAVE,
  PLOT_WIDTH,
  VALID_CSV_TYPES,
} from '../constants';

import { getFirstValuesForKeys } from '../utils/getFirstValuesForKeys';
import { parseCSV } from '../utils/parseCsv';
import { parseInput } from '../utils/parseInput';
import { parseObjectToString } from '../utils/parseObjectToString';

import { SimpleData } from '../types';
import { FitMaterial } from '@/types';

import styles from '../styles.module.scss';

type SimpleInputProps = {
  data: SimpleData;
  setData: (targetCoefficients: SimpleData) => void;
  materialFit: FitMaterial | null;
  formDisabled: boolean | undefined;
  shouldReset: boolean;
  plotHeight: number;
};

const PLACEHOLDER = `Paste random incidence absorption data for each octave band from 63 Hz - 8.000 Hz separated by space and in a new line e.g.
  50	0.67
  ...
or switch to  upload mode, to upload a .csv or .txt directly.`;

export const SimpleInput: React.FC<SimpleInputProps> = ({
  data,
  setData,
  materialFit,
  formDisabled,
  shouldReset,
  plotHeight,
}) => {
  const [textInput, setTextInput] = useState<string>('');
  const [uploadedFile, setUploadedFile] = useState<{ file: File; contents: string | ArrayBuffer | null | undefined }>();
  const [enableUploadFile, setEnableUploadFile] = useState(true);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [disableEdit, setDisableEdit] = useState<boolean>(false);

  useEffect(() => {
    setDisableEdit(formDisabled || materialFit !== null);
  }, [formDisabled, materialFit]);

  useEffect(() => {
    if (shouldReset) {
      setData(INITIAL_VALUES_FULL_OCTAVE);
    }
  }, [shouldReset]);

  useEffect(() => {
    const newTextInput = parseObjectToString(data);
    setTextInput(newTextInput);
  }, [data]);

  useEffect(() => {
    if (uploadedFile) {
      setErrorMessage(null);
      if (VALID_CSV_TYPES.includes(uploadedFile.file.type)) {
        const absRecord = parseCSV(uploadedFile.contents as string);
        const absRecordWithValues = getFirstValuesForKeys(absRecord, FULL_OCTAVES);
        datadogRum.addAction(`Successful CSV file upload ${absRecordWithValues}`);
        setData(absRecordWithValues);
      } else {
        const absRecord = parseInput(uploadedFile.contents as string);
        if (Object.keys(absRecord).length === 0) {
          datadogRum.addError(`Invalid or empty file: ${absRecord}`);
          setErrorMessage('Invalid or empty file');
        } else {
          datadogRum.addAction(`Successful text file upload ${absRecord}`);
          setData(absRecord as Record<number, number>);
        }
      }
    }
  }, [uploadedFile]);

  const changeInput = (newInput: string) => {
    setTextInput(newInput);
  };

  const validateInput = (newInput: string) => {
    const absRecord = parseInput(newInput);
    setData(absRecord as Record<number, number>);
  };

  return (
    <div>
      <div>
        <div className={styles.space_between}>
          <Label> Absorption Coefficient (Random Incidence)</Label>
          <div className={styles.justify_right}>
            <UploadFileToggle toggle={setEnableUploadFile} isToggled={enableUploadFile} disabled={disableEdit} />
          </div>
        </div>
        {enableUploadFile ? (
          <MaterialFileUpload
            setUploadedFile={setUploadedFile}
            errorMessage={errorMessage}
            setErrorMessage={setErrorMessage}
            shouldReset={shouldReset}
            disabled={disableEdit}
          />
        ) : (
          <MaterialTextInput
            textInput={textInput}
            disabled={disableEdit}
            changeInput={changeInput}
            validateInput={validateInput}
            placeholder={PLACEHOLDER}
          />
        )}
        <SimpleInputTable materialFit={materialFit} data={data} setData={setData} disableEdit={disableEdit} />
      </div>
      <MissingKeys data={data} keys={FULL_OCTAVES} />
      <DarkBox>
        <div className={styles.space_between}>
          {formDisabled && <BufferLoadingScreen />}
          <MaterialDetailsPlot
            range={ABS_RANGE}
            ticktext={FREQUENCY_RANGE_FULL_OCTAVE}
            tickvals={TICK_VALS}
            xData={Object.keys(data)}
            yData={materialFit?.material?.FittedAbsorptionCoefficients || []}
            secondYData={Object.values(data)}
            yDataName={'Result'}
            secondYDataName={'Target'}
            yAxesTitle={ABS_PLOT_TITLE}
            plotHeight={plotHeight}
            plotWidth={PLOT_WIDTH}
          />
          <MaterialDetailsPlot
            range={REF_RANGE}
            ticktext={FREQUENCY_RANGE_FULL_OCTAVE}
            tickvals={TICK_VALS}
            xData={REF_X_VALS}
            yData={materialFit?.material_metadata?.RealReflectionCoefficient || []}
            secondYData={materialFit?.material_metadata?.ImagReflectionCoefficient || []}
            yDataName={'Real'}
            secondYDataName={'Imag'}
            yAxesTitle={REF_PLOT_TITLE}
            plotHeight={plotHeight}
            plotWidth={PLOT_WIDTH}
          />
        </div>
      </DarkBox>
    </div>
  );
};
