import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { useAppContext } from '@/context/AppContext';

import { TrblGeometryFolderIcon, TrblIcon } from '@/components/Icons';
import { TextField } from '../../Shared/TextField';
import { SelectOption, TrblSelect } from '../../Shared/TrblSelect';
import { AddNewProjectPopup } from './AddNewProjectPopup';

import styles from '../styles.module.scss';

export const MultiSpaceInfoBox = ({
  filename,
  modelNames,
  setModelNames,
  projectName,
  setProjectName,
  setAvailableSpacesInProject,
  goBack,
  manySpaces = true,
}: {
  filename: string;
  modelNames: string;
  setModelNames: (name: string) => void;
  projectName: string;
  setProjectName: (name: string) => void;
  setAvailableSpacesInProject: (value: SelectOption[]) => void;
  goBack: () => void;
  manySpaces?: boolean;
}) => {
  const {
    appState: { availableSpaces },
  } = useAppContext();

  const [model, setModel] = useState(modelNames);
  const [newProjectName, setNewProjectName] = useState('');

  const [availableProjects, setAvailableProjects] = useState<SelectOption[]>([]);
  const [projects, setProjects] = useState<SelectOption[]>([]);

  const [showNewProjectPopup, setShowNewProjectPopup] = useState(false);

  useEffect(() => {
    if (availableSpaces?.length) {
      let allProjects = availableSpaces.map((space) => {
        return space.tag || 'none';
      });
      allProjects = [...new Set(allProjects)];
      const projectsOptions = allProjects.map((projectName) => {
        return { id: projectName, name: projectName };
      });

      setProjects(projectsOptions);
      setAvailableProjects(projectsOptions);
    }
  }, [availableSpaces]);

  const setSelectedProject = (project: string) => {
    if (availableSpaces?.length) {
      const currentSpaces = availableSpaces
        .filter((space) => space.tag == project)
        .map((space) => {
          return { id: space.id, name: space.name, description: space.description };
        });

      setAvailableSpacesInProject(currentSpaces);
    }

    setProjectName(project);
  };

  const addNewProject = (projectName: string) => {
    const currentProjects = [...availableProjects];
    currentProjects.push({ id: projectName, name: projectName });

    setProjects(currentProjects);
    setProjectName(projectName);
    setNewProjectName(projectName);
    setAvailableSpacesInProject([]);
  };

  const updateModelNames = (value: string) => {
    if (value) {
      setModelNames(value);
    } else {
      setModel(modelNames);
      toast.warning('Model names have to contain at least 1 character');
    }
  };

  return (
    <>
      <div className={styles['panel-box']}>
        <div className={styles['filename-box']} title={filename}>
          <TrblGeometryFolderIcon width="14" height="14" fill="#dadada" />
          <span>{filename}</span>
          <button title="Upload another file" onClick={goBack}>
            Back
          </button>
        </div>

        <TrblSelect
          autoFocus
          label="Project"
          placeholder="Select project"
          value={projectName ?? ''}
          setValue={setSelectedProject}
          menuItems={projects}
          actionButton={{
            label: newProjectName && newProjectName == projectName ? 'Change project' : 'New project',
            color: '#00f5ba',
            icon: <TrblIcon icon="add" color="#00f5ba" />,
            onClick: () => setShowNewProjectPopup(true),
          }}
        />
        {manySpaces && (
          <TextField
            label={manySpaces ? 'All model names' : 'Model name'}
            value={model}
            onChange={setModel}
            onBlur={updateModelNames}
            blurOnEnter={true}
          />
        )}
      </div>
      {showNewProjectPopup && (
        <AddNewProjectPopup
          addNewProject={addNewProject}
          onClose={() => setShowNewProjectPopup(false)}
          projects={availableProjects}
        />
      )}
    </>
  );
};
