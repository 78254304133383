import { FC, useEffect, useState } from 'react';

import { ActionType as EditorActionType, useEditorContext } from '@/context/EditorContext';
import { useSimulationContext } from '@/context/SimulationContext';

import { SolverSettings } from '@/components/SolverSettings';
import { SourceRecieverSettings } from '@/components/SourceRecieverSettings';
import { TrblMaterialIcon, TrblSolverSettingsIcon, TrblSoundSourceIcon } from '../Icons';
import { LayersTable } from '../LayersTable';
import { ActionType, useLibraryPanelContext } from '../LibraryPanel/LibraryPanelContext';
import { Divider } from '../Shared/Divider';
import { StatisticalEstimates } from '../StatisticalEstimates';

import { RunStatus } from '@/types';

import './styles.scss';

// Variable used to change the simulationKey to re-render components, when Edit popup has been closed
let simulationKeyIncrement = 0;

type SidePanelTabsProps = {
  sidepanelCollapsed: boolean;
};

export const SidePanelTabs: FC<SidePanelTabsProps> = ({ sidepanelCollapsed }) => {
  const [activeTab, setActiveTab] = useState('tabMat');
  const [sourceAndReceiversTabValue, setSourceAndReceiversTabValue] = useState(0);

  const [disable, setDisable] = useState(false);
  const { dispatch: editorDispatch, editSimulation, isInResultsMode, isAuralizerOpen, selected } = useEditorContext();

  const {
    simulationState: { selectedSimulation, availableSimulations },
  } = useSimulationContext();
  const { dispatch, isMaterialsLibraryOpen, isSourceDefinitionLibraryOpen } = useLibraryPanelContext();

  const [simulationKey, setSimulationKey] = useState('');

  useEffect(() => {
    if (selectedSimulation) {
      // update simulationKey if new simulation is selected, to trigger a re-render for components
      setSimulationKey(selectedSimulation.id);
    }
  }, [selectedSimulation?.id]);

  useEffect(() => {
    // check if hasBeenEdited changed and if simulation is Completed, then update simulationKey with
    // simulationKeyIncrement to trigger a re-render for components, works for both Edit and Revert
    if (editSimulation.showModal === false && selectedSimulation?.lastSimulationRun?.status === RunStatus.Completed) {
      simulationKeyIncrement++;
      setSimulationKey(selectedSimulation.id + simulationKeyIncrement.toString());
    }
  }, [selectedSimulation?.hasBeenEdited, editSimulation.showModal]);

  const openTab = (tab: string) => {
    if (tab !== activeTab) {
      dispatch({
        type: ActionType.CLOSE_LIBRARY_PANEL,
      });
    }
    setActiveTab(tab);
  };

  useEffect(() => {
    if (selectedSimulation?.extra.status === 1) {
      setDisable(true);
    } else {
      setDisable(false);
    }
  }, [selectedSimulation?.extra.status]);

  useEffect(() => {
    if (isInResultsMode) {
      if (isMaterialsLibraryOpen || isSourceDefinitionLibraryOpen) {
        dispatch({
          type: ActionType.CLOSE_LIBRARY_PANEL,
        });
      }
      if (selected) {
        editorDispatch({ type: EditorActionType.CLEAR_SELECTED });
      }
    }
  }, [isInResultsMode]);

  return (
    <div
      className={`tabs-container ${sidepanelCollapsed ? 'collapsed' : ''}`}
      style={{ visibility: availableSimulations?.length === 0 ? 'hidden' : 'visible' }}>
      <ul className="row-tabs">
        <li className={`tab mat ${activeTab === 'tabMat' ? 'active' : ''}`} id="tabMat">
          <button onClick={() => openTab('tabMat')}>
            <span className="tab-img mat">
              <TrblMaterialIcon fillExtra={activeTab === 'tabMat' ? '#00F5BA' : undefined} />
            </span>
            Materials
          </button>
        </li>
        <li className={`tab sr ${activeTab === 'tabSR' ? 'active' : ''}`} id="tabSR">
          <button onClick={() => openTab('tabSR')}>
            <span className="tab-img sr">
              <TrblSoundSourceIcon fill={activeTab === 'tabSR' ? '#00F5BA' : undefined} />
            </span>
            Sources / Receivers
          </button>
        </li>
        <li className={`tab set ${activeTab === 'tabSet' ? 'active' : ''}`} id="tabSet">
          <button onClick={() => openTab('tabSet')}>
            <span className="tab-img set">
              <TrblSolverSettingsIcon fill={activeTab === 'tabSet' ? '#00F5BA' : undefined} />
            </span>
            Settings
          </button>
        </li>
      </ul>

      <div className="tab-container">
        <div className={`tab-content mat ${activeTab === 'tabMat' ? 'active' : ''} ${disable ? 'disabled' : ''}`}>
          <div className="content">
            <LayersTable />
          </div>
          <Divider />
          <StatisticalEstimates />
        </div>

        <div className={`tab-content sr ${activeTab === 'tabSR' ? 'active' : ''} ${disable ? 'disabled' : ''}`}>
          {selectedSimulation && (
            <SourceRecieverSettings
              tabValue={sourceAndReceiversTabValue}
              setTabValue={setSourceAndReceiversTabValue}
              key={simulationKey}
            />
          )}
        </div>

        <div className={`tab-content set ${activeTab === 'tabSet' ? 'active' : ''} ${disable ? 'disabled' : ''}`}>
          {selectedSimulation && (
            // We cannot use the simulationKey when opening the auralizer since it stays the same although object itself is changed.
            // The editSimulation.showModal check is there to prevent double-rerending-bug which happens after user reverts changes
            // and selects a new options resulting in weird behavior. Instead we have this weird behavior!
            <SolverSettings
              key={isAuralizerOpen ? selectedSimulation.lastSimulationRun?.id : simulationKey}
              selectedSimulation={selectedSimulation}
            />
          )}
        </div>
      </div>
    </div>
  );
};
