import { PlotType } from './types';

export const PLOT_TYPES: PlotType[] = ['EDC', 'Impulse response', 'Frequency response', 'Spatial decay'];

export const TICK_TEXT = ['63', '125', '250', '500', '1k', '2k', '4k', '8k'];
export const TICK_TEXT_FOR_RESPONSE_PLOT = ['32', ...TICK_TEXT];

export const FREQUENCY_VALUES = [63, 125, 250, 500, 1000, 2000, 4000, 8000];
export const FREQUENCY_VALUES_FOR_RESPONSE_PLOT = [32, ...FREQUENCY_VALUES];

export const FREQUENCY_OPTIONS = [
  { value: '63', label: '63 Hz' },
  { value: '125', label: '125 Hz' },
  { value: '250', label: '250 Hz' },
  { value: '500', label: '500 Hz' },
  { value: '1000', label: '1k Hz' },
  { value: '2000', label: '2k Hz' },
  { value: '4000', label: '4k Hz' },
  { value: '8000', label: '8k Hz' },
];

export const FREQUENCY_OPTIONS_SPL = [
  ...FREQUENCY_OPTIONS,
  { value: 'aWeighted', label: 'A-weighted' },
  { value: 'aWeightedSpeech', label: 'Speech (A-weighted)' },
];

export const PARAMETER_OPTIONS = [
  { id: 'edt', name: 'EDT' },
  { id: 't20', name: 'T20' },
  { id: 't30', name: 'T30' },
  { id: 'c50', name: 'C50' },
  { id: 'c80', name: 'C80' },
  { id: 'd50', name: 'D50' },
  { id: 'ts', name: 'TS' },
  { id: 'g', name: 'G' },
  { id: 'spl', name: 'SPL' },
  { id: 'sti', name: 'STI' },
];

export const PARAMETERS_WITH_TRENDLINES = ['sti', 'spl'];

export const NC_CURVE_OPTIONS = [
  'NC15',
  'NC20',
  'NC25',
  'NC30',
  'NC35',
  'NC40',
  'NC45',
  'NC50',
  'NC55',
  'NC60',
  'NC65',
  'NC70',
];

export const MANUAL_RANGE_MARGIN_PERCENT = 10;

export const OCTAVE_BANDS = [63, 125, 250, 500, 1000, 2000, 4000, 8000];

// A-Weighting for frequency bands 64 to 8000 hz
export const A_WEIGHTING = [-26.2, -16.1, -8.6, -3.2, 0.0, 1.2, 1.0, -1.1];

// SPL of normal effort speech at 1m from speaker
export const SPL_OF_SPEECH_AT_1_METER = [44, 49.9, 54.3, 58.0, 52.0, 44.8, 38.8, 33.5];

export const TARGET_TYPE_OPTIONS = [
  {
    id: 'Above',
    name: 'Greater than ≥',
  },
  {
    id: 'Below',
    name: 'Less than ≤',
  },
  {
    id: 'Range',
    name: 'Range ≤ x ≤',
  },
];
