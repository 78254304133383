import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { useBaseContext } from '@/context/BaseContext';

import { Button } from '@mui/material';

import { TrblTooltip } from '@/components/Shared';
import { TrblIconButton } from '@/components/Shared/Buttons';
import { Text } from '@/components/Shared/Text';
import { TrblIcon } from '@/components/Icons';
import { OpenOfficeParameters } from '../OpenOfficeParameters';
import { ParameterPlot } from '../ParameterPlot';

import { AVAILABLE_PARAMETER_KEYS } from './constants';

import { ParsedResponseData } from '../ResponsePlot/types';
import { ParsedParameterData } from './types';

import classes from './styles.module.scss';

export const ParameterResults = ({
  parameterData,
  inTabs = false,
}: {
  parameterData: ParsedParameterData[];
  inTabs?: boolean;
}) => {
  const {
    state: { sidepanelCollapsed },
  } = useBaseContext();

  const [plotlyData, setPlotlyData] = useState<ParsedResponseData[]>([]);

  const [selectedParameter, setSelectedParameter] = useState('t20');
  const [availableResultParameters, setAvailableResultParameters] = useState<string[]>([]);
  const [hasTransitionFreq, setHasTransitionFreq] = useState(false);
  const [showTransitionFreq, setShowTransitionFreq] = useState(true);

  const handleCopyToClipboard = () => {
    // 1. Filter out the transition frequency data.
    // 2. Reduce the data to a string where each line contains the name of the data and the y values, tab separated
    // 3. Write to clipboard

    // find the regionally specific comma separator
    const numberFormat = new Intl.NumberFormat();
    const parts = numberFormat.formatToParts(1000.1);
    const regionalSeparator = parts.find((part) => part.type === 'decimal')!.value;
    const clipboardText = plotlyData
      .filter((x) => x.type !== 'scatter')
      .reduce((acc, cur) => {
        return acc + cur.name + '\t' + cur.y.join('\t').replaceAll('.', regionalSeparator) + '\n';
      }, '');

    navigator.clipboard.writeText(clipboardText);
    const toastId = toast.info('Copied to clipboard');
    setTimeout(() => {
      toast.dismiss(toastId);
    }, 2000);
  };

  useEffect(() => {
    let availableParameterKeys = AVAILABLE_PARAMETER_KEYS;

    if (parameterData.length) {
      if (parameterData.findIndex((x) => x.resultType === 'Hybrid' || x.resultType === 'GA') === -1) {
        availableParameterKeys = availableParameterKeys.filter((x) => x !== 'sti');
      } else {
        availableParameterKeys = [...availableParameterKeys, 'oop'];
      }

      if (!availableParameterKeys.includes(selectedParameter)) {
        setSelectedParameter('t20');
      }

      setAvailableResultParameters(availableParameterKeys);
    }
  }, [parameterData]);

  const isOpenOfficeParametersResults = selectedParameter === 'oop';

  return (
    <div
      className={`${classes.plot_container} ${inTabs ? classes.tabs : ''} ${
        sidepanelCollapsed ? classes.collapsed : ''
      }`}>
      <div>
        <div className={classes.plot_header}>
          <>
            <Text type="semibold-12px">Select parameter</Text>
            {!isOpenOfficeParametersResults && (
              <TrblTooltip title="Copy current values to clipboard">
                <span>
                  <TrblIconButton
                    label="Copy to clipboard"
                    icon={<TrblIcon icon="clipboard" />}
                    className={classes.action_button}
                    onClick={handleCopyToClipboard}
                  />
                </span>
              </TrblTooltip>
            )}
          </>
        </div>
        <div className={classes.plot_parameters}>
          {availableResultParameters.map((parameter) => (
            <Button
              key={parameter}
              variant={parameter === selectedParameter ? 'contained' : undefined}
              onClick={() => setSelectedParameter(parameter)}>
              {parameter}
            </Button>
          ))}
        </div>
      </div>
      {selectedParameter !== 'oop' ? (
        <div className={classes.plot_container__graph}>
          <ParameterPlot
            plotlyData={plotlyData}
            onPlotlyDataCreated={setPlotlyData}
            parameterData={parameterData}
            selectedParameter={selectedParameter}
            showTransitionFrequency={showTransitionFreq}
            setHasTransitionFrequency={setHasTransitionFreq}
          />
          {hasTransitionFreq && (
            <TrblTooltip title="Show/Hide transition frequency">
              <div
                className={classes.transition_freq_legend}
                style={{
                  opacity: showTransitionFreq ? 1 : 0.5,
                }}
                onClick={() => setShowTransitionFreq(!showTransitionFreq)}>
                <svg width="10" height="10" viewBox="0 0 10 3" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path opacity="0.8" d="M0 1.5L10 1.5" stroke="#DADADA" strokeWidth="2.5" strokeDasharray="2 2"></path>
                </svg>
                <Text type="semibold-10px" color="">
                  TF
                </Text>
              </div>
            </TrblTooltip>
          )}
        </div>
      ) : (
        <div className={classes.oop_container}>
          <OpenOfficeParameters parameterData={parameterData} />
        </div>
      )}
    </div>
  );
};
