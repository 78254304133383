import { RefObject, SyntheticEvent, useState } from 'react';

import { ClickAwayListener, Menu, MenuItem } from '@mui/material';

import { TrblMenuVerticalIcon } from '../Icons/TrblMenuVerticalIcon';
import { Text } from '../Shared/Text';

import { getIconSize } from './utils';

import { IActions } from '@/types';

import './styles.scss';

interface ActionMenuProps {
  id: string;
  actions: IActions[];
  classNames?: string;
  triggerRef?: RefObject<HTMLButtonElement> | null;
  size?: 'small' | 'medium' | 'large' | 'x-large';
  label?: string;
  title?: string;
  disabled?: boolean;
  tabIndex?: number;
}

export const TrblActionsMenu = ({
  id,
  actions,
  classNames = '',
  triggerRef,
  size = 'medium',
  label,
  title = 'Open to see item actions',
  disabled,
  tabIndex = 0,
}: ActionMenuProps) => {
  const [anchorElement, setAnchorElement] = useState<HTMLButtonElement | null>(null);

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setAnchorElement(e.currentTarget);
  };

  const doOnClick = (e: SyntheticEvent, cb: () => void) => {
    e.preventDefault();
    e.stopPropagation();
    cb();
    setAnchorElement(null);
  };

  const onClose = () => setAnchorElement(null);

  return (
    <ClickAwayListener onClickAway={onClose}>
      <div className={classNames}>
        <button
          disabled={disabled}
          className={`menu-btn ${anchorElement ? 'active' : ''} ${label ? 'with-label' : ''}`}
          aria-controls="action-menu"
          aria-haspopup="true"
          id={`actions-button-${id}`}
          onClick={handleClick}
          ref={triggerRef}
          title={title}
          tabIndex={tabIndex}>
          <TrblMenuVerticalIcon width={getIconSize(size)} height={getIconSize(size)} />
          {label && <Text type="medium-11px">{label}</Text>}
        </button>

        {!!anchorElement && (
          <Menu
            id={`project-actions-${id}`}
            className={`menu-list ${label ? 'with-label' : ''}`}
            anchorEl={anchorElement}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            keepMounted
            open={!!anchorElement}
            onClose={onClose}>
            {actions.map(({ onClick, key, value, disabled, hidden, title, icon }: IActions) => (
              <MenuItem
                style={{ display: hidden ? 'none' : 'block' }}
                disabled={disabled}
                key={key}
                title={title}
                onClick={(e: SyntheticEvent) => doOnClick(e, () => onClick())}
                value={value}>
                {icon !== undefined && icon}
                {value}
              </MenuItem>
            ))}
          </Menu>
        )}
      </div>
    </ClickAwayListener>
  );
};
