import React, { useState } from 'react';
import Plot from 'react-plotly.js';
import AutoSizer from 'react-virtualized-auto-sizer';
import { PlotMouseEvent } from 'plotly.js';

import { ReflectionDetails } from '@/components/Results/context/ReflectogramResultsContext';

import { PlotHeader } from './PlotHeader';

import { useSplPerBandPlotData } from './hooks/useSplPerBandPlotData';

import { AVAILABLE_FREQUENCY_BANDS_LABELS } from '../constants';
import { LAYOUT_CONFIG } from './constants';

import { SPLType } from './types';

import styles from './styles.module.scss';

type ReflectionsPlotProps = {
  data: ReflectionDetails | null;
  selectedFrequencyIndex: number | null;
  onFrequencySelected: (index: number) => void;
};

export const SPLPerBandPlot: React.FC<ReflectionsPlotProps> = ({
  data,
  selectedFrequencyIndex,
  onFrequencySelected,
}) => {
  const [selectedSplType, setSelectedSplType] = useState<SPLType>('actual');

  const plotData = useSplPerBandPlotData({
    data,
    selectedFrequencyIndex,
    selectedSplType,
  });

  const handleClick = (event: Readonly<PlotMouseEvent>) => {
    const frequencyBand = event.points[0].data.x[0] as string;
    const frequencyIndex = AVAILABLE_FREQUENCY_BANDS_LABELS.indexOf(frequencyBand);

    if (frequencyIndex !== undefined) {
      onFrequencySelected(frequencyIndex);
    }
  };

  return (
    <div className={styles['plot-container']}>
      <PlotHeader selectedSplType={selectedSplType} onSplTypeChange={setSelectedSplType} />
      <div className={styles['plot']}>
        <AutoSizer>
          {({ height, width }) => (
            <Plot
              data={plotData}
              layout={LAYOUT_CONFIG}
              style={{ width, height }}
              config={{ displaylogo: false, responsive: true, displayModeBar: false }}
              useResizeHandler={true}
              onClick={handleClick}
            />
          )}
        </AutoSizer>
      </div>
    </div>
  );
};
