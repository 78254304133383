import { FC, useState } from 'react';

import { Box, FormControl, InputLabel, ListSubheader, MenuItem, Select, SelectChangeEvent, Stack } from '@mui/material';

import { TrblToggle } from '@/components/Shared';
import { Checkbox } from '@/components/Shared/Checkbox';
import { Text } from '@/components/Shared/Text';
import { Marker } from '@/components/SourceRecieverSettings/Marker';

import classes from './styles.module.scss';

type SimulationFormMultiSelectProps = {
  label: string;
  values: string[];
  options: {
    id: string;
    name: string;
  }[];
  onChange: (value: string[]) => void;
  disabled?: boolean;
  placeholder?: string;
  allowSingleSelect?: boolean;
};

export const SimulationFormMultiSelect: FC<SimulationFormMultiSelectProps> = ({
  label,
  values,
  options,
  onChange,
  disabled,
  placeholder,
  allowSingleSelect = false,
}) => {
  const formControlId = `select-${label}-label`;

  /** If single select is allowed we default to that option */
  const [multiSelectEnabled, setMultiSelectEnabled] = useState(!allowSingleSelect);

  const handleChange = (event: SelectChangeEvent<string[] | string>) => {
    if (multiSelectEnabled) {
      // When multiselect is enabled, the event.target.value is an array of strings
      onChange(event.target.value as string[]);
    } else {
      // When multiselect is disabled, the event.target.value is a string but for consistency we pass to the onChange handler an array with a single value
      onChange([event.target.value as string]);
    }
  };

  const handleSelectAll = () => {
    if (values.length === options.length) {
      onChange([]);
    } else {
      onChange(options.map((option) => option.id));
    }
  };

  const handleToggleMultiselect = (checked: boolean) => {
    // If we are switching from multiselect to a single select we select the first option by default
    if (!checked) {
      if (options.length) {
        onChange([options[0].id]);
      }
    }

    setMultiSelectEnabled(checked);
  };

  return (
    <FormControl fullWidth disabled={disabled} className={classes.simulation_form_control__container}>
      <InputLabel id={formControlId}> {label} </InputLabel>
      {/* TODO: create a Treble Multi Select for forms to standardize the styling and behavior (?) */}
      <Select
        multiple={multiSelectEnabled}
        displayEmpty
        name={label}
        className={classes.treble_select}
        labelId={formControlId}
        id={formControlId}
        value={values}
        label={label}
        renderValue={(value) => {
          if (value.length === 0) {
            return <em>{placeholder}</em>;
          } else if (value.length === 1 && label == 'Receivers') {
            const valueIndex = options.findIndex((x) => x.id === value[0]);
            const valueText = options[valueIndex].name;
            return (
              <Box component="span" display="flex" alignItems="center">
                {label == 'Receivers' && <Marker color={'blue'} label={(valueIndex + 1).toString()} small />}
                <span className={classes.label}>{valueText}</span>
              </Box>
            );
          } else {
            const valueText = options
              .filter((o) => value.includes(o.id))
              .map((o) => o.name)
              .join(', ');
            return <span className={classes.label_multi}>{valueText}</span>;
          }
        }}
        onChange={handleChange}
        onClick={(e) => e.stopPropagation()}>
        <ListSubheader style={{ padding: '0px' }}>
          {/* We only display the multiselect toggle if single select is allowed, and the number of options is greather then 1 */}
          {allowSingleSelect && options.length !== 1 && (
            <Stack
              padding="10px 8px 10px 10px"
              gap="8px"
              alignItems={'center'}
              direction="row"
              justifyContent={'space-between'}>
              <Text type="regular-11px">{`Multiselect ${multiSelectEnabled ? 'on' : 'off'}`}</Text>
              <TrblToggle
                dark
                ariaLabel="Select multiple items toggle"
                checked={multiSelectEnabled}
                onChangeToggle={() => handleToggleMultiselect(!multiSelectEnabled)}
              />
            </Stack>
          )}
          {multiSelectEnabled && (
            <span className={classes.treble_select_menu} style={{ marginBottom: '0px' }} onClick={handleSelectAll}>
              <Checkbox
                id={'all'}
                label={
                  <Text type="medium-10px" color="#999999" uppercase>
                    Select all
                  </Text>
                }
                spaceBetween={true}
                labelAlignment={'left'}
                isChecked={values.length === options.length}
              />
            </span>
          )}
        </ListSubheader>

        {options.map((option, index) => (
          <MenuItem
            key={option.id}
            value={option.id}
            className={classes.treble_select_menu}
            selected={values.includes(option.id)}>
            {multiSelectEnabled ? (
              <Checkbox
                id={option.id}
                label={
                  <Box component="div" display="flex" alignItems="center" gap="7px">
                    {label == 'Receivers' && <Marker color={'blue'} label={(index + 1).toString()} small />}
                    <span className={classes.label}>{option.name}</span>
                  </Box>
                }
                spaceBetween={true}
                labelAlignment={'left'}
                isChecked={values.includes(option.id)}
              />
            ) : (
              <Box component="div" display="flex" alignItems="center" gap="7px">
                {label == 'Receivers' && <Marker color={'blue'} label={(index + 1).toString()} small />}
                <span className={classes.label}>{option.name}</span>
              </Box>
            )}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
