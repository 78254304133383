import React from 'react';

import { Text } from '@/components/Shared/Text';
import { TrblSelect } from '@/components/Shared/TrblSelect';

import { SPL_TYPE_OPTIONS } from './constants';

import { SPLType } from './types';

import styles from './styles.module.scss';

type PlotHeaderProps = {
  selectedSplType: SPLType;
  onSplTypeChange: (value: SPLType) => void;
};

export const PlotHeader: React.FC<PlotHeaderProps> = ({ selectedSplType, onSplTypeChange }) => (
  <div className={styles['plot-header']}>
    <Text type="semibold-12px">SPL per band</Text>
    <TrblSelect
      menuItems={SPL_TYPE_OPTIONS}
      value={selectedSplType}
      setValue={(value) => onSplTypeChange(value as SPLType)}
      minimal
      className={styles['header-dropdown']}
    />
  </div>
);
