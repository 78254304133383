/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from 'react';

import { ActionType, useResultsContext } from '../../context/ResultsContext';

import { SearchInputWrapper } from '@/components/Shared/SearchInputWrapper';
import { TrblTreeView } from '@/components/Shared/TreeView';
import { TreeStructure } from '@/components/Shared/TreeView/types';
import { useCreateFileStructure } from './useCreateFileStructure';
import { useFilterFileStructure } from './useFilterFileStructure';

import { NewComparion } from '../../types';

import styles from './styles.module.scss';

let delayTimer: ReturnType<typeof setTimeout>;

export const AddComparisonContent = ({ setShowPopup }: { setShowPopup: (showPopup: boolean) => void }) => {
  const { allSpacesWithSims, availableComparisons, dispatch } = useResultsContext();
  const fileStructure = useCreateFileStructure(allSpacesWithSims);
  const [filteredFileStructure, setFilteredFileStructure] = useState<TreeStructure>(fileStructure || {});
  const [filterWord, setFilterWord] = useState('');

  const filterFileStructure = useFilterFileStructure();

  useEffect(() => {
    if (
      fileStructure &&
      Object.keys(fileStructure).length > 0 &&
      availableComparisons.length > 0 &&
      availableComparisons[availableComparisons.length - 1].formState !== null
    ) {
      const { formState } = availableComparisons[availableComparisons.length - 1];
      if (formState) {
        const newFileStructure = filterFileStructure(formState.simulationId, { ...fileStructure });
        setFilteredFileStructure({ ...newFileStructure });
      }
    }
  }, []);

  const addComparison = (selectedItem: any) => {
    const { modelName, spaceName, simulation } = selectedItem.meta;
    const newComparison: NewComparion = {
      selectedSimulation: simulation,
      modelName,
      spaceName,
    };
    setShowPopup(false);
    dispatch({ type: ActionType.ADD_COMPARISON, newComparison });
  };

  const filterForSim = (event: any) => {
    const input = event?.target?.value;
    if (input.length > 0) {
      // delay search so we don't run the filtering function on every keystroke
      clearTimeout(delayTimer);
      delayTimer = setTimeout(() => {
        setFilterWord(input);

        if (fileStructure) {
          const newFileStructure = filterFileStructure(input, fileStructure, true);
          setFilteredFileStructure({ ...newFileStructure });
        }
      }, 200);
    } else {
      if (fileStructure) {
        setFilterWord('');

        setFilteredFileStructure(fileStructure);
      }
    }
  };

  return (
    <>
      <div className={styles.comparison_content__search}>
        <SearchInputWrapper>
          <input
            className={styles.search_sim}
            type="text"
            placeholder="Search for simulation"
            onChange={filterForSim}></input>
        </SearchInputWrapper>
      </div>
      <div className={styles.comparison_content__tree}>
        {fileStructure && (
          <TrblTreeView treeStructure={filteredFileStructure} onSelectItem={addComparison} filter={filterWord} />
        )}
      </div>
    </>
  );
};
