import { useMemo } from 'react';
import { Data } from 'plotly.js';

import { ReflectionDetails } from '@/components/Results/context/ReflectogramResultsContext';

import { AVAILABLE_FREQUENCY_BANDS_LABELS } from '../../constants';

import { roundFloat } from '@/utils/trebleFunctions';

import { SPLType } from '../types';

type UseSplPerBandPlotDataProps = {
  data: ReflectionDetails | null;
  selectedFrequencyIndex: number | null;
  selectedSplType: SPLType;
};

export const useSplPerBandPlotData = ({
  data,
  selectedFrequencyIndex,
  selectedSplType,
}: UseSplPerBandPlotDataProps): Data[] => {
  return useMemo(() => {
    if (!data) {
      return AVAILABLE_FREQUENCY_BANDS_LABELS.map((frequency) => ({
        x: [frequency],
        y: [0],
        type: 'bar',
        marker: {
          color: '#007BFF',
          line: {
            color: '#007BFF',
          },
        },
        text: '',
        hoverinfo: 'text',
        textposition: 'none',
      }));
    }

    const splValues = selectedSplType === 'actual' ? data.splPerBand : data.splPerBandRelative;

    return AVAILABLE_FREQUENCY_BANDS_LABELS.map((frequency, index) => {
      const yValue = [splValues[index]];
      const color = selectedFrequencyIndex === index ? '#00f5ba' : '#007BFF';
      const roundedSPL = roundFloat(splValues[index], 2);

      return {
        x: [frequency],
        y: yValue,
        type: 'bar',
        marker: {
          color,
          line: {
            color: '#007BFF',
          },
        },
        text: `SPL at ${frequency} Hz: ${roundedSPL} db`,
        hoverinfo: 'text',
        textposition: 'none',
      };
    });
  }, [data, selectedFrequencyIndex, selectedSplType]);
};
