import { ReactNode } from 'react';

import { SubscriptionBanners } from './components';
import { ServiceBanner } from '../Banners';
import { Header } from '../Header';
import { TrblChevronRight } from '../Icons';
import { TrblTooltip } from '../Shared';
import { TrblIconButton } from '../Shared/Buttons';
import { LoadingSpinner } from '../Shared/LoadingSpinner';

import './styles.scss';

type FixedSidepanelWidth = {
  isFixed: true;
  size: string;
};

type DynamicSidepanelWidth = {
  isFixed: false;
  size: 'small' | 'medium';
};

export type SidepanelWidth = FixedSidepanelWidth | DynamicSidepanelWidth;

type PageLayout = {
  isFetching?: boolean;
  extraHeader?: ReactNode;
  sidepanel?: ReactNode;
  mainContentMinWidth?: string;
  sidepanelWidth?: SidepanelWidth;
  sidepanelCollapsible?: boolean;
  sidepanelCollapsed?: boolean;
  sidepanelExtraHeader?: ReactNode;
  onCollapseSidepanel?: () => void;
  children: ReactNode;
};

export const PageLayout = ({
  isFetching,
  extraHeader,
  sidepanel,
  children,
  sidepanelCollapsible = false,
  sidepanelCollapsed = false,
  sidepanelWidth = { isFixed: false, size: 'medium' },
  sidepanelExtraHeader,
  onCollapseSidepanel,
  mainContentMinWidth,
}: PageLayout) => {
  const showSidepanel = !!sidepanel;

  return (
    <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      <SubscriptionBanners />
      <ServiceBanner />
      <Header
        hasSidepanel={showSidepanel}
        mainContent={extraHeader}
        sideContent={sidepanelExtraHeader}
        sidepanelWidth={sidepanelWidth}
        mainContentMinWidth={mainContentMinWidth}
      />
      <div
        className={`page-layout ${showSidepanel && !sidepanelWidth.isFixed ? `sidepanel-${sidepanelWidth.size}` : ''}`}>
        <div
          style={{
            minWidth: mainContentMinWidth,
            width: sidepanelWidth.isFixed ? `calc(100% - ${sidepanelWidth.size})` : undefined,
          }}
          className="page-layout-content">
          {isFetching ? <LoadingSpinner /> : children}
        </div>
        {showSidepanel && (
          <div
            className={`page-layout-sidepanel ${sidepanelCollapsed ? 'collapsed' : ''}`}
            style={sidepanelWidth.isFixed && !sidepanelCollapsed ? { width: sidepanelWidth.size } : undefined}>
            {sidepanel}
            {onCollapseSidepanel && (
              <TrblTooltip title={sidepanelCollapsed ? 'Expand sidepanel' : 'Collapse sidepanel'}>
                <span className="collapse-sidepanel">
                  <TrblIconButton
                    className={`collapse-button ${sidepanelCollapsed ? 'collapsed' : ''} ${
                      sidepanelCollapsible ? 'active' : ''
                    }`}
                    onClick={onCollapseSidepanel}
                    label={sidepanelCollapsed ? 'Expand sidepanel' : 'Collapse sidepanel'}
                    icon={<TrblChevronRight width="11" height="11" fill="#dadada" />}></TrblIconButton>
                </span>
              </TrblTooltip>
            )}
          </div>
        )}
      </div>
    </div>
  );
};
