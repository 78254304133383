import '@mui/material';
import { createTheme } from '@mui/material/styles';

import colors from './colors.module.scss';

// Typescript module augmentation
declare module '@mui/material/styles' {
  interface BreakpointOverrides {
    xs: true;
    sm: true;
    md: true;
    lg: true;
    xl: true;
    heightSmall: true;
  }
}

export const trebleTheme = createTheme({
  typography: {
    fontFamily: ['"Inter"', 'Helvetica', 'Arial', 'sans-serif'].join(','),
  },
});

export const darkTheme = createTheme({
  ...trebleTheme,
  breakpoints: {
    values: {
      ...trebleTheme.breakpoints.values,
      heightSmall: 850,
    },
  },
  components: {
    MuiTypography: {
      styleOverrides: {
        body2: {
          fontSize: '0.75rem',
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          fontSize: '14px',
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          fontSize: '14px',
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        outlined: {
          border: 'none',
          padding: '8px 12px',
          display: 'flex',
        },
      },
    },
    MuiNativeSelect: {
      styleOverrides: {
        select: {
          '> option': {
            color: '#dadada',
          },
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          width: '100%',
        },
      },
    },
    MuiListSubheader: {
      styleOverrides: {
        root: {
          backgroundColor: colors.gray100,
          color: colors.gray700,
          padding: '0 12px',
          lineHeight: 'inherit',
          fontSize: '11px',
          fontWeight: 'bold',
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          backgroundColor: colors.gray100,
          padding: '10px 12px',
          lineHeight: '12px',
          fontSize: '11px',
          '&.Mui-selected[aria-selected="false"]': {
            background: 'none',
          },
        },
      },
    },
    MuiList: {
      styleOverrides: {
        root: {
          backgroundColor: colors.gray100,
          maxHeight: '450px',
          borderTopLeftRadius: 0,
          borderTopRightRadius: 0,
          padding: 0,
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          minHeight: '28px',
        },
        indicator: {
          backgroundColor: '#DADADA',
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            fontWeight: 700,
          },
        },
      },
    },
  },
  palette: {
    mode: 'dark',
    primary: {
      main: colors.trebleGreen,
    },
    secondary: {
      main: colors.trebleLightGreen,
    },
    text: {
      primary: colors.gray1000,
    },
    background: {
      default: colors.gray100,
      paper: colors.gray200,
    },
    error: {
      main: colors.trebleRed,
    },
    warning: {
      main: colors.trebleOrange,
    },
    info: {
      main: colors.gray800,
    },
    action: {
      disabled: '#00000000',
    },
  },
});
