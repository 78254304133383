import { ReflectionDetails } from '@/components/Results/context/ReflectogramResultsContext';

import { Checkbox } from '@/components/Shared/Checkbox';

import { TimeOfArrivalGroup } from './constants';

import { roundFloat } from '@/utils/trebleFunctions';

import { ReceiverReflections, Reflection } from './types';

/**
 * Calculates the azimuth angle between two points in degrees, normalized to the range [0, 360).
 */
export const calculateAzimuth = (source: number[], receiver: number[]): number => {
  // Calculate the azimuth angle using the arctangent of the y and x differences and convert to degrees.
  let azimuth = Math.atan2(source[1] - receiver[1], source[0] - receiver[0]) * (180 / Math.PI);

  // Ensure azimuth is in the range [0, 360] degrees. If negative, adjust by adding 360.
  if (azimuth < 0) azimuth += 360;

  // Round the azimuth to 3 decimal places to avoid floating point errors.
  return roundFloat(azimuth, 3);
};

/**
 * Calculates the elevation angle between two points in degrees.
 */
export const calculateElevation = (source: number[], receiver: number[]): number => {
  // Calculate the horizontal distance between the source and receiver in the XY plane.
  const horizontalDistance = Math.sqrt((receiver[0] - source[0]) ** 2 + (receiver[1] - source[1]) ** 2);

  // Calculate the elevation angle using the arctangent of the z difference and horizontal distance, then convert to degrees.
  const elevation = Math.atan2(receiver[2] - source[2], horizontalDistance) * (180 / Math.PI);

  // Round the elevation to 3 decimal places to avoid floating point errors.
  return roundFloat(elevation, 3);
};

/**
 * Normalizes an azimuth difference to the range [-180, 180).
 */
export const normalizeAzimuthDifference = (azimuthDifference: number): number => {
  // Normalize azimuth difference to the range [-180, 180).
  // If the difference is greater than 180, adjust it to a negative equivalent.
  if (azimuthDifference > 180) azimuthDifference -= 360;
  return azimuthDifference;
};

/**
 * Calculates the elevation relative to the base elevation, considering the azimuth.
 */
export const calculateElevationRelative = (
  baseElevation: number,
  reflectionElevation: number,
  azimuthRelative: number
): number => {
  // Calculate the elevation difference between the base and reflection. A positive value means the reflection is above the base.
  const elevationDifference = -(baseElevation - reflectionElevation);

  // Constants for azimuth threshold and elevation correction.
  const AZIMUTH_THRESHOLD = 90;
  const ELEVATION_CORRECTION = 180;

  // If the relative azimuth is beyond the front-facing range, invert the elevation difference to simulate the reflection's relative elevation.
  if (azimuthRelative > AZIMUTH_THRESHOLD || azimuthRelative < -AZIMUTH_THRESHOLD) {
    return elevationDifference >= 0
      ? ELEVATION_CORRECTION - elevationDifference
      : -ELEVATION_CORRECTION - elevationDifference;
  }

  // Return the elevation difference if within the front-facing range.
  return elevationDifference;
};

/**
 * Maps a single reflection to detailed reflection results.
 */
export const mapSingleReflection = (
  reflection: Reflection,
  firstReflection: Reflection,
  baseAzimuth: number,
  baseElevation: number
): ReflectionDetails => {
  // Calculate azimuth difference and normalize it.
  const azimuthDifference = baseAzimuth - roundFloat(reflection.Azimuth, 3);
  const azimuthRelative = normalizeAzimuthDifference(azimuthDifference);

  // Calculate relative elevation considering the azimuth difference.
  const elevationRelative = calculateElevationRelative(
    baseElevation,
    roundFloat(reflection.Elevation, 3),
    azimuthRelative
  );

  // Return the detailed reflection data including relative values.
  return {
    order: reflection.Order,
    timeOfArrival: reflection.TimeOfArrival,
    timeOfArrivalRelative: reflection.TimeOfArrival - firstReflection.TimeOfArrival,
    path: reflection.Path,
    azimuth: reflection.Azimuth,
    azimuthRelative,
    elevation: reflection.Elevation,
    elevationRelative,
    spl: reflection.SPL,
    splRelative: reflection.SPL - firstReflection.SPL,
    splPerBand: reflection.SPLPerBand,
    splPerBandRelative: reflection.SPLPerBand.map((spl, index) => spl - firstReflection.SPLPerBand[index]),
    distance: reflection.Distance,
  };
};

/**
 * Maps reflection data to detailed reflection results, calculating relative azimuth and elevation.
 */
export const mapReflectionData = (data: ReceiverReflections): ReflectionDetails[] => {
  if (!data.Reflections.length) return [];

  // Sort reflections by time of arrival to process them in order.
  const reflectionsOrdered = [...data.Reflections].sort((a, b) => a.TimeOfArrival - b.TimeOfArrival);

  // Take the first reflection as the reference point for calculating relative times and SPL.
  const firstReflection = reflectionsOrdered[0];
  const firstReflectionPath = firstReflection.Path;

  // Source is the first point in the path, and receiver is the last point.
  const sourcePoint = firstReflectionPath[0];
  const receiverPoint = firstReflectionPath[firstReflectionPath.length - 1];

  // Calculate base azimuth and elevation assuming the receiver is directly facing the source.
  const baseAzimuth = calculateAzimuth(sourcePoint, receiverPoint);
  const baseElevation = calculateElevation(sourcePoint, receiverPoint);

  // Map each reflection using the extracted function
  return reflectionsOrdered.map((reflection) =>
    mapSingleReflection(reflection, firstReflection, baseAzimuth, baseElevation)
  );
};

export const renderTimeGroupMenuItem = (
  option: { value: string; label: string },
  index: number,
  timeOfArrivalGroups: TimeOfArrivalGroup[],
  selectedTimeOfArrivalGroupIndexes: number[]
) => (
  <div style={{ display: 'flex', gap: '6px', width: '100%', alignItems: 'center' }}>
    <hr
      style={{
        display: 'block',
        height: '1px',
        width: '10px',
        border: '0',
        borderTop: `2px solid ${timeOfArrivalGroups[index].color}`,
        margin: '1em 0',
        padding: '0',
      }}
    />
    <Checkbox
      id={option.value}
      label={option.label}
      spaceBetween={true}
      labelAlignment={'left'}
      isChecked={selectedTimeOfArrivalGroupIndexes.includes(Number(option.value))}
    />
  </div>
);
