import { FC } from 'react';

import { ActionType, useReflectogramResultsContext } from '@/components/Results/context/ReflectogramResultsContext';

import { Box } from '@mui/material';

import { ReflectionsPlot } from '@/components/Results/components/Reflectogram';
import { ReflectionAnglePlots } from '@/components/Results/components/Reflectogram/ReflectionAnglePlots';
import { SPLPerBandPlot } from '@/components/Results/components/Reflectogram/SPLPerBandPlot';
import { TIME_OF_ARRIVAL_SCALES } from '@/components/Results/components/SubHeaders/ReflectogramResultsHeader/constants';

import styles from './styles.module.scss';

export const ReflectogramResults: FC = () => {
  const {
    state: { filteredReflectionsData, selectedScale, selectedReflectionIndex, selectedFrequencyBandIndex },
    dispatch,
  } = useReflectogramResultsContext();

  const selectedTimeOfArrivalScale = TIME_OF_ARRIVAL_SCALES.find((scale) => scale.id === selectedScale);

  const handleReflectionSelected = (index: number) => {
    dispatch({
      type: ActionType.SET_SELECTED_REFLECTION_INDEX,
      reflectionIndex: index === selectedReflectionIndex ? null : index,
    });
  };

  const handleFrequencySelected = (index: number) => {
    dispatch({
      type: ActionType.SET_SELECTED_FREQUENCY_BAND_INDEX,
      frequencyBandIndex: index === selectedFrequencyBandIndex ? null : index,
    });
  };

  return (
    <Box component="div" className={styles['reflectogram-plots']} flexDirection={'row'} gap={'16px'}>
      <div className={styles['left-side']}>
        <ReflectionsPlot
          data={filteredReflectionsData}
          selectedReflectionIndex={selectedReflectionIndex}
          selectedFrequencyBandIndex={selectedFrequencyBandIndex}
          timeOfArrivalGroups={selectedTimeOfArrivalScale?.timeOfArrivalGroups ?? []}
          onSelectReflection={handleReflectionSelected}
        />
      </div>
      <div className={styles['right-side']}>
        <Box component="div" flex={'1 1 0'}>
          <ReflectionAnglePlots
            data={filteredReflectionsData}
            selectedReflectionIndex={selectedReflectionIndex}
            selectedFrequencyBandIndex={selectedFrequencyBandIndex}
            timeOfArrivalGroups={selectedTimeOfArrivalScale?.timeOfArrivalGroups ?? []}
            onSelectReflection={handleReflectionSelected}
          />
        </Box>
        <Box component="div" flex={'1 1 0'}>
          <SPLPerBandPlot
            data={selectedReflectionIndex !== null ? filteredReflectionsData[selectedReflectionIndex] : null}
            selectedFrequencyIndex={selectedFrequencyBandIndex}
            onFrequencySelected={handleFrequencySelected}
          />
        </Box>
      </div>
    </Box>
  );
};
