import { useEffect, useState } from 'react';
import Plot from 'react-plotly.js';
import { Data } from 'plotly.js';

type MaterialDetailsPlot = {
  ticktext: string[];
  tickvals: number[];
  xData: number[] | string[];
  yData: number[];
  yDataName: string;
  secondYData?: Array<number | undefined>;
  secondYDataName?: string;
  yAxesTitle: string;
  range?: number[];
  plotHeight: number;
  plotWidth?: number;
};

export const MaterialDetailsPlot = ({
  range,
  ticktext,
  tickvals,
  xData,
  yData,
  yDataName,
  secondYData,
  secondYDataName,
  yAxesTitle,
  plotHeight,
  plotWidth,
}: MaterialDetailsPlot) => {
  const [plotlyData, setPlotlyData] = useState<Data[]>([] as Data[]);

  useEffect(() => {
    if (xData && yData) {
      const firstPlot = createPlotObject(yDataName, xData, yData, '#00F5BA');

      let secondPlot = {};

      if (secondYData) {
        // @ts-expect-error Argument of type '(number | undefined)[]' is not assignable to parameter of type 'number[]'
        secondPlot = createPlotObject(secondYDataName || '', xData, secondYData, '#F8BB60');
      }

      const plotData = [firstPlot, secondPlot];
      setPlotlyData(plotData);
    }
  }, [xData, yData, secondYData]);

  const createPlotObject = (name: string, x: number[] | string[], y: number[], color: string) => {
    return {
      name,
      x,
      y,
      type: 'scatter',
      mode: 'lines+markers',
      line: {
        color,
      },
    };
  };

  return (
    <Plot
      data={plotlyData}
      config={{ displaylogo: false }}
      layout={{
        width: plotWidth || 400,
        height: plotHeight,
        autosize: true,
        legend: {
          y: 1.15,
          orientation: 'h',
          font: {
            color: '#DADADA',
            size: 10,
          },
        },
        xaxis: {
          title: {
            text: 'FREQUENCY [Hz]',
            font: {
              size: 10,
              color: '#ADADAD',
            },
          },
          tickmode: 'array',
          range: [Math.log10(50), Math.log10(12000)],
          type: 'log',
          ticktext,
          tickvals,
          gridcolor: '#3c3c3c',
          griddash: 'dot',
        },
        yaxis: {
          range: range,
          title: {
            text: yAxesTitle,
            font: {
              size: 10,
              color: '#ADADAD',
            },
            standoff: 10,
          },

          gridcolor: '#3c3c3c',
          griddash: 'dot',
          zeroline: false,
        },
        margin: {
          l: 54,
          r: 10,
          b: 40,
          t: 2,
          pad: 5,
        },

        paper_bgcolor: 'transparent',
        plot_bgcolor: 'transparent',
        font: {
          family: 'Inter, Helvetica, Arial, sans-serif',
          color: '#DADADA',
          size: 10,
        },
      }}
    />
  );
};
