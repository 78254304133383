import { useEffect, useState } from 'react';

import { Box, Grid } from '@mui/material';

import { DarkBox } from '@/components/Shared/Popup';
import { TabButtons } from '@/components/Shared/TabButtons';
import { AbsorptionCoefficient } from './AbsorptionCoefficient';
import { MaterialDetailsPlot } from './MaterialDetailsPlot';
import { MaterialInfo } from './MaterialInfo';

import {
  ABS_PLOT_TITLE,
  ABS_RANGE,
  ABS_TITLE,
  FREQ_LABELS,
  REF_PLOT_TITLE,
  REF_RANGE,
  REF_TITLE,
  REF_X_VALS,
  SURFACE_TITLE,
  TICK_VALS,
  TOGGLE_MATERIAL_REFLECTION_PLOT,
} from './constants';

import { Material } from '@/types';

import styles from './styles.module.scss';

export const MaterialDetailsContent = ({ material }: { material: Material }) => {
  const [realReflectionCoefficient, setRealReflectionCoefficient] = useState<number[]>([]);
  const [imagReflectionCoefficient, setImagReflectionCoefficient] = useState<number[]>([]);
  const [impedanceData, setImpedanceData] = useState<number[]>([]);
  const [selectedPlot, setSelectedPlot] = useState('Reflection');

  useEffect(() => {
    if (material) {
      const parsedMaterialMetadata = JSON.parse(material?.materialMetadataJson);
      setRealReflectionCoefficient(parsedMaterialMetadata.RealReflectionCoefficient || []);
      setImagReflectionCoefficient(parsedMaterialMetadata.ImagReflectionCoefficient || []);
      // When we get the impedance data we will populate it here
      setImpedanceData([]);
    }
  }, [material]);

  return (
    <Box component="div">
      <Grid container spacing={2} alignItems="stretch" mb={2}>
        <MaterialInfo material={material} />
      </Grid>
      <DarkBox>
        <Grid container>
          <Grid item xs={6}>
            <p className={styles['plot-title']}> {ABS_TITLE} </p>
            <AbsorptionCoefficient
              labels={FREQ_LABELS}
              absorptionCoefficients={material?.absorptionCoefficients ?? []}
            />
            <MaterialDetailsPlot
              range={ABS_RANGE}
              ticktext={FREQ_LABELS}
              tickvals={TICK_VALS}
              xData={TICK_VALS}
              yData={material?.absorptionCoefficients ?? []}
              yDataName="Real"
              secondYData={[]}
              yAxesTitle={ABS_PLOT_TITLE}
              plotHeight={280}
            />
          </Grid>
          <Grid item xs={6} className={styles['right-plot-container']}>
            <p className={styles['plot-title']}>{selectedPlot === 'Reflection' ? REF_TITLE : SURFACE_TITLE}</p>
            {impedanceData && impedanceData.length > 0 && (
              <div className={styles['tabs-buttons-container']}>
                <TabButtons
                  options={[
                    { key: 'Reflection', label: 'R', tooltip: TOGGLE_MATERIAL_REFLECTION_PLOT[0] },
                    { key: 'Specific', label: 'Z', tooltip: TOGGLE_MATERIAL_REFLECTION_PLOT[1] },
                  ]}
                  selectedValue={selectedPlot}
                  onChange={(value) => setSelectedPlot(value)}
                  small
                  dark
                />
              </div>
            )}
            {selectedPlot === 'Reflection' ? (
              <MaterialDetailsPlot
                ticktext={FREQ_LABELS}
                range={REF_RANGE}
                tickvals={TICK_VALS}
                xData={REF_X_VALS}
                yData={realReflectionCoefficient}
                yDataName="Real"
                secondYData={imagReflectionCoefficient}
                secondYDataName={'Imag'}
                yAxesTitle={REF_PLOT_TITLE}
                plotHeight={344}
              />
            ) : null}
          </Grid>
        </Grid>
      </DarkBox>
    </Box>
  );
};
