import { Layout } from 'plotly.js';

import { SelectOption } from '@/components/Shared/TrblSelect';

export const LAYOUT_CONFIG: Partial<Layout> = {
  barmode: 'group',
  xaxis: {
    type: 'category',
    title: {
      text: 'FREQUENCY [Hz]',
      standoff: 15,
      font: {
        size: 10,
        color: '#ADADAD',
      },
    },
    gridcolor: '#555555',
  },
  yaxis: {
    title: {
      text: 'SPL [dB]',
      standoff: 20,
      font: {
        size: 10,
        color: '#ADADAD',
      },
    },
    gridcolor: '#555555',
  },
  showlegend: false,
  title: '',
  plot_bgcolor: 'transparent',
  paper_bgcolor: '#272727',
  font: {
    family: 'Inter, Helvetica, Arial, sans-serif',
    color: '#f5f5f5',
    size: 10,
  },

  margin: {
    l: 40,
    r: 5,
    b: 50,
    t: 15,
    pad: 5,
  },
  autosize: true,
};

export const SPL_TYPE_OPTIONS: SelectOption[] = [
  { id: 'actual', name: 'Actual' },
  { id: 'relative', name: 'Rel. direct' },
];
