import { useEffect, useState } from 'react';

import { TrblTooltip } from '@/components/Shared';
import { AlphabetOption, ControlButton } from '@/components/Shared/Auralizer';
import { AmbisonicsButton } from '@/components/Shared/Auralizer/ControlButton/AmbisonicsButton';
import { ByPassIRButton } from '@/components/Shared/Auralizer/ControlButton/ByPassIRButton';
import { useFeatureFlags } from '@/components/FeatureToggles';
import { TrblAmbisonicsIcon, TrblBinauralIcon, TrblPauseIcon, TrblPlayIcon } from '@/components/Icons';
import { AudioEngine } from '../../AudioEngine';
import { ActionType, useAuralizerContext } from '../../AuralizerContext';
import { usePresetContext } from '../../PresetContext';

import { useSelectSimulationAsAurSim } from '../../hooks/useSelectSimulationAsAurSim';
import { useUpdatedReverb } from '../../hooks/useUpdateReverb';

import { Simulation, Source } from '@/types';

import '../../style.scss';

export const HeaderActionsLeft = ({
  originalSim,
  sources,
  isMinimized = false,
}: {
  originalSim: Simulation;
  sources: Source[];
  isMinimized: boolean;
}) => {
  const audioEngine = AudioEngine.getInstance();

  const { selectedAurSim, selectedReceiver, simsToCompare, isPlayingAuralization, dispatch } = useAuralizerContext();
  const { selectedSounds } = usePresetContext();

  const [hasSoundToPlay, setHasSoundToPlay] = useState(true);
  const [isPlaying, setIsPlaying] = useState<boolean | null>(false);
  const [isAmbisonicsActive, setIsAmbisonicsActive] = useState(false);
  const [hasMultichannel, setHasMultichannel] = useState(false);

  const selectSimulationAsAurSim = useSelectSimulationAsAurSim();
  const updateReverb = useUpdatedReverb();

  const { ambisonicsOutputFeature } = useFeatureFlags();

  useEffect(() => {
    if (selectedSounds) {
      const hasSound = Object.keys(selectedSounds).length > 0;
      setHasSoundToPlay(hasSound);
    }
  }, [selectedSounds]);

  useEffect(() => {
    if (isPlayingAuralization !== null && sources.length > 0) {
      const updatedPlaying = isPlayingAuralization && hasSoundToPlay;
      setIsPlaying(updatedPlaying);
      audioEngine.playbackToggle(updatedPlaying);

      if (!hasSoundToPlay && isPlayingAuralization) {
        dispatch({
          type: ActionType.SET_PLAYING_AURALIZATION,
          isPlaying: false,
        });
      }
    }
  }, [isPlayingAuralization, hasSoundToPlay, sources]);

  useEffect(() => {
    // check if soundcard has at least 9 ch out
    const hasMultichannel = audioEngine.checkMultiChannelOutput();
    // true or false should trigger UI update on button enabled or disabled
    setHasMultichannel(hasMultichannel);
  }, [hasMultichannel]);

  const togglePlaySound = () => {
    const newIsPlaying = !isPlaying;
    dispatch({
      type: ActionType.SET_PLAYING_AURALIZATION,
      isPlaying: newIsPlaying,
    });
  };

  const toggleAmbisonicsOutput = () => {
    const newIsAmbisonicsActive = !isAmbisonicsActive;
    setIsAmbisonicsActive(newIsAmbisonicsActive);
    newIsAmbisonicsActive ? audioEngine.setOutputAmbisonics() : audioEngine.setOutputBinaural();
  };

  const byPassIR = () => {
    audioEngine.bypassAll();
  };

  const revertByPassIR = () => {
    if (selectedReceiver && selectedAurSim) {
      updateReverb(selectedReceiver.receiver.id, selectedAurSim.id);
    }
  };

  return (
    <div className="left-content">
      <p className="auralizer-header-text"> Auralizer </p>
      <TrblTooltip
        title={`${
          !hasSoundToPlay
            ? 'Select sound to play auralization'
            : !isPlayingAuralization
            ? 'Play auralization'
            : 'Pause auralization'
        }`}>
        <span>
          <ControlButton
            disabled={!hasSoundToPlay}
            onClick={togglePlaySound}
            icon={isPlaying ? <TrblPauseIcon /> : <TrblPlayIcon />}
          />
        </span>
      </TrblTooltip>
      <TrblTooltip title="Bypass IR">
        <span>
          <ByPassIRButton
            disabled={!hasSoundToPlay}
            style={{
              marginLeft: '8px',
            }}
            onMouseEnter={byPassIR}
            onMouseLeave={revertByPassIR}
          />
        </span>
      </TrblTooltip>
      <TrblTooltip
        title={`${
          !hasMultichannel
            ? 'Multichannel output not supported'
            : !isAmbisonicsActive
            ? 'Activate multichannel ouptut'
            : 'Back to binaural'
        }`}>
        <span>
          {ambisonicsOutputFeature && (
            <AmbisonicsButton
              disabled={!hasMultichannel}
              style={{
                marginLeft: '8px',
              }}
              onClick={hasMultichannel ? toggleAmbisonicsOutput : undefined}
              icon={isAmbisonicsActive ? <TrblBinauralIcon /> : <TrblAmbisonicsIcon />}
            />
          )}
        </span>
      </TrblTooltip>
      <div className={`simulation-controls-header ${isMinimized ? 'active' : ''} `}>
        {isMinimized &&
          simsToCompare.map((sim: Simulation, index: number) => (
            <TrblTooltip title={sim.name} key={`${sim.id}-${originalSim.id}-`}>
              <span>
                <AlphabetOption
                  selectedOptionId={selectedAurSim?.id}
                  optionId={sim.id}
                  index={index}
                  onSelect={selectSimulationAsAurSim}
                />
              </span>
            </TrblTooltip>
          ))}
      </div>
    </div>
  );
};
