import { useState } from 'react';
import { generateUUID } from 'three/src/math/MathUtils';

import { ActionType, useEditorContext } from '@/context/EditorContext';
import { useModelContext } from '@/context/ModelContext';

import { useSaveGridReceivers } from './useSaveGridReceivers';

import { DEFAULT_RECEIVER_POSITION, GRID_RECEIVER_OFFSET_Y, GRID_RECEIVER_SAVE_TEXT } from '../constants';

import { mapToGridReceivers, newPointPosition } from '../utils';

import { GridReceiver, GridReceiverPoint, GridReceiverUserInput } from '@/context/EditorContext/types';

export const useGridReceivers = () => {
  const { gridReceivers, hiddenSurfaceReceivers, selected, dispatch } = useEditorContext();
  const { modelCenter, modelBoundingBox } = useModelContext();

  // Contains the id of a point just added. Will be set to undefined as soon as user leaves the label input field
  const [newPointId, setNewPointId] = useState<string>();

  const { saveGridReceivers } = useSaveGridReceivers();

  // Remove from local state and delete point in viewport. Triggers save simulation
  const handleRemoveGridReceiver = async (receiverId: string) => {
    const newGridReceivers = gridReceivers.filter((r) => r.id !== receiverId);

    const savedGridReceivers = await saveSimulationWithGridReceivers(newGridReceivers);
    if (savedGridReceivers) {
      dispatch({
        type: ActionType.SET_GRID_RECEIVERS,
        gridReceivers: newGridReceivers,
      });
    }
    // Remove selected item in viewport and delete the point
    dispatch({
      type: ActionType.CLEAR_SELECTED,
    });
  };

  const handleAddGridReceiver = async () => {
    const [x, y, z] = newPointPosition(
      [],
      modelCenter,
      modelBoundingBox?.min || null,
      DEFAULT_RECEIVER_POSITION,
      GRID_RECEIVER_OFFSET_Y
    );

    const label = '';
    const centerPoint = { x, y, z };
    const width = 2;
    const length = 2;
    const coarseness = 1;
    const pitch = 0;
    const yaw = 0;
    const roll = 0;
    const gridType = 'Plane';

    const id = generateUUID();

    const userInput: GridReceiverUserInput = {
      centerPoint,
      width,
      length,
      coarseness,
      pitch,
      yaw,
      roll,
    };

    const newGridReceivers = [...gridReceivers];
    const newGridReceiver: GridReceiver = {
      id,
      label,
      userInput,
      gridType,
      points: [],
      geometryInfo: {},
    };

    // There seem to be a lag in creating a grid receiver which affects the ViewportControl
    // UI in a way that the controls render at a position 0, 0, 0 for a split second
    // and then move to the right position. This is a hack to fix that buggy behaviour
    setTimeout(() => {
      dispatch({
        type: ActionType.SET_SELECTED,
        selected: {
          type: 'GridReceiver',
          id,
        },
      });
    }, 0);

    newGridReceivers.push(newGridReceiver);
    const savedGR = await saveSimulationWithGridReceivers(newGridReceivers);
    if (savedGR) {
      dispatch({
        type: ActionType.SET_GRID_RECEIVERS,
        gridReceivers: newGridReceivers,
      });
    }
  };

  const handleGridReceiverVisibility = (gridReceiver: GridReceiver) => {
    const newHiddenSurfaceReceivers: GridReceiver[] = [...hiddenSurfaceReceivers];
    const hiddenIndex = newHiddenSurfaceReceivers.findIndex((receiver) => receiver.id === gridReceiver.id);
    if (hiddenIndex === -1) {
      // if the surface receiver does not exist in the array we add it
      newHiddenSurfaceReceivers.push(gridReceiver);
    } else {
      // if it does extist we remove it
      newHiddenSurfaceReceivers.splice(hiddenIndex, 1);
    }
    dispatch({
      type: ActionType.SET_HIDDEN_SURFACE_RECEIVERS,
      hiddenSurfaceReceivers: newHiddenSurfaceReceivers,
    });
  };

  // Event handler when surface receiver label is changed from input
  const handleGridReceiverLabelChange = async (receiverIndex: number, value: string) => {
    const newGridReceivers = [...gridReceivers];
    const newGridReceiver = {
      ...newGridReceivers[receiverIndex],
      label: value,
    };
    newGridReceivers[receiverIndex] = newGridReceiver;
    const savedGridReceivers = await saveSimulationWithGridReceivers(newGridReceivers);
    if (savedGridReceivers) {
      dispatch({
        type: ActionType.SET_GRID_RECEIVERS,
        gridReceivers: newGridReceivers,
      });
    }

    // As soon as label change is triggered we don't consider this point to be new anymore
    if (newPointId === newGridReceiver.id) {
      setNewPointId(undefined);
    }
  };

  // Event handler when x,y,z coordinates are changed from inputs
  const saveGridReceiver = async () => {
    const newGridReceivers = mapToGridReceivers(gridReceivers);

    const savedGridReceivers = await saveSimulationWithGridReceivers(gridReceivers);
    if (savedGridReceivers) {
      dispatch({
        type: ActionType.SET_GRID_RECEIVERS,
        gridReceivers: newGridReceivers,
      });
    }
  };

  // Event handler when x,y,z coordinates are changed from inputs
  const handleGridReceiverCoordinateChange = async (gridReceiverIndex: number, x?: number, y?: number, z?: number) => {
    if (x == undefined || y == undefined || z == undefined) {
      return;
    }

    const newCenterPoint = { x, y, z };
    const newGridReceivers = [...gridReceivers];
    const newGridReceiver = {
      ...newGridReceivers[gridReceiverIndex],
      userInput: {
        ...newGridReceivers[gridReceiverIndex].userInput,
        centerPoint: newCenterPoint,
      },
    };

    newGridReceivers[gridReceiverIndex] = newGridReceiver;
    const savedGridReceivers = await saveSimulationWithGridReceivers(newGridReceivers);

    if (savedGridReceivers) {
      dispatch({
        type: ActionType.SET_GRID_RECEIVERS,
        gridReceivers: newGridReceivers,
      });
    }
  };

  // Event handler when source parameters are changed from inputs
  const handleGridReceiverParamChange = async (
    gridReceiverIndex: number,
    param: 'pitch' | 'yaw' | 'roll',
    value?: number
  ) => {
    const newGridReceivers = [...gridReceivers];
    const newGridReceiver: GridReceiver = {
      ...newGridReceivers[gridReceiverIndex],
      userInput: {
        ...newGridReceivers[gridReceiverIndex].userInput,
        [param]: value,
      },
    };

    newGridReceivers[gridReceiverIndex] = newGridReceiver;
    const savedGridReceivers = await saveSimulationWithGridReceivers(newGridReceivers);
    if (savedGridReceivers) {
      dispatch({
        type: ActionType.SET_GRID_RECEIVERS,
        gridReceivers: newGridReceivers,
      });
    }
  };

  // Event handler when source size are changed from inputs
  const handleGridReceiverSizeChange = async (gridReceiverIndex: number, value: number[]) => {
    const newGridReceivers = [...gridReceivers];
    const newGridReceiver = {
      ...newGridReceivers[gridReceiverIndex],
      userInput: {
        ...newGridReceivers[gridReceiverIndex].userInput,
        width: value[0],
        length: value[1],
        coarseness: value[2],
      },
    };

    newGridReceivers[gridReceiverIndex] = newGridReceiver;
    const savedGridReceivers = await saveSimulationWithGridReceivers(newGridReceivers);
    if (savedGridReceivers) {
      dispatch({
        type: ActionType.SET_GRID_RECEIVERS,
        gridReceivers: newGridReceivers,
      });
    }
  };

  // Event handler when source size are changed from inputs
  const handleGridReceiverPointsChange = async (gridReceiverIndex: number, value: GridReceiverPoint[]) => {
    const newGridReceivers: GridReceiver[] = [...gridReceivers];
    const newGridReceiver: GridReceiver = {
      ...newGridReceivers[gridReceiverIndex],
      points: value,
    };

    newGridReceivers[gridReceiverIndex] = newGridReceiver;
    const savedGridReceivers = await saveSimulationWithGridReceivers(newGridReceivers);
    if (savedGridReceivers) {
      dispatch({
        type: ActionType.SET_GRID_RECEIVERS,
        gridReceivers: newGridReceivers,
      });
    }
  };

  // Remove all from local state and delete points in viewport. Triggers save simulation
  const handleRemoveAllGridReceivers = async () => {
    const savedReceivers = await saveSimulationWithGridReceivers([]);
    if (savedReceivers) {
      dispatch({
        type: ActionType.SET_GRID_RECEIVERS,
        gridReceivers: [],
      });
    }

    dispatch({
      type: ActionType.CLEAR_SELECTED,
    });
  };

  const saveSimulationWithGridReceivers = async (gridReceivers: GridReceiver[]) => {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve) => {
      const updatedSimulation = await saveGridReceivers(gridReceivers);
      if (updatedSimulation) {
        dispatch({
          type: ActionType.SHOW_EDIT_MODAL,

          editSimulation: { showModal: true, updatedSimulation, saveText: GRID_RECEIVER_SAVE_TEXT },
        });
        resolve(null);
      } else {
        resolve(gridReceivers);
      }
    });
  };

  return {
    selectedPointId: selected?.type === 'GridReceiver' ? selected.id : undefined,
    newPointId,
    handleAddGridReceiver,
    handleGridReceiverVisibility,
    handleGridReceiverCoordinateChange,
    handleGridReceiverParamChange,
    handleGridReceiverSizeChange,
    handleGridReceiverPointsChange,
    handleGridReceiverLabelChange,
    handleRemoveGridReceiver,
    handleRemoveAllGridReceivers,
    saveGridReceiver,
  };
};
