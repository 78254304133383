import { useEffect, useState } from 'react';

import { useSimulationContext } from '@/context/SimulationContext';

import { EMPTY_GUID } from '@/components/Shared/constants';

import styles from '../styles.module.scss';

const FREQUENCIES = ['63', '125', '250', '500', '1k', '2k', '4k', '8k'];

export const SabineEstimateTable = () => {
  const {
    simulationState: { sabineEstimate, selectedSimulation },
  } = useSimulationContext();

  const [showSabineEstimate, setShowSabineEstimate] = useState(false);

  useEffect(() => {
    if (
      selectedSimulation?.modelSettings &&
      Object.values(selectedSimulation.modelSettings.materialIdByObjectId).indexOf(EMPTY_GUID) > -1
    ) {
      setShowSabineEstimate(false);
    } else {
      setShowSabineEstimate(true);
    }
  }, [selectedSimulation?.modelSettings?.materialIdByObjectId]);

  return (
    <table className={styles['se-table']}>
      <tbody>
        <tr>
          {FREQUENCIES.map((freq: string) => (
            <th key={freq}>{freq}</th>
          ))}
        </tr>
        <tr>
          {showSabineEstimate && sabineEstimate.length > 0
            ? sabineEstimate.map((est: number, index: number) => <td key={index}>{est}</td>)
            : FREQUENCIES.map((_, index: number) => <td key={index}>-</td>)}
        </tr>
      </tbody>
    </table>
  );
};
