export const FREQUENCY_BANDS = ['63', '125', '250', '500', '1k', '2k', '4k', '8k'];
export const FREQUENCY_VALUES = [63, 125, 250, 500, 1000, 2000, 4000, 8000];

export const TOGGLE_2D_PLOT_HOVER_TEXT = ['Top view', 'Side view'];

export const CORRECT_IR_SPL_TEXT = `When enabled, the impulse response gets corrected with the on axis SPL levels. 
             
It is recommended to enable this for most amplified sources, but to disable it for sources where the response is embedded in the source signal. 

Note that the SPL parameter and frequency response plots in the results will always be corrected`;

export const USE_SPL_AS_STI_TEXT = `SPL as STI speech level is not available at the moment`;

export const SOURCE_DEFINITION_WARNING_TEXT = `Currently Treble only models source directivity in the geometrical acoustics solver`;

// A-Weighting
export const aWeighting = [-26.2, -16.1, -8.6, 3.2, 0.0, 1.2, 1.0, -1.1];

export const ZERO_EQ_VALUES = [0, 0, 0, 0, 0, 0, 0, 0];
export const DEFAULT_SPL_VALUES = [94, 94, 94, 94, 94, 94, 94, 94];
export const DEFAULT_SPL_VALUE = 94;

export const FREQUENCY_VALUES_PER_THIRD_OCTAVE = [
  25, 32, 40, 50, 63, 80, 100, 125, 160, 200, 250, 315, 400, 500, 630, 800, 1000, 1250, 1600, 2000, 2500, 3150, 4000,
  5000, 6300, 8000, 10000, 12500, 16000, 20000,
];

export const FREQUENCY_VALUES_TICKS = [32, 63, 125, 250, 500, 1000, 2000, 4000, 8000, 16000];

export const FREQUENCY_TEXT_TICKS = ['32', '63', '125', '250', '500', '1k', '2k', '4k', '8k', '16k'];

export const INTERPOLETED_HOVER_TEXT = (
  <div>
    Interpolated data. See{' '}
    <a
      style={{ textDecoration: 'underline' }}
      href="https://docs.treble.tech/user-guide/source-definitions/uploading"
      target="_blank">
      documentation
    </a>
  </div>
);
