import { MouseEventHandler, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { ActionType as ResultsActionType, useResultsContext } from '../../context/ResultsContext';
import { useResultComparisonContext } from './context/ResultComparisonContext';
import { useEditorContext } from '@/context/EditorContext';

import { TrblIconButton } from '@/components/Shared/Buttons';
import { TrblDeleteIcon } from '@/components/Icons';
import { DownloadResults } from '../DownloadResults/DownloadResults';
import { SimulationForm } from './SimulationForm';
import { SimulationFormGridReceivers } from './SimulationFormGridReceivers';

import {
  useGetLastGridReceiverResultsBySimulationId,
  useGetLastSolveResultsBySimulationId,
  useGetSimulationById,
} from '@/hooks';

import { ActionType } from './constants';

import { ResultComparisonState } from '../../types';
import { ResultsView } from '@/context/EditorContext/types';
import { ModelInformationDto, SourceResults } from '@/types';

import classes from './styles.module.scss';

type ResultComparison = {
  modelInformationData: ModelInformationDto;
  defaultState: ResultComparisonState | null;
  index: number;
  color: string;
  isSelected: boolean;
  isMinimized: boolean;
};

export const ResultComparison = ({
  color,
  defaultState,
  modelInformationData,
  index,
  isSelected,
  isMinimized,
}: ResultComparison) => {
  const { allSpacesWithSims, dispatch: resultsDispatch } = useResultsContext();

  const [searchParams] = useSearchParams();

  const { dispatch, state } = useResultComparisonContext();

  const { resultsView } = useEditorContext();

  // fetch the last solve result by a simulation id
  // once the user selects a simulation
  const [modelName, setModelName] = useState('');
  const [spaceName, setSpaceName] = useState('');
  const [simulationId, setSimulationId] = useState('');

  const { data: newSimulationObject } = useGetSimulationById(simulationId, true);
  const { data: lastSolveResults, isLoading: loadingSolveResults } = useGetLastSolveResultsBySimulationId(
    state.simulationId
  );
  const { data: lastGridResults, isLoading: loadingGridResults } = useGetLastGridReceiverResultsBySimulationId(
    state.simulationId
  );

  useEffect(() => {
    if (newSimulationObject) {
      dispatch({
        type: ActionType.SELECT_SIMULATION,
        payload: {
          modelName,
          spaceName,
          selectedSimulation: newSimulationObject,
        },
      });
    }
  }, [newSimulationObject]);

  useEffect(() => {
    if (index > 0 && defaultState) {
      const { modelName, spaceName, selectedSimulation } = defaultState;
      if (selectedSimulation) {
        setModelName(modelName);
        setSpaceName(spaceName);
        setSimulationId(selectedSimulation.id);
      }
    }
  }, [index, defaultState]);

  useEffect(() => {
    if (index === 0) {
      const paramSimulationId = searchParams.get('sid');
      if (allSpacesWithSims.length > 0 && paramSimulationId) {
        const space = allSpacesWithSims.find((x) => x.simulations.some((y) => y.id === paramSimulationId));
        if (space) {
          setModelName(space.modelBaseName);
          setSpaceName(space.spaceName);
          setSimulationId(paramSimulationId);
        }
      }
    }
  }, [index, searchParams, allSpacesWithSims, modelInformationData]);

  // Dispatch an action to set the lastSolveResults when we get new solve result or when we switch back from grid receiver view
  const notInGridReceiverView = resultsView !== ResultsView.ResultsGridReceiversView;
  useEffect(() => {
    if (lastSolveResults?.sourceResults.length && notInGridReceiverView) {
      dispatch({
        type: ActionType.UPDATE_SOLVE_RESULTS,
        payload: lastSolveResults,
      });
    }
  }, [lastSolveResults, notInGridReceiverView]);

  useEffect(() => {
    if (lastGridResults?.length && resultsView === ResultsView.ResultsGridReceiversView) {
      dispatch({
        type: ActionType.UPDATE_GRID_RECEIVERS_SOLVE_RESULTS,
        payload: lastGridResults,
      });
    }
  }, [lastGridResults, resultsView]);

  useEffect(() => {
    // when the simulation data gets updated we want to let the
    // parent know so the next child can copy the same state
    // Also we use this to let the plots know if the title changed
    if (state.simulationData !== null) {
      resultsDispatch({ type: ResultsActionType.UPDATE_COMPARISON, update: { color, formState: state } });
    }
  }, [state.simulationData, state.title]);

  const removeComparison: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.stopPropagation();
    resultsDispatch({ type: ResultsActionType.REMOVE_COMPARISON, color });
  };

  const handleSelect = () => {
    resultsDispatch({ type: ResultsActionType.SET_SELECTED_COMPARISON_INDEX, selectedComparisonIndex: index });
  };

  return !(loadingGridResults || loadingSolveResults) ? (
    <div
      onClick={handleSelect}
      className={`${classes.simulation_selector__container} ${isSelected ? classes.selected : ''} ${
        isMinimized ? classes.minimized : ''
      } ${
        resultsView !== ResultsView.ResultsGridReceiversView ||
        state.selectedSimulation?.lastSimulationRun?.gridReceivers
          ? ''
          : classes.no_receivers
      }`}>
      {resultsView !== ResultsView.ResultsGridReceiversView ? (
        <SimulationForm
          spaceName={state.spaceName}
          modelName={state.modelName}
          color={color}
          isReflectionResults={resultsView === ResultsView.ResultsReflectogramView}
        />
      ) : (
        <SimulationFormGridReceivers spaceName={state.spaceName} modelName={state.modelName} color={color} />
      )}
      <div className={classes.simulation_selector__footer}>
        {resultsView !== ResultsView.ResultsGridReceiversView &&
        resultsView !== ResultsView.ResultsReflectogramView &&
        state.simulationData?.selectedSourceObject ? (
          <DownloadResults
            selectedSimulation={state.selectedSimulation}
            solveResultId={
              // We take the solveResultId found on the taskResult object for the first reicever, they will always be the same for all receivers for the selected source
              (state.simulationData?.selectedSourceObject as SourceResults).taskResultForReceiver?.[0]?.solveResultId
            }
            simulationId={state.simulationId}
          />
        ) : null}
        {index > 0 ? (
          <TrblIconButton
            onClick={removeComparison}
            className={classes.remove_button}
            label="Remove comparison"
            icon={<TrblDeleteIcon />}
          />
        ) : null}
      </div>
    </div>
  ) : null;
};
