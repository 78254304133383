import { ActionType, useReflectogramResultsContext } from '@/components/Results/context/ReflectogramResultsContext';

import { useArrowKeyPress } from './';

export const useReflectogramKeyPressHandlers = (selectedReflectionIndex: number | null) => {
  const {
    dispatch,
    state: { filteredReflectionsData },
  } = useReflectogramResultsContext();

  const handleSelectPreviousIndex = () => {
    let newIndex: number | null;
    if (selectedReflectionIndex === null) {
      newIndex = filteredReflectionsData.length - 1;
    } else if (selectedReflectionIndex === 0) {
      newIndex = null;
    } else {
      newIndex = selectedReflectionIndex - 1;
    }

    dispatch({
      type: ActionType.SET_SELECTED_REFLECTION_INDEX,
      reflectionIndex: newIndex,
    });
  };

  const handleSelectNextIndex = () => {
    let newIndex: number | null;
    if (selectedReflectionIndex === null) {
      newIndex = 0;
    } else if (selectedReflectionIndex === filteredReflectionsData.length - 1) {
      newIndex = null;
    } else {
      newIndex = selectedReflectionIndex + 1;
    }

    dispatch({
      type: ActionType.SET_SELECTED_REFLECTION_INDEX,
      reflectionIndex: newIndex,
    });
  };

  useArrowKeyPress(
    filteredReflectionsData.length ? handleSelectPreviousIndex : null,
    filteredReflectionsData.length ? handleSelectNextIndex : null
  );

  return {
    handleSelectPreviousIndex,
    handleSelectNextIndex,
  };
};
