import React, { ForwardedRef, useEffect, useState } from 'react';

import { TrblNumberInput } from '@/components/Shared/NumberInput';
import { TrblIcon } from '@/components/Icons';
import { TrblEditableLabel } from '../../Shared/TrblEditableLabel';
import { Marker } from '../Marker';

import { Receiver } from '@/context/EditorContext/types';

import styles from '../styles.module.scss';

type ReceiverRowProps = {
  receiver: Receiver;
  index: number;
  isSelected: boolean;
  isDisabled: boolean;
  readonly: boolean;
  isNewPoint: boolean;
  onSelect: () => void;
  onDelete: () => void;
  onChangeLabel: (label: string) => void;
  onChangeAxis: (axis: 'x' | 'y' | 'z', value?: number) => void;
};

export const ReceiverRow = React.forwardRef(
  (
    {
      receiver,
      index,
      isSelected,
      isDisabled,
      readonly,
      isNewPoint,
      onSelect,
      onDelete,
      onChangeAxis,
      onChangeLabel,
    }: ReceiverRowProps,
    ref: ForwardedRef<HTMLDivElement>
  ) => {
    const [posX, setPosX] = useState<number | undefined>(receiver.x);
    const [posY, setPosY] = useState<number | undefined>(receiver.y);
    const [posZ, setPosZ] = useState<number | undefined>(receiver.z);

    useEffect(() => {
      if (receiver.x !== posX) setPosX(receiver.x);
    }, [receiver.x]);
    useEffect(() => {
      if (receiver.y !== posY) setPosY(receiver.y);
    }, [receiver.y]);
    useEffect(() => {
      if (receiver.z !== posZ) setPosZ(receiver.z);
    }, [receiver.z]);

    const handlePosXBlur = (value: number | undefined) => {
      if (receiver.x !== value) {
        onChangeAxis('x', value);
      }
    };
    const handlePosYBlur = (value: number | undefined) => {
      if (receiver.y !== value) {
        onChangeAxis('y', value);
      }
    };
    const handlePosZBlur = (value: number | undefined) => {
      if (receiver.z !== value) {
        onChangeAxis('z', value);
      }
    };

    const handleLabelChange = (value: string) => {
      if (receiver.label !== value) {
        onChangeLabel(value);
      }
    };

    const handleContainerClick = () => {
      onSelect();
    };

    const handleDelete = (e: React.MouseEvent) => {
      e.stopPropagation();
      onDelete();
    };

    return (
      <div
        ref={ref}
        onClick={handleContainerClick}
        className={`${styles['row-container']} ${isSelected ? styles['selected'] : ''} ${
          isDisabled || readonly ? styles['disabled'] : ''
        }`}>
        <div style={{ padding: '2px 0', width: 180 }}>
          <TrblEditableLabel
            label={receiver.label}
            placeholder={receiver.label ? undefined : `Receiver ${index + 1}`}
            editableOnRender={isNewPoint}
            onBlur={handleLabelChange}
            isDisabled={isDisabled || readonly}
          />
        </div>
        <div className={styles['settings-row']}>
          <Marker isSelected={isSelected} color="blue" label={(index + 1).toString()} />
          <div className={styles['coordinate-inputs-wrapper']}>
            <TrblNumberInput
              value={posX}
              step={0.5}
              decimals={2}
              onChange={setPosX}
              onBlur={handlePosXBlur}
              startAdornment={'X'}
              alignment={'right'}
              readOnly={readonly}
            />
            <TrblNumberInput
              value={posY}
              step={0.5}
              decimals={2}
              onChange={setPosY}
              onBlur={handlePosYBlur}
              startAdornment={'Y'}
              alignment={'right'}
              readOnly={readonly}
            />
            <TrblNumberInput
              value={posZ}
              step={0.1}
              decimals={2}
              onChange={setPosZ}
              onBlur={handlePosZBlur}
              startAdornment={'Z'}
              alignment={'right'}
              readOnly={readonly}
            />
          </div>
          {!readonly && (
            <div className={styles['point-icons']}>
              <span onClick={handleDelete} title="Delete receiver">
                <TrblIcon icon="delete" hoverColor="#f5f5f5" />
              </span>
            </div>
          )}
        </div>
      </div>
    );
  }
);
