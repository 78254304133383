import { createContext, ReactNode, useContext, useEffect, useMemo, useReducer } from 'react';

import { TIME_OF_ARRIVAL_SCALES } from '../components/SubHeaders/ReflectogramResultsHeader/constants';
import { useResultsContext } from './ResultsContext';

type ReflectogramResultsProviderProps = { children: ReactNode };

type ReflectionPath = [number, number, number][];

export type ReflectionDetails = {
  order: number;
  path: ReflectionPath;
  timeOfArrival: number;
  timeOfArrivalRelative: number;
  azimuth: number;
  azimuthRelative: number;
  elevation: number;
  elevationRelative: number;
  spl: number;
  splRelative: number;
  splPerBand: number[];
  splPerBandRelative: number[];
  distance: number;
};

enum ActionType {
  RESET_STATE = 'RESET_STATE',
  RESET_SELECTED_FILTERS = 'RESET_SELECTED_FILTERS',
  SET_REFLECTIONS_DOWNLOAD_INFO = 'SET_REFLECTIONS_DOWNLOAD_INFO',
  SET_REFLECTIONS_DATA = 'SET_REFLECTIONS_DATA',
  SET_SELECTED_REFLECTION_INDEX = 'SET_SELECTED_REFLECTION_INDEX',
  SET_SELECTED_FREQUENCY_BAND_INDEX = 'SET_SELECTED_FREQUENCY_BAND_INDEX',
  SET_SELECTED_SCALE = 'SET_SELECTED_SCALE',
  SET_SELECTED_TIME_OF_ARRIVAL_GROUP_INDEXES = 'SET_SELECTED_TIME_OF_ARRIVAL_GROUP_INDEXES',
}

type ReflectogramResultsContextAction =
  | { type: ActionType.RESET_STATE }
  | { type: ActionType.RESET_SELECTED_FILTERS }
  | { type: ActionType.SET_REFLECTIONS_DOWNLOAD_INFO; downloadInfo: { id: number; downloadUrl: string } | null }
  | { type: ActionType.SET_REFLECTIONS_DATA; reflectionsData: ReflectionDetails[] }
  | { type: ActionType.SET_SELECTED_REFLECTION_INDEX; reflectionIndex: number | null }
  | { type: ActionType.SET_SELECTED_FREQUENCY_BAND_INDEX; frequencyBandIndex: number | null }
  | { type: ActionType.SET_SELECTED_SCALE; scale: string }
  | { type: ActionType.SET_SELECTED_TIME_OF_ARRIVAL_GROUP_INDEXES; indexes: number[] };

type State = {
  reflectionsDataDownloadInfo: { id: number; downloadUrl: string } | null;
  reflectionsData: ReflectionDetails[];
  selectedFrequencyBandIndex: number | null;
  selectedScale: string;
  selectedTimeOfArrivalGroupIndexes: number[];
  selectedReflectionIndex: number | null;
  // Derived state
  filteredReflectionsData: ReflectionDetails[];
};

const initialState: State = {
  reflectionsDataDownloadInfo: null,
  reflectionsData: [],
  selectedFrequencyBandIndex: null,
  selectedScale: 'music',
  selectedTimeOfArrivalGroupIndexes: [0, 1, 2, 3, 4],
  selectedReflectionIndex: null,
  // Derived state
  filteredReflectionsData: [],
};

const ReflectogramResultsContext = createContext<
  { state: State; dispatch: React.Dispatch<ReflectogramResultsContextAction> } | undefined
>(undefined);

const reflectogramResultsReducer = (state: State, action: ReflectogramResultsContextAction): State => {
  switch (action.type) {
    case ActionType.RESET_STATE:
      return initialState;

    case ActionType.RESET_SELECTED_FILTERS:
      return {
        ...state,
        selectedFrequencyBandIndex: initialState.selectedFrequencyBandIndex,
        selectedScale: initialState.selectedScale,
        selectedTimeOfArrivalGroupIndexes: initialState.selectedTimeOfArrivalGroupIndexes,
        selectedReflectionIndex: initialState.selectedReflectionIndex,
      };

    case ActionType.SET_REFLECTIONS_DOWNLOAD_INFO:
      return {
        ...state,
        reflectionsDataDownloadInfo: action.downloadInfo,
      };

    case ActionType.SET_REFLECTIONS_DATA:
      return {
        ...state,
        reflectionsData: action.reflectionsData,
      };

    case ActionType.SET_SELECTED_REFLECTION_INDEX:
      return {
        ...state,
        selectedReflectionIndex: action.reflectionIndex,
      };

    case ActionType.SET_SELECTED_FREQUENCY_BAND_INDEX:
      return {
        ...state,
        selectedFrequencyBandIndex: action.frequencyBandIndex,
      };

    case ActionType.SET_SELECTED_SCALE:
      return {
        ...state,
        selectedScale: action.scale,
      };

    case ActionType.SET_SELECTED_TIME_OF_ARRIVAL_GROUP_INDEXES:
      return {
        ...state,
        selectedTimeOfArrivalGroupIndexes: action.indexes,
        selectedReflectionIndex: null,
      };

    default:
      return state;
  }
};

const ReflectogramResultsProvider = ({ children }: ReflectogramResultsProviderProps) => {
  const [state, dispatch] = useReducer(reflectogramResultsReducer, initialState);
  const { selectedComparisonIndex, availableComparisons } = useResultsContext();

  const firstReceiverObject = useMemo(
    () => availableComparisons[selectedComparisonIndex]?.formState?.simulationData?.selectedReceiverObjects?.[0],
    [availableComparisons, selectedComparisonIndex]
  );

  useEffect(() => {
    /** Reset the selected reflection if we are changing a source / receiver or selecting a different comparison */
    dispatch({ type: ActionType.SET_SELECTED_REFLECTION_INDEX, reflectionIndex: null });

    if (firstReceiverObject) {
      const reflectionResults = firstReceiverObject.receiverResults?.filter(
        (receiver) => receiver.resultType === 'reflection'
      );

      if (reflectionResults && reflectionResults.length > 0) {
        dispatch({
          type: ActionType.SET_REFLECTIONS_DOWNLOAD_INFO,
          downloadInfo: {
            id: reflectionResults[0].uploadId,
            downloadUrl: reflectionResults[0].uploadUrl,
          },
        });
      } else {
        dispatch({
          type: ActionType.SET_REFLECTIONS_DOWNLOAD_INFO,
          downloadInfo: null,
        });
        dispatch({
          type: ActionType.SET_REFLECTIONS_DATA,
          reflectionsData: [],
        });
      }
    }
  }, [firstReceiverObject]);

  const filteredReflectionsData = useMemo(() => {
    if (state.reflectionsData.length > 0 && state.selectedScale) {
      const scale = TIME_OF_ARRIVAL_SCALES.find((scale) => scale.id === state.selectedScale);
      if (scale && state.selectedTimeOfArrivalGroupIndexes.length < scale.timeOfArrivalGroups.length) {
        return state.reflectionsData.filter((reflection) =>
          state.selectedTimeOfArrivalGroupIndexes.some((index) => {
            const { range } = scale.timeOfArrivalGroups[index];
            return range[0] <= reflection.timeOfArrivalRelative && reflection.timeOfArrivalRelative < range[1];
          })
        );
      }
    }
    return state.reflectionsData;
  }, [state.reflectionsData, state.selectedScale, state.selectedTimeOfArrivalGroupIndexes]);

  const contextValue = useMemo(
    () => ({ state: { ...state, filteredReflectionsData }, dispatch }),
    [state, filteredReflectionsData]
  );

  return <ReflectogramResultsContext.Provider value={contextValue}>{children}</ReflectogramResultsContext.Provider>;
};
// Custom hook to easily access the state and dispatch actions
const useReflectogramResultsContext = () => {
  const context = useContext(ReflectogramResultsContext);
  if (!context) {
    throw new Error('useReflectogramResultsContext must be used within a ReflectogramResultsProvider');
  }
  return context;
};

export { ActionType, ReflectogramResultsProvider, useReflectogramResultsContext };
