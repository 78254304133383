import { Dispatch, useEffect, useState } from 'react';

import { useModelContext } from '@/context/ModelContext';

import { Stack } from '@mui/material';

import { SelectOptionWithGrouping, TrblSelect } from '@/components/Shared/TrblSelect';
import { TrblSkeleton } from '@/components/Shared/TrblSkeleton/TrblSkeleton';
import { ActionType, useAuralizerContext } from '../AuralizerContext';
import { ActionType as PreActionType, usePresetContext } from '../PresetContext';

import { useConnectIRS } from '../hooks/useConnectIRS';
import { useSelectSimulationAsAurSim } from '../hooks/useSelectSimulationAsAurSim';
import { useGetModelInformation, useLoadAndExtractFileFromUrl } from '@/hooks';

import { Simulation } from '@/types';

import '../style.scss';

export const SimulationPicker = ({
  originalSim,
  menuItems,
  selectedSimId,
  setSelectedSimId,
  taskType,
}: {
  originalSim: Simulation;
  menuItems: SelectOptionWithGrouping[];
  selectedSimId: string;
  setSelectedSimId: Dispatch<string>;
  taskType: string | undefined;
}) => {
  const { selectedAurSim, simsToCompare, availableSimsToCompare, simSrcRecHash, audioNodesDict, dispatch } =
    useAuralizerContext();
  const { dispatch: preDispatch } = usePresetContext();

  const { models3d, addModelFromFile } = useModelContext();
  const [newModelId, setNewModelId] = useState<string | null>(null);
  const [availableSimulations, setAvailableSimulations] = useState<Simulation[]>([]);

  const { data: modelInformationData = null } = useGetModelInformation(newModelId);
  const { data: modelFile } = useLoadAndExtractFileFromUrl(modelInformationData?.modelUrl || null, newModelId);
  const selectSimulationAsAurSim = useSelectSimulationAsAurSim();

  const connectIRS = useConnectIRS();

  useEffect(() => {
    if (availableSimsToCompare.length > 0) {
      const availableSimulations = availableSimsToCompare.flatMap((simsByModels) => simsByModels.simulations);
      setAvailableSimulations(availableSimulations);
    }
  }, [availableSimsToCompare]);

  useEffect(() => {
    if (selectedSimId && selectedSimId === selectedAurSim?.id) {
      const newSimulation = availableSimulations.find((sim) => sim.id === selectedSimId);

      if (newSimulation && !models3d[newSimulation.modelId]) {
        setNewModelId(newSimulation.modelId);
      }
    }
  }, [selectedSimId, selectedAurSim]);

  useEffect(() => {
    if (modelFile && newModelId) {
      addModelFromFile(newModelId, modelFile);
    }
  }, [modelFile, newModelId]);

  const selectSimToCompare = async (newValue: string) => {
    const newSimulation = availableSimulations.find((sim) => sim.id === newValue);
    if (newSimulation && originalSim && audioNodesDict) {
      // connect new IRS but don't change the reverb - we want the files to load asap
      if (audioNodesDict[newSimulation.id] !== undefined) {
        // do nothing - this simulation has already been connected earlier
        dispatch({
          type: ActionType.SET_FETCHING,
          simId: newSimulation.id,
          isFetching: false,
        });
      } else if (simSrcRecHash) {
        connectIRS(newSimulation, originalSim, simSrcRecHash);
      }
    }

    let newSimulationsToCompare: Simulation[] = [];
    if (selectedSimId !== '' && newSimulation) {
      // if the user is replacing a simulation from the picker
      newSimulationsToCompare = [
        ...simsToCompare.filter((simulation) => simulation.id !== selectedSimId),
        newSimulation,
      ];

      // if the old value is the same as the selected auralization simulation
      if (selectedAurSim && selectedSimId === selectedAurSim.id) {
        selectSimulationAsAurSim(newSimulation.id);
      }
    } else if (newSimulation) {
      // if it is a new picker value
      newSimulationsToCompare = [...simsToCompare, newSimulation];
    }

    dispatch({
      type: ActionType.SET_SIMS_TO_COMPARE,
      simulations: newSimulationsToCompare,
    });
    preDispatch({
      type: PreActionType.SET_PRESET_EDITED,
      presetEdited: true,
    });

    setSelectedSimId(newValue);
  };

  return (
    <>
      {menuItems.length > 0 ? (
        <Stack flexDirection="row" alignItems="center" gap="8px">
          <TrblSelect
            placeholder="Select simulation"
            value={selectedSimId}
            setValue={selectSimToCompare}
            menuItems={menuItems}
            hasCategories={true}
          />
          {taskType === 'GA' && <span className="task-type-label">GA</span>}
        </Stack>
      ) : (
        <TrblSkeleton height={32} />
      )}
    </>
  );
};
