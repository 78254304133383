import { useQuery } from '@tanstack/react-query';

import { RecUrl } from '../types';
import { SourceResults, TaskResultForReceiver } from '@/types';

import axios from '@/axios';

export const getURLsFromSimID = async (simulationId: string, type: string): Promise<RecUrl> => {
  const { data } = await axios.get(
    `/api/SolveTask/GetLastSolveResultsBySimulationId?simulationId=${simulationId}&taskTypesCsv=${type}&resultTypesCsv=spatial-ir`
  );
  const sourceResults: SourceResults[] = data.sourceResults;
  const result: RecUrl = {};
  sourceResults?.forEach((sourceResult: SourceResults) => {
    sourceResult.taskResultForReceiver.forEach((recentTaskResult: TaskResultForReceiver) => {
      const receiverId = recentTaskResult.pointId;
      const resultUrl = recentTaskResult.receiverResults?.[0].uploadUrl;
      const normalizationFactor = recentTaskResult.spatialIRNormalization ? recentTaskResult.spatialIRNormalization : 1;
      result[sourceResult.sourcePointId] = result[sourceResult.sourcePointId] || {};
      result[sourceResult.sourcePointId][receiverId] = result[sourceResult.sourcePointId][receiverId] || {};
      result[sourceResult.sourcePointId][receiverId].url = resultUrl ?? '';
      result[sourceResult.sourcePointId][receiverId].normalizationFactor = normalizationFactor;
    });
  });

  return result;
};

export const useGetURLsFromSimID = (simulationId: string, type: string) => {
  return useQuery<RecUrl, boolean>(
    ['urls-by-simulation-id', simulationId],
    () => getURLsFromSimID(simulationId, type),
    {
      enabled: !!simulationId && !!type,
      refetchOnWindowFocus: false,
      cacheTime: Infinity,
    }
  );
};
