import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { GizmoHelper, Html } from '@react-three/drei';
import { Size, useThree } from '@react-three/fiber';
import { CanvasTexture, SpriteMaterial } from 'three';

import { Text } from '@/components/Shared/Text';
import { getYTitle } from '@/components/Results/components/ParameterPlot/utils';

import { useExportCanvas } from '../hooks/useExportCanvas';

import { roundFloat } from '@/utils/trebleFunctions';

import styles from './styles.module.scss';

type ColorScaleProps = {
  isExporting: boolean;
  setIsExporting: React.Dispatch<React.SetStateAction<boolean>>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  colorBar: any;
  visible: boolean;
  selectedParameter: string;
  selectedFrequency: string;
  selectedNcCurve: string;
  onCustomMinChange: (value: number) => void;
  onCustomMaxChange: (value: number) => void;
  disabled?: boolean;
};

export const ColorScale: React.FC<ColorScaleProps> = ({
  isExporting,
  setIsExporting,
  colorBar,
  visible,
  selectedFrequency,
  selectedParameter,
  selectedNcCurve,
  onCustomMinChange,
  onCustomMaxChange,
  disabled,
}) => {
  const { size, invalidate } = useThree();

  const [scaleRef, setScaleRef] = useState<HTMLDivElement>();
  const spriteRef = useRef<THREE.Sprite>(null);

  // the color bar is not part of the three.js scene object so we need to pass the reference to it
  // separately to then be able to add it to the image that we want to generate and download
  const hasExported = useExportCanvas(isExporting, scaleRef, spriteRef.current);

  // hack to wait for the scaleRef dom to be ready, and then set scaleRef has the ready dom
  const scaleRefReady = useCallback((node: HTMLDivElement) => {
    if (node !== null) setScaleRef(node);
  }, []);

  useEffect(() => {
    if (hasExported) {
      setIsExporting(false);
    }
  }, [hasExported]);

  const material = useMemo(
    () =>
      new SpriteMaterial({
        map: new CanvasTexture(colorBar.createCanvas()),
      }),
    [colorBar]
  );

  useEffect(() => {
    if (material) {
      material.visible = visible;
    }
  }, [visible]);

  useEffect(() => {
    updateColorScaleSize(size);
  }, [size, scaleRef]);

  const updateColorScaleSize = (size: Size) => {
    if (spriteRef.current && scaleRef) {
      // Size depends on canvas size
      // TODO: Bjarni create variables for magic numbers
      spriteRef.current.scale.set(size.width / 3, 15, 1);
      scaleRef.style.width = size.width / 3 + 'px';
      scaleRef.style.marginLeft = -size.width / 6 + 'px';
      spriteRef.current.material.color.set('white');
      spriteRef.current.material.needsUpdate = true;
      invalidate();
    }
  };

  const scaleValues = useMemo(() => {
    const values: number[] = [];

    const minMaxDiff = colorBar.maxV - colorBar.minV;
    const numberOfTicks = 5;
    const step = minMaxDiff / (numberOfTicks - 1);

    let decimals: number;
    if (step >= 0.1) {
      decimals = 2;
    } else if (step >= 0.01) {
      decimals = 3;
    } else {
      decimals = 4;
    }

    for (let i = 0; i < numberOfTicks; i++) {
      const value = colorBar.minV + step * i;
      values.push(roundFloat(value, decimals));
    }

    return values;
  }, [colorBar.maxV, colorBar.minV]);

  const updateScaleMin: React.FocusEventHandler<HTMLInputElement> = (event) => {
    const value = event.target.value;
    if (value !== '' && Number(value) < colorBar.maxV) {
      if (Number(value) > -150) {
        onCustomMinChange(Number(value));
      } else {
        onCustomMinChange(-150);
      }
    } else {
      event.target.value = colorBar.minV;
    }
  };

  const updateScaleMax: React.FocusEventHandler<HTMLInputElement> = (event) => {
    const value = event.target.value;
    if (value !== '' && Number(value) > colorBar.minV) {
      if (Number(value) < 150) {
        onCustomMaxChange(Number(value));
      } else {
        onCustomMaxChange(150);
      }
    } else {
      event.target.value = colorBar.maxV;
    }
  };

  const handleInputClicked: React.MouseEventHandler<HTMLInputElement> = (event) => {
    const target = event.target as HTMLInputElement;
    target.select();
  };

  const handleKeyPress: React.KeyboardEventHandler<HTMLInputElement> = (event) => {
    if (event.key === 'Enter') {
      event.target.blur();
    }
  };

  return (
    <GizmoHelper autoClear alignment="bottom-left" renderPriority={2} margin={[size.width / 6 + 20, 35]}>
      <Html>
        <div className={styles['color-bar-text-container']} ref={scaleRefReady}>
          <div className={styles['scale-values']}>
            <span key={colorBar.minV}>
              <input
                tabIndex={-1}
                className={`${styles['scale-input']} ${styles['min']}`}
                title={disabled ? '' : 'Set custom min value'}
                onClick={handleInputClicked}
                onKeyDown={handleKeyPress}
                onBlur={updateScaleMin}
                defaultValue={scaleValues[0]}
                type="number"
                disabled={disabled}
                min={-150}
                max={colorBar.maxV}
                step="0.01"></input>
            </span>
            {scaleValues.slice(1, scaleValues.length - 1).map((value, i) => (
              <Text key={`scale_value_${i}`} type="regular-11px" numberFontStyleEnabled>
                {value}
              </Text>
            ))}

            <span key={colorBar.maxV}>
              <input
                tabIndex={-1}
                className={`${styles['scale-input']} ${styles['max']}`}
                title={disabled ? '' : 'Set custom max value'}
                onClick={handleInputClicked}
                onKeyDown={handleKeyPress}
                onBlur={updateScaleMax}
                defaultValue={scaleValues[scaleValues.length - 1]}
                type="number"
                disabled={disabled}
                min={colorBar.minV}
                max={150}
                step="0.01"></input>
            </span>
          </div>
          <div className={styles['inner-text']}>{selectedParameter === 'sti' && <StiResultCategories />}</div>
          <Text type="regular-11px" numberFontStyleEnabled>
            {`${getYTitle(selectedParameter)} ${
              selectedParameter !== 'sti' ? `at ${selectedFrequency} Hz` : `for ${selectedNcCurve}`
            }`}
          </Text>
        </div>
      </Html>
      <sprite ref={spriteRef} material={material}></sprite>
    </GizmoHelper>
  );
};

const StiResultCategories = () => {
  const color = '#171717';
  const flexStyling = { display: 'flex', justifyContent: 'center', alignItems: 'center', background: 'transparent' };

  return (
    <>
      <div style={{ width: '30%', ...flexStyling }}>
        <Text type="medium-11px" color={color}>
          Bad
        </Text>
      </div>
      <div style={{ width: '15%', ...flexStyling }}>
        <Text type="medium-11px" color={color}>
          Poor
        </Text>
      </div>
      <div style={{ width: '15%', ...flexStyling }}>
        <Text type="medium-11px" color={color}>
          Fair
        </Text>
      </div>
      <div style={{ width: '15%', ...flexStyling }}>
        <Text type="medium-11px" color={color}>
          Good
        </Text>
      </div>
      <div style={{ width: '25%', ...flexStyling }}>
        <Text type="medium-11px" color={color}>
          Excellent
        </Text>
      </div>
    </>
  );
};
