import { getConvolverNodeFromURLObject } from './getConvolverNodeFromURLObject';

import { AudioObject, RecUrl, SimSrcRecHash } from '../types';
import { Simulation } from '@/types';

export const createConvolverNodesConcurrent = async (
  urls: RecUrl,
  simId: string,
  originalSim: Simulation,
  simSrcRecHash: SimSrcRecHash,
  pair: { rId: string; sId: string }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<any> => {
  const srcHash = getSourceHashById(simId, pair.sId, simSrcRecHash);
  const recHash = getRecHashById(simId, pair.rId, simSrcRecHash);

  if (srcHash && recHash) {
    const originalSourceId = simSrcRecHash[originalSim.id]['srcHmap'][srcHash];
    const originalReceiverId = simSrcRecHash[originalSim.id]['recHmap'][recHash];

    const newAudioObject: AudioObject = await getConvolverNodeFromURLObject(
      urls,
      pair.sId,
      pair.rId,
      simId,
      originalSourceId
    );

    return {
      sourceId: originalSourceId,
      receiverId: originalReceiverId,
      convolverNodeList: newAudioObject.convolverArray,
      inputNodeList: newAudioObject.inputGain,
      mergerNodeList: newAudioObject.merger,
      convolverIds: originalReceiverId,
    };
  }
};

const getSourceHashById = (simId: string, sourceId: string, simSrcRecHash: SimSrcRecHash) => {
  try {
    const map = simSrcRecHash[simId]?.srcHashes;
    return Object.keys(map).find((key) => map[key].id === sourceId);
  } catch (error) {
    console.error(error);
    return null;
  }
};

const getRecHashById = (simId: string, receiverId: string, simSrcRecHash: SimSrcRecHash) => {
  try {
    const map = simSrcRecHash[simId]?.recHashes;
    return Object.keys(map).find((key) => map[key].id === receiverId);
  } catch (error) {
    console.error(error);
    return null;
  }
};
