import { useEffect, useState } from 'react';

import { PrimaryButton } from '@/components/Shared/Buttons';

import { useFitMaterial, useGetFitMaterial } from '@/hooks';

import { SimpleData } from '../types';
import { FitMaterial } from '@/types';

export const FitMaterialButton = ({
  targetCoefficients,
  setMaterialFit,
  formDisabled,
  setFormDisabled,
  disabled,
}: {
  targetCoefficients: SimpleData;
  setMaterialFit: React.Dispatch<React.SetStateAction<FitMaterial | null>>;
  formDisabled: boolean | undefined;
  setFormDisabled: React.Dispatch<React.SetStateAction<boolean | undefined>>;
  disabled: boolean;
}) => {
  const { mutate: fitMaterial, isLoading: isStartingMaterialFit } = useFitMaterial();
  const [ongoingMaterialFitId, setOngoingMaterialFitId] = useState<string | null>(null);

  const { data } = useGetFitMaterial(ongoingMaterialFitId, true);

  useEffect(() => {
    if (isStartingMaterialFit) {
      setFormDisabled(isStartingMaterialFit);
    }
  }, [isStartingMaterialFit]);

  const handleFitMaterial = () => {
    // @ts-expect-error targetCoefficients can not have undefined value here
    const inputTarget: number[] = Object.values(targetCoefficients);
    fitMaterial(inputTarget, {
      onSuccess: (fitResponse) => {
        if (fitResponse?.material_fit_id) {
          setOngoingMaterialFitId(fitResponse.material_fit_id);
        }
      },
      onError: () => {
        setFormDisabled(false);
      },
    });
  };

  useEffect(() => {
    if (ongoingMaterialFitId && data?.status === 40) {
      setOngoingMaterialFitId(null);
      setMaterialFit(data);
      setFormDisabled(false);
    }
  }, [ongoingMaterialFitId, data?.status]);

  return (
    <PrimaryButton
      width="fit-content"
      label="Start material fit"
      disabled={formDisabled || disabled}
      onClick={handleFitMaterial}
    />
  );
};
