import { useEffect } from 'react';

import { ActionType, useAppContext } from '@/context/AppContext';

import { TrblTooltip } from '@/components/Shared';
import { PrimaryButton } from '@/components/Shared/Buttons';

import { useCreateMaterial } from '@/hooks';

import { FitMaterial, MaterialDto } from '@/types';

export const CreateMaterialButton = ({
  disabled,
  formValues,
  shouldShare,
  setFormDisabled,
  setShowPopup,
  materialFit,
  fittedData,
}: {
  disabled: boolean;
  formValues: { name: string; category: string | null; description: string; defaultScattering: number };
  shouldShare: boolean;
  setFormDisabled: React.Dispatch<React.SetStateAction<boolean | undefined>>;
  setShowPopup: React.Dispatch<React.SetStateAction<boolean | undefined>>;
  materialFit?: FitMaterial | null;
  fittedData?: MaterialDto;
}) => {
  const {
    dispatch,
    appState: { filteredMaterials },
  } = useAppContext();

  const { mutate: createMaterial, isLoading: isCreatingMaterial } = useCreateMaterial();

  useEffect(() => {
    setFormDisabled(isCreatingMaterial);
  }, [isCreatingMaterial]);

  const handleCreateMaterial = () => {
    if ((materialFit?.material_json && materialFit.material_metadata_json) || fittedData !== undefined) {
      const materialProps =
        materialFit?.material_json && materialFit.material_metadata_json
          ? {
              materialJson: materialFit.material_json,
              materialMetaDataJson: materialFit.material_metadata_json,
              materialFitId: materialFit.material_fit_id,
            }
          : {
              materialJson: fittedData!.materialJson,
              materialMetaDataJson: fittedData!.materialMetadataJson,
              materialFitId: fittedData!.id,
            };

      createMaterial(
        {
          ...formValues,
          description: formValues.description.trim() === '' ? 'none' : formValues.description,
          isSharedWithOrganization: shouldShare,
          ...materialProps,
        },
        {
          onSuccess: (data) => {
            const newMaterials = [...filteredMaterials, data];
            dispatch({
              type: ActionType.SET_MATERIALS,
              payload: newMaterials,
            });
            setShowPopup(false);
          },
          onError: () => {
            setFormDisabled(false);
          },
        }
      );
    }
  };

  return (
    <TrblTooltip title={disabled ? 'Name and category are required' : ''}>
      <span>
        <PrimaryButton disabled={disabled} width="fit-content" label="Create material" onClick={handleCreateMaterial} />
      </span>
    </TrblTooltip>
  );
};
