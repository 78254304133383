import { Object3D, Raycaster, Vector3 } from 'three';

import { EMPTY_GUID } from '@/components/Shared/constants';

import { POINT_OFFSET_X, POINT_Z_HEIGHT } from './constants';

import { roundFloat } from '@/utils/trebleFunctions';

import { Receiver, Source } from '@/context/EditorContext/types';
import { GridReceiver, Receiver as SimReceiver, Source as SimSource, SourceParameter } from '@/types';

export const newPointPosition = (
  points: Source[] | Receiver[],
  modelCenter: Vector3 | null,
  modelBottom: Vector3 | null,
  defaultPosition: Vector3,
  offsetY: number = 0
) => {
  let x, y, z;

  // if there's already points created, then set the position same as the last point + 0.5m on the x axis
  if (points.length > 0) {
    x = points[points.length - 1].x! + POINT_OFFSET_X;
    y = points[points.length - 1].y!;
    z = points[points.length - 1].z!;

    // else if it's the first point created, then set the position in the center of the model + offset on the y axis
    // and the z on the bottom of the model + default point z height (1.5m)
  } else if (modelCenter && modelBottom) {
    x = modelCenter.x;
    y = modelCenter.y + offsetY;
    z = modelBottom.z + POINT_Z_HEIGHT;

    // else if no points and modelCenter was found, then fall back on the default positions
  } else {
    x = defaultPosition.x;
    y = defaultPosition.y;
    z = defaultPosition.z;
  }

  // return the x y z variables, rounded to 2 decimals
  return [roundFloat(x, 2), roundFloat(y, 2), roundFloat(z, 2)];
};

export const mapToSources = (sources: SimSource[], sourceParameters: SourceParameter[]): Source[] => {
  const newSources: Source[] = [];
  if (sources) {
    sources
      .sort((a, b) => a.orderNumber - b.orderNumber)
      .forEach((s) => {
        const params = sourceParameters?.find((p) => p.sourceId === s.id);

        newSources.push({
          id: s.id,
          label: s.label,
          x: s.x,
          y: s.y,
          z: s.z,
          isValid: true,
          params: params
            ? {
                directivityPattern: params.directivityPattern,
                azimuth: params.azimuth || 0,
                elevation: params.elevation || 0,
                rotation: params.rotation || 0,
                eq: params.eq || null,
                overallGain: params.overallGain || 0,
                correctIrByOnAxisSpl: params.correctIrByOnAxisSpl || false,
                useSplForSti: params.useSplForSti || false,
              }
            : {
                directivityPattern: EMPTY_GUID,
                azimuth: 0,
                elevation: 0,
                rotation: 0,
                eq: null,
                overallGain: null,
                correctIrByOnAxisSpl: false,
                useSplForSti: false,
              },
        });
      });
  }

  return newSources;
};

export const mapToReceivers = (receivers: SimReceiver[]): Receiver[] => {
  const newReceivers: Receiver[] = [];

  if (receivers) {
    receivers
      .sort((a, b) => a.orderNumber - b.orderNumber)
      .forEach((r) => {
        newReceivers.push({
          id: r.id,
          label: r.label,
          x: r.x,
          y: r.y,
          z: r.z,
          isValid: true,
        });
      });
  }

  return newReceivers;
};

export const mapToGridReceivers = (gridReceivers: GridReceiver[] | null): GridReceiver[] => {
  if (gridReceivers) {
    const newGridReceivers: GridReceiver[] = [...gridReceivers];

    return newGridReceivers.sort((a: GridReceiver, b: GridReceiver) => {
      if (a.orderNumber && b.orderNumber) {
        return a.orderNumber - b.orderNumber;
      } else {
        return 0;
      }
    });
  } else {
    return [];
  }
};

/** Point validation */

// setting vector in one direction, that is re-used for the point inside model and internal Volume check
// this needs to be in a fixed random direction that is very unlikely to be paralalle to a surface (hence the weird number)
const rayVector = new Vector3(0.9667941475489408, 0.24977617986410142, 0.05404568657731098);

export const isPointInsideModel = (point: Vector3, outerMeshes: Object3D[]) => {
  const raycaster = new Raycaster(point, rayVector);

  const intersects = raycaster.intersectObjects(outerMeshes, false);

  const result = intersects.length % 2 == 0 ? false : true;

  return result;
};

export const isPointInsideInternalVolume = (point: Vector3, innerMeshes: Object3D[]) => {
  const raycaster = new Raycaster(point, rayVector);

  const intersects = raycaster.intersectObjects(innerMeshes, false);

  const result = intersects.length % 2 == 0 ? false : true;

  return result;
};

/** Initialize 480 rays evenly in a sphere for proximity check */
let theta, phi;
const nSample = 480;
const sphericalRaysDirections: Vector3[] = [];

for (let i = 0; i < nSample; i++) {
  phi = Math.acos(-1 + (2 * i) / nSample);
  theta = Math.sqrt(nSample * Math.PI) * phi;
  const v = new Vector3().setFromSphericalCoords(1, phi, theta).normalize();
  sphericalRaysDirections.push(v);
}
// we set a ray that is re-used for the validiy, for speed-up
const ray = new Raycaster(new Vector3(0, 0, 0), new Vector3(0, 0, 0), 0.0, 0);

export const isPointInValidProximity = (point: Vector3, margin: number, meshes: Object3D[]) => {
  ray.far = margin;
  for (let i = 0; i < sphericalRaysDirections.length; i++) {
    ray.set(point, sphericalRaysDirections[i]);

    const intersects = ray.intersectObjects(meshes, false);

    if (intersects.length > 0) return false;
  }

  return true;
};

const sourceReceiverMargin = 0.51;
export const isPointCloseToSource = (point: Vector3, sources: Source[]) => {
  for (const source of sources) {
    if (source.x == null || source.y == null || source.z == null) {
      return false;
    }
    const distance = Math.sqrt(
      Math.pow(Math.abs(source.x - point.x), 2) +
        Math.pow(Math.abs(source.y - point.y), 2) +
        Math.pow(Math.abs(source.z - point.z), 2)
    );
    if (distance < sourceReceiverMargin) {
      return true;
    }
  }
  return false;
};

const directionsMediumSimple = [
  new Vector3(1, 0, 0),
  new Vector3(0, 1, 0),
  new Vector3(0, 0, 1),
  new Vector3(-1, 0, 0),
  new Vector3(0, -1, 0),
  new Vector3(0, 0, -1),
  new Vector3(1, 1, 1).normalize(),
  new Vector3(-1, 1, 1).normalize(),
  new Vector3(1, -1, 1).normalize(),
  new Vector3(-1, -1, 1).normalize(),

  new Vector3(1, 1, -1).normalize(),
  new Vector3(-1, 1, -1).normalize(),
  new Vector3(1, -1, -1).normalize(),
  new Vector3(-1, -1, -1).normalize(),

  new Vector3(1, 1, 0).normalize(),
  new Vector3(-1, 1, 0).normalize(),
  new Vector3(1, -1, 0).normalize(),
  new Vector3(-1, -1, 0).normalize(),
];

const gridReceiverMargin = 0.1;
// we set a ray that is re-used for the validiy, for speed-up
const rayGridReceiver = new Raycaster(new Vector3(0, 0, 0), new Vector3(0, 0, 0), 0.0, gridReceiverMargin);

export const isPointInValidProximityGridReceiver = async (point: Vector3, meshes: Object3D[]) => {
  return new Promise((resolve) => {
    for (let i = 0; i < directionsMediumSimple.length; i++) {
      rayGridReceiver.set(point, directionsMediumSimple[i]);

      const intersects = rayGridReceiver.intersectObjects(meshes, false);

      if (intersects.length > 0) resolve(false);
    }

    resolve(true);
  });
};
