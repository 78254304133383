/* eslint-disable @typescript-eslint/no-explicit-any */
import { toast } from 'react-toastify';
import { datadogRum } from '@datadog/browser-rum';

import { AudioEngine } from '../AudioEngine';
import { int16ToFloat32 } from './int16ToFloat32';
import { spawnWorker } from './spawnWorker';

import { AudioObject, RecUrl } from '../types';

export const getConvolverNodeFromURLObject = (
  URLdict: RecUrl,
  sourceId: string,
  receiverId: string,
  simId: string,
  originalSourceId: string
): Promise<AudioObject> => {
  return new Promise((resolve) => {
    const audioObject: AudioObject = {
      merger: null,
      inputGain: null,
      convolverArray: [],
      channelCount: 9,
      sampleRate: 32000,
    };
    const url = URLdict[sourceId][receiverId].url;
    const audioEngine = AudioEngine.getInstance();
    const audioContext = audioEngine.audioContext;

    audioObject.merger = audioContext.createChannelMerger(audioObject.channelCount);
    audioObject.inputGain = audioContext.createGain();

    // read audioSettings here
    const originalMax = audioEngine.audioSettings.firstSimNormMax?.relMax;
    const numberFactor = audioEngine.audioSettings[simId][originalSourceId].normFactor;
    const ratio = numberFactor / originalMax;

    if (isFinite(ratio) && ratio !== null) {
      const originalGain = 1;
      const newGain = ratio * originalGain;
      audioObject.inputGain.gain.value = newGain;
    } else {
      // We need to figure out why some simulations don't have a numberFactor
      // and this is also an experiment to see how custom errors work in DD :)
      const error = new Error(`NumberFactor is infinite for sim ${simId} and source ${sourceId}`);
      datadogRum.addError(error);
    }

    audioObject.inputGain.channelCount = 1;

    audioObject.convolverArray = [];

    fetch(url)
      .then((response) => response.arrayBuffer())
      .then((arrayBuffer: ArrayBuffer) => {
        spawnWorker(arrayBuffer)
          .then((results: any) => results[0])
          .then((res) => {
            res.buffers.forEach((buffer: Buffer, index: number) => {
              let floatBuffer = null;

              const dataType: number = res.datatype;

              if (dataType === 1) {
                floatBuffer = int16ToFloat32(buffer, 0, buffer.length);
              } else if (dataType === 3) {
                floatBuffer = buffer;
              }

              const convolver = audioContext.createConvolver();
              convolver.normalize = false;
              convolver.channelCount = 1;

              if (floatBuffer) {
                const audioBuffer = audioContext.createBuffer(1, floatBuffer?.length, audioObject.sampleRate);
                audioBuffer.getChannelData(0).set(floatBuffer);
                convolver.buffer = audioBuffer;
              }

              audioObject.convolverArray[index] = convolver;

              if (audioObject.inputGain && audioObject.merger) {
                // connect convolver channel to input node
                audioObject.inputGain.connect(audioObject.convolverArray[index]);

                // connect convolver channel to merger
                audioObject.convolverArray[index].connect(audioObject.merger, 0, index);
              }
            });

            resolve(audioObject);
          })
          .catch(() => {
            toast.error('Not able to read file');
          });
      });
  });
};
