import { FC, useEffect, useRef, useState } from 'react';
import { useFrame } from '@react-three/fiber';
import { ArrowHelper, Color, DynamicDrawUsage, Euler, MathUtils, Texture, TextureLoader, Vector3 } from 'three';

import { useResultsContext } from '@/components/Results/context/ResultsContext';
import { ActionType, useEditorContext } from '@/context/EditorContext';

import { EMPTY_GUID, ZEROS_ONE_GUID } from '@/components/Shared/constants';
/** Images */
import roundImageUrl from './images/round.png';
import roundSelectedImageUrl from './images/round_selected.png';

import { useObjectClickHandler } from '../../hooks';
import { useCreatePointLabel } from '../../hooks/useCreateLabel';

import { ResultsView, SourceParams, ValidationError } from '@/context/EditorContext/types';

const pointTexture = new TextureLoader().load(roundImageUrl);
const pointSelectedTexture = new TextureLoader().load(roundSelectedImageUrl);
const arrowColor = new Color('rgb(5,5,5)');
const arrowLengthScale = 0.075;

type ArrowProps = {
  azimuth: number;
  elevation: number;
};
const Arrow: FC<ArrowProps> = ({ azimuth, elevation }) => {
  const arrowHelperRef = useRef<ArrowHelper>(null!);
  const [rotation, setRotation] = useState(new Euler());

  useEffect(() => {
    const aroundZ = MathUtils.degToRad(azimuth - 90);
    const aroundX = MathUtils.degToRad(elevation);
    const eulerRot = new Euler(aroundX, 0, aroundZ, 'ZXY');
    setRotation(eulerRot);
  }, [azimuth, elevation]);

  useFrame(({ camera }) => {
    const currentDistance = camera.position.distanceTo(arrowHelperRef.current.position);
    const arrowLength = currentDistance * arrowLengthScale;
    arrowHelperRef.current.setLength(arrowLength, undefined, arrowLength * 0.1);
    arrowHelperRef.current.setRotationFromEuler(rotation);
  });

  return <arrowHelper ref={arrowHelperRef} args={[new Vector3(0, 1, 0), undefined, 0, arrowColor]} />;
};

type PointProps = {
  id: string;
  index: number;
  type: 'SourcePoint' | 'ReceiverPoint';
  x: number;
  y: number;
  z: number;
  defaultSize?: number;
  defaultTexture?: Texture;
  params?: SourceParams;
  isSelected?: boolean;
  inEditor?: boolean;
  validationError?: ValidationError;
  onSelect?: (id: string, type: 'SourcePoint' | 'ReceiverPoint') => void;
};
export const Point: FC<PointProps> = ({
  id,
  index,
  type,
  x,
  y,
  z,
  defaultSize = 12,
  defaultTexture = pointTexture,
  params,
  isSelected = false,
  inEditor = false,
  validationError,
  onSelect,
}) => {
  const handleClick = onSelect ? () => onSelect(id, type) : undefined;
  const clickHandlerProps = useObjectClickHandler(handleClick, onSelect && !isSelected && inEditor);

  const pointLabel = useCreatePointLabel(
    (index + 1).toString(),
    type,
    validationError,
    defaultSize > 12 ? 17 : undefined,
    defaultSize > 12 ? -1.7 : undefined
  );

  return (
    <points
      uuid={id}
      name={type}
      position={new Vector3(x, y, z)}
      {...clickHandlerProps}
      renderOrder={!isSelected ? 0 : !inEditor ? 10 : 0}>
      <primitive object={pointLabel} renderOrder={!isSelected ? 0 : !inEditor ? 10 : 0}></primitive>

      <bufferGeometry attach="geometry">
        <bufferAttribute
          attach="attributes-position"
          count={1}
          array={new Float32Array([0, 0, 0])}
          itemSize={3}
          usage={DynamicDrawUsage}
        />
      </bufferGeometry>
      <pointsMaterial
        attach="material"
        map={!isSelected ? defaultTexture : pointSelectedTexture}
        color={validationError ? '#f84400' : type === 'SourcePoint' ? '#65f6b0' : '#00A3FE'}
        size={!isSelected ? defaultSize : 17.5}
        sizeAttenuation={false}
        alphaTest={0.2}
        transparent={true}
      />
      {validationError === ValidationError.CloseToSurface && (
        <mesh type="Sphere">
          <sphereGeometry
            args={[
              type === 'SourcePoint' ? 0.5 : 0.1,
              type === 'SourcePoint' ? 16 : 12,
              type === 'SourcePoint' ? 16 : 12,
            ]}
          />
          <meshStandardMaterial color={0xf84400} depthTest={false} opacity={0.3} transparent={true} />
        </mesh>
      )}
      {validationError === ValidationError.CloseToSource && (
        <mesh type="Sphere">
          <sphereGeometry args={[0.5, 12, 12]} />
          <meshStandardMaterial color={0xf84400} depthTest={false} opacity={0.3} transparent={true} />
        </mesh>
      )}
      {isSelected &&
        params &&
        params.directivityPattern !== EMPTY_GUID &&
        params.directivityPattern !== ZEROS_ONE_GUID && <Arrow azimuth={params.azimuth} elevation={params.elevation} />}
    </points>
  );
};

export const Points = () => {
  const { selected, receivers, sources, isInResultsMode, isAuralizerOpen, resultsView, selectedAurSource, dispatch } =
    useEditorContext();

  const { selectedComparisonIndex, availableComparisons } = useResultsContext();

  let sourceSelectedResults: string | undefined, receiverSelectedResults: string[] | undefined;

  if (isInResultsMode && availableComparisons) {
    sourceSelectedResults = availableComparisons[selectedComparisonIndex].formState?.sourcePointId;
    receiverSelectedResults = availableComparisons[selectedComparisonIndex].formState?.receiverPointIds;
  }

  if (isAuralizerOpen && selectedAurSource) {
    sourceSelectedResults = selectedAurSource.source.id;
  }

  const handlePointSelect = (pointId: string, type: 'SourcePoint' | 'ReceiverPoint') => {
    if (selected?.id !== pointId) {
      dispatch({
        type: ActionType.SET_SELECTED,
        selected: {
          type: type,
          id: pointId,
        },
      });
    } else {
      dispatch({ type: ActionType.CLEAR_SELECTED });
    }
  };

  return (
    <>
      {sources.map((s, index) =>
        s.x !== undefined && s.y !== undefined && s.z !== undefined ? (
          <Point
            key={s.id}
            id={s.id}
            index={index}
            type="SourcePoint"
            x={s.x}
            y={s.y}
            z={s.z}
            params={s.params}
            onSelect={handlePointSelect}
            inEditor={!isInResultsMode && !isAuralizerOpen}
            isSelected={
              !isInResultsMode && !isAuralizerOpen
                ? selected?.type === 'SourcePoint' && selected.id === s.id
                : s.id == sourceSelectedResults
            }
            validationError={s.validationError}
          />
        ) : null
      )}
      {!(isInResultsMode && resultsView === ResultsView.ResultsGridReceiversView) &&
        receivers.map((r, index) =>
          r.x !== undefined && r.y !== undefined && r.z !== undefined ? (
            <Point
              key={r.id}
              id={r.id}
              index={index}
              type="ReceiverPoint"
              x={r.x}
              y={r.y}
              z={r.z}
              onSelect={handlePointSelect}
              inEditor={!isInResultsMode && !isAuralizerOpen}
              isSelected={
                !isInResultsMode
                  ? selected?.type === 'ReceiverPoint' && selected.id === r.id
                  : receiverSelectedResults?.includes(r.id) ?? false
              }
              validationError={r.validationError}
            />
          ) : null
        )}
    </>
  );
};
