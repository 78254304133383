import { useMemo } from 'react';

import { ReflectionDetails } from '@/components/Results/context/ReflectogramResultsContext';

export const useDbAxisRange = (
  data: ReflectionDetails[],
  selectedFrequencyBandIndex: number | null
): [number, number] => {
  return useMemo(() => {
    const splData = selectedFrequencyBandIndex
      ? data.map((d) => d.splPerBand[selectedFrequencyBandIndex])
      : data.map((d) => d.spl);
    const minDb = Math.min(...splData) - 10;
    const maxDb = Math.max(...splData);
    return [minDb, Math.max(maxDb, 0)];
  }, [data, selectedFrequencyBandIndex]);
};
