import { useQuery } from '@tanstack/react-query';

import { SpaceDto } from '@/types';

import axios from '@/axios';

const getSpaceById = async (spaceId: string) => {
  const { data } = await axios.get(`/api/Space/GetSpaceById?spaceId=${spaceId}`, {
    transformResponse: [(data) => data],
  });

  // parseWithBigInt is a way to read the SourceModelUploadId correctly
  // it is saved in the database as an Int that is too big for Json, so that's why we need to to this
  const dataParsed = parseWithBigInt(data, isBigNumber);

  return dataParsed;
};

export const useGetSpaceById = (spaceId: string) =>
  useQuery<SpaceDto, boolean>(['spaceById', spaceId], () => getSpaceById(spaceId), {
    enabled: !!spaceId,
    refetchOnWindowFocus: false,
  });

// parser that turns matching *big numbers* in source JSON string to bigint
const parseWithBigInt = (jsonString: string, bigNumChecker: (bigNum: number) => boolean) =>
  JSON.parse(enquoteBigNumber(jsonString, bigNumChecker), (key, value) =>
    !isNaN(value) && bigNumChecker(value) ? BigInt(value).toString() : value
  );

// function that enquotes *big numbers* into double quotes inside JSON string,
// with function checking for *big numbers* passed as a second parameter for flexibility
const enquoteBigNumber = (jsonString: string, bigNumChecker: (bigNum: number) => boolean) =>
  jsonString.replaceAll(
    // eslint-disable-next-line no-useless-escape
    /([:\s\[,]*)(\d+)([\s,\]]*)/g,
    (matchingSubstr: string, prefix: string, bigNum: number, suffix: string) =>
      bigNumChecker(bigNum) ? `${prefix}"${bigNum}"${suffix}` : matchingSubstr
  );

// function that helps separate *big numbers* from *small* ones
const isBigNumber = (num: number): boolean => !Number.isSafeInteger(+num);
