import { useEffect, useState } from 'react';

import { Stack, useTheme } from '@mui/material';

import { TrblToggle } from '@/components/Shared';
import { SecondaryButton } from '@/components/Shared/Buttons';
import { TrblPopup, TrblPopupActions, TrblPopupContent, TrblPopupTitle } from '@/components/Shared/Popup';
import { TabButtons } from '@/components/Shared/TabButtons';
import { ComplexInput } from './components/ComplexInput';
import { CreateMaterialButton } from './components/CreateMaterialButton';
import { FitMaterialButton } from './components/FitMaterialButton';
import { LambdaMaterialFit } from './components/LambdaMaterialFit';
import { MaterialDetails } from './components/MaterialDetails';
import { SimpleInput } from './components/SimpleInput';
import { useFeatureFlags } from '../FeatureToggles';

import { useSubmitEvent } from '@/hooks';

import { REF_RANGE } from '../MaterialDetailsPopup/constants';
import {
  FORM_INITIAL,
  FREQUENCY_RANGE_THIRD_OCTAVE_NUM,
  INITIAL_VALUES_FULL_OCTAVE,
  PLOT_HEIGHT,
  PLOT_HEIGHT_SMALL,
} from './constants';

import { getMissingKeys } from './utils/getMissingKeys';

import { ComplexData, SimpleData } from './types';
import { FitMaterial, MaterialDto } from '@/types';

enum MaterialInputType {
  FullOctaveAbsorption = 'FullOctaveAbsorption',
  SurfaceImpedance = 'SurfaceImpedance',
  ReflectionCoefficient = 'ReflectionCoefficient',
}

export const CreateMaterial = ({
  setShowPopup,
}: {
  setShowPopup: React.Dispatch<React.SetStateAction<boolean | undefined>>;
}) => {
  const [targetCoefficients, setTargetCoefficients] = useState<SimpleData>(INITIAL_VALUES_FULL_OCTAVE);
  const [reflectionData, setReflectionData] = useState<ComplexData>({});
  const [impedanceData, setImpedanceData] = useState<ComplexData>({});
  const [materialFit, setMaterialFit] = useState<FitMaterial | null>(null);

  const [reflectionFittedData, setReflectionFittedData] = useState<MaterialDto | null>(null);
  const [impedanceFittedData, setImpedanceFittedData] = useState<MaterialDto | null>(null);

  const [formValues, setFormValues] = useState<{
    name: string;
    category: string;
    description: string;
    defaultScattering: number;
  }>(FORM_INITIAL);

  const [isSpecific, setIsSpecific] = useState(false);
  const [isFormValid, setIsFormValid] = useState(true);
  const [shouldReset, setShouldReset] = useState(false);
  const [shouldShare, setShouldShare] = useState(false);
  const [formDisabled, setFormDisabled] = useState<boolean | undefined>(undefined);
  const [materialInputType, setMaterialInputType] = useState<MaterialInputType>(MaterialInputType.FullOctaveAbsorption);

  const [plotHeight, setPlotHeight] = useState(PLOT_HEIGHT);
  const theme = useTheme();

  const { createMaterialInputsFeature } = useFeatureFlags();

  useSubmitEvent(isFormValid, [formValues.name, formValues.description]);

  useEffect(() => {
    if (window.innerHeight < theme.breakpoints.values.heightSmall) {
      setPlotHeight(PLOT_HEIGHT_SMALL);
    }
  }, [theme]);

  // Form validation
  useEffect(() => {
    if (formValues.name && formValues.name.length > 0 && formValues.category) {
      setIsFormValid(true);
    } else {
      setIsFormValid(false);
    }
  }, [formValues.name, formValues.category]);

  const resetForm = () => {
    setShouldReset(true);
    setMaterialFit(null);
    setShouldShare(false);
    // hack to change the reset flag back to false so next time we click reset it works
    setTimeout(() => {
      setShouldReset(false);
    }, 100);
  };

  const editCoefficients = () => {
    setMaterialFit(null);
    setReflectionFittedData(null);
    setImpedanceFittedData(null);
  };

  const changeMaterialType = (value: string) => {
    setMaterialInputType(value as MaterialInputType);
  };

  return (
    <TrblPopup
      width={1000}
      hideBackdrop={false}
      aria-labelledby={'Create new material'}
      sx={{ fontSize: '12px' }}
      open={true}>
      <form>
        <TrblPopupTitle onClose={() => setShowPopup(false)}>{'Create new material'}</TrblPopupTitle>
        <TrblPopupContent>
          <MaterialDetails formValues={formValues} setFormValues={setFormValues} shouldReset={shouldReset} />
          {createMaterialInputsFeature && (
            <Stack margin={'0px 0 16px'}>
              <TabButtons
                options={[
                  {
                    key: MaterialInputType.FullOctaveAbsorption,
                    label: 'Full octave absorption',
                    disabled: formDisabled,
                  },
                  { key: MaterialInputType.SurfaceImpedance, label: 'Surface impedance', disabled: formDisabled },
                  {
                    key: MaterialInputType.ReflectionCoefficient,
                    label: 'Reflection coefficient',
                    disabled: formDisabled,
                  },
                ]}
                selectedValue={materialInputType}
                onChange={changeMaterialType}
                height={30}
              />
            </Stack>
          )}

          <>
            {materialInputType === MaterialInputType.FullOctaveAbsorption && (
              <SimpleInput
                data={targetCoefficients}
                setData={setTargetCoefficients}
                materialFit={materialFit}
                formDisabled={formDisabled}
                shouldReset={shouldReset}
                plotHeight={plotHeight}
              />
            )}
            {materialInputType === MaterialInputType.SurfaceImpedance && (
              <ComplexInput
                data={impedanceData}
                setData={setImpedanceData}
                formDisabled={formDisabled}
                fittedData={impedanceFittedData}
                setFittedData={setImpedanceFittedData}
                label="surface impedance"
                rowLabels={['Real (Z)', 'Imag (Z)']}
                specificRowLabels={['Real (ζ)', 'Imag (ζ)']}
                isSpecific={isSpecific}
                setIsSpecific={setIsSpecific}
                shouldReset={shouldReset}
                plotHeight={plotHeight}
              />
            )}
            {materialInputType === MaterialInputType.ReflectionCoefficient && (
              <ComplexInput
                data={reflectionData}
                setData={setReflectionData}
                formDisabled={formDisabled}
                fittedData={reflectionFittedData}
                setFittedData={setReflectionFittedData}
                range={REF_RANGE}
                label="reflection coefficient"
                rowLabels={['Real (R)', 'Imag (R)']}
                shouldReset={shouldReset}
                plotHeight={plotHeight}
              />
            )}
          </>
        </TrblPopupContent>
        <TrblPopupActions>
          <div
            style={{
              flex: '1 1 auto',
            }}>
            <TrblToggle
              checked={shouldShare}
              ariaLabel="Share material toggle"
              onChangeToggle={() => setShouldShare(!shouldShare)}
              disabled={formDisabled}
            />
            <span style={{ marginLeft: '8px' }}> Share with organization </span>
          </div>

          <div>
            {materialInputType === 'FullOctaveAbsorption' && (
              <div>
                <SecondaryButton
                  style={{ marginRight: '10px' }}
                  width={'fit-content'}
                  label={materialFit !== null ? 'Edit' : 'Reset form'}
                  disabled={formDisabled}
                  onClick={materialFit !== null ? editCoefficients : resetForm}
                />
                {materialFit === null && (
                  <FitMaterialButton
                    disabled={Object.keys(targetCoefficients).length !== 8}
                    targetCoefficients={targetCoefficients}
                    setMaterialFit={setMaterialFit}
                    formDisabled={formDisabled}
                    setFormDisabled={setFormDisabled}
                  />
                )}
                {materialFit !== null && (
                  <CreateMaterialButton
                    disabled={!isFormValid}
                    materialFit={materialFit}
                    formValues={formValues}
                    shouldShare={shouldShare}
                    setFormDisabled={setFormDisabled}
                    setShowPopup={setShowPopup}
                  />
                )}
              </div>
            )}
            {materialInputType === MaterialInputType.SurfaceImpedance && (
              <>
                <SecondaryButton
                  style={{ marginRight: '10px' }}
                  width={'fit-content'}
                  label={impedanceFittedData !== null ? 'Edit' : 'Reset form'}
                  disabled={formDisabled}
                  onClick={impedanceFittedData !== null ? editCoefficients : resetForm}
                />
                {impedanceFittedData === null && (
                  <LambdaMaterialFit
                    data={impedanceData}
                    inputType={materialInputType}
                    disabled={getMissingKeys(impedanceData, FREQUENCY_RANGE_THIRD_OCTAVE_NUM).length > 0}
                    formDisabled={formDisabled}
                    setFormDisabled={setFormDisabled}
                    setFittedData={setImpedanceFittedData}
                    specificImpedance={isSpecific}
                  />
                )}
                {impedanceFittedData !== null && (
                  <CreateMaterialButton
                    disabled={!isFormValid}
                    formValues={formValues}
                    shouldShare={shouldShare}
                    fittedData={impedanceFittedData}
                    setFormDisabled={setFormDisabled}
                    setShowPopup={setShowPopup}
                  />
                )}
              </>
            )}
            {materialInputType === MaterialInputType.ReflectionCoefficient && (
              <>
                <SecondaryButton
                  style={{ marginRight: '10px' }}
                  width={'fit-content'}
                  label={reflectionFittedData !== null ? 'Edit' : 'Reset form'}
                  disabled={formDisabled}
                  onClick={reflectionFittedData !== null ? editCoefficients : resetForm}
                />
                {reflectionFittedData === null && (
                  <LambdaMaterialFit
                    data={reflectionData}
                    inputType={materialInputType}
                    disabled={getMissingKeys(reflectionData, FREQUENCY_RANGE_THIRD_OCTAVE_NUM).length > 0}
                    formDisabled={formDisabled}
                    setFormDisabled={setFormDisabled}
                    setFittedData={setReflectionFittedData}
                  />
                )}
                {reflectionFittedData !== null && (
                  <CreateMaterialButton
                    disabled={!isFormValid}
                    formValues={formValues}
                    shouldShare={shouldShare}
                    fittedData={reflectionFittedData}
                    setFormDisabled={setFormDisabled}
                    setShowPopup={setShowPopup}
                  />
                )}
              </>
            )}
          </div>
        </TrblPopupActions>
      </form>
    </TrblPopup>
  );
};
