import styles from './styles.module.scss';

export const AbsorptionCoefficient = ({
  labels,
  absorptionCoefficients,
}: {
  labels: string[];
  absorptionCoefficients: number[];
}) => {
  return (
    <div className={styles['material-details-table-container']}>
      <table className={styles['material-details-table']}>
        <thead>
          <tr>
            {labels.map((label) => (
              <th key={label}>{label}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          <tr>
            {absorptionCoefficients.map((abs, index: number) => (
              <td key={abs + index}>{abs}</td>
            ))}
          </tr>
        </tbody>
      </table>
    </div>
  );
};
