// The available nc curves with their respective spl values for octave bands ranging from 125 to 4000
export const AVAILABLE_NC_CURVES = [
  { name: 'NC15', value: [36, 29, 22, 17, 14, 12] },
  { name: 'NC20', value: [40, 33, 26, 22, 19, 17] },
  { name: 'NC25', value: [44, 37, 31, 27, 24, 22] },
  { name: 'NC30', value: [48, 41, 35, 31, 29, 28] },
  { name: 'NC35', value: [52, 45, 40, 36, 34, 33] },
  { name: 'NC40', value: [56, 50, 45, 41, 39, 38] },
  { name: 'NC45', value: [60, 54, 49, 46, 44, 43] },
  { name: 'NC50', value: [64, 58, 54, 51, 49, 48] },
  { name: 'NC55', value: [67, 62, 58, 56, 54, 53] },
  { name: 'NC60', value: [71, 67, 63, 61, 59, 58] },
  { name: 'NC65', value: [75, 71, 68, 66, 64, 63] },
  { name: 'NC70', value: [79, 75, 72, 71, 70, 69] },
];

export const OCTAVE_BANDS = [125, 250, 500, 1000, 2000, 4000];

// We calculate the open office parameters if there are at least 2 receivers, although according to the ISO standard we need at least 4
export const MIN_NUMBER_OF_RECEIVERS = 2;

// All of Treble's sound sources have SPL of 94 dB at 1 meter distance
export const LP_OSS_1M = 94;

// Speech spectrum at 1m
export const L_P_S_1M = [49.9, 54.3, 58.0, 52.0, 44.8, 38.8];

// A-Weighting
export const A = [-16.1, -8.6, 3.2, 0.0, 1.2, 1.0];
