import { createContext, ReactNode, useContext, useEffect, useMemo, useReducer } from 'react';

import { useEditorContext } from '@/context/EditorContext';

import { LibrarySound, SORTED_SOUNDS } from './constants/audioSounds';

import { AllSimsAurObject, AudioNodesDict, SimSrcRecHash } from './types';
import { ModelSimulationsDto, Receiver, Simulation } from '@/types';

enum ActionType {
  SET_INITIAL_AUR_SIM = 'SET_INITIAL_AUR_SIM',
  SET_SELECTED_AUR_SIM = 'SET_SELECTED_AUR_SIM',
  SET_SELECTED_RECEIVER = 'SET_SELECTED_RECEIVER',
  SET_INITIAL_SIMS_TO_COMPARE = 'SET_INITIAL_SIMS_TO_COMPARE',
  SET_SIMS_TO_COMPARE = 'SET_SIMS_TO_COMPARE',
  SET_AVAILABLE_SIMS_TO_COMPARE = 'SET_AVAILABLE_SIMS_TO_COMPARE',
  SET_SIM_SRC_REC_HASH = 'SET_SIM_SRC_REC_HASH',
  SET_AUR_SIMS_OBJECT = 'SET_AUR_SIMS_OBJECT',
  SET_FETCHING = 'SET_FETCHING',
  RESET_STATE = 'RESET_STATE',
  SET_PLAYING_AURALIZATION = 'SET_PLAYING_AURALIZATION',
  SET_AMBISONICS = 'SET_AMBISONICS',
  SET_HAS_INITIALIZED = 'SET_HAS_INITIALIZED',
  SET_AVAILABLE_SOUNDS = 'SET_AVAILABLE_SOUNDS',
  SET_AUDIO_NODE_DICT = 'SET_AUDIO_NODE_DICT',
}

type AuralizerContextAction =
  | {
      type: ActionType.SET_INITIAL_AUR_SIM;
      simulation: Simulation | null;
    }
  | {
      type: ActionType.SET_SELECTED_AUR_SIM;
      simulation: Simulation;
    }
  | {
      type: ActionType.SET_SELECTED_RECEIVER;
      receiver: Receiver | null;
      index: number | null;
    }
  | {
      type: ActionType.SET_INITIAL_SIMS_TO_COMPARE;
      simulations: Simulation[];
    }
  | {
      type: ActionType.SET_SIMS_TO_COMPARE;
      simulations: Simulation[];
    }
  | {
      type: ActionType.SET_AVAILABLE_SIMS_TO_COMPARE;
      allSimulations: ModelSimulationsDto[];
    }
  | {
      type: ActionType.SET_SIM_SRC_REC_HASH;
      simSrcRecHash: SimSrcRecHash | null;
    }
  | {
      type: ActionType.SET_AUR_SIMS_OBJECT;
      simsAurObject: AllSimsAurObject;
    }
  | {
      type: ActionType.SET_FETCHING;
      simId: string;
      isFetching: boolean;
    }
  | {
      type: ActionType.RESET_STATE;
    }
  | {
      type: ActionType.SET_PLAYING_AURALIZATION;
      isPlaying: boolean;
    }
  | {
      type: ActionType.SET_HAS_INITIALIZED;
      hasInitialized: boolean;
    }
  | {
      type: ActionType.SET_AVAILABLE_SOUNDS;
      auralizerSounds: LibrarySound[];
    }
  | {
      type: ActionType.SET_AUDIO_NODE_DICT;
      simId: string | null;
      audioNodesDict: AudioNodesDict | null;
    };

type State = {
  selectedAurSim: Simulation | null;
  selectedReceiver: { receiver: Receiver; index: number } | null;
  simsAurObject: AllSimsAurObject;
  simsToCompare: Simulation[];
  simSrcRecHash: SimSrcRecHash | null;
  availableSimsToCompare: ModelSimulationsDto[];
  fetching: {
    [key: string]: { isFetching: boolean };
  } | null;
  error: {
    [key: string]: { message: string };
  };
  isPlayingAuralization: boolean;
  hasInitialized: boolean;
  auralizerSounds: LibrarySound[];
  audioNodesDict: { [sourceId: string]: AudioNodesDict } | null;
  dispatch: (action: AuralizerContextAction) => void;
};

const initialState: State = {
  selectedAurSim: null,
  selectedReceiver: null,
  simsAurObject: {}, // former simsToCompare, object of the sims to compare with some new props related to audio things
  simsToCompare: [], // former simsAuralArray, array of simulations that have been selected to be compared
  availableSimsToCompare: [], // all available simulations from current space that the user can compare
  simSrcRecHash: {}, // object with simulations and their source and receivers hashes
  fetching: null,
  hasInitialized: false,
  error: {},
  isPlayingAuralization: false,
  auralizerSounds: [],
  audioNodesDict: null,
  dispatch: () => {},
};

const AuralizerContext = createContext(initialState);

function handleUnknownAction(action: never): never;
function handleUnknownAction(action: AuralizerContextAction) {
  throw new Error(`Unhandled action type: ${action.type}`);
}

type AuralizerProviderProps = { children: ReactNode };

const auralizerReducer = (state: State, action: AuralizerContextAction): State => {
  switch (action.type) {
    case ActionType.SET_INITIAL_AUR_SIM: {
      return {
        ...state,
        selectedAurSim: action.simulation ? { ...action.simulation } : null,
        simsToCompare: action.simulation ? [action.simulation] : [],
        selectedReceiver: state.selectedReceiver
          ? { receiver: state.selectedReceiver.receiver, index: state.selectedReceiver.index }
          : null,
      };
    }

    case ActionType.SET_SELECTED_AUR_SIM: {
      return {
        ...state,
        selectedAurSim: { ...action.simulation },
        selectedReceiver: state.selectedReceiver
          ? { receiver: state.selectedReceiver.receiver, index: state.selectedReceiver.index }
          : null,
      };
    }

    case ActionType.SET_SELECTED_RECEIVER: {
      return {
        ...state,
        selectedReceiver:
          action.receiver && action.index !== null ? { receiver: action.receiver, index: action.index } : null,
      };
    }

    case ActionType.SET_INITIAL_SIMS_TO_COMPARE:
    case ActionType.SET_SIMS_TO_COMPARE: {
      return {
        ...state,
        simsToCompare: [...action.simulations],
      };
    }

    case ActionType.SET_AVAILABLE_SIMS_TO_COMPARE: {
      return {
        ...state,
        availableSimsToCompare: action.allSimulations,
      };
    }

    case ActionType.SET_SIM_SRC_REC_HASH: {
      return {
        ...state,
        simSrcRecHash: action.simSrcRecHash,
      };
    }

    case ActionType.SET_AUR_SIMS_OBJECT: {
      return {
        ...state,
        simsAurObject: action.simsAurObject,
      };
    }

    // This is used to disable shared receivers for a given simulation that is
    // fetching the IRS files, otherwise the sound won't update correctly
    case ActionType.SET_FETCHING: {
      const newFetching = {
        ...state.fetching,
        [action.simId]: { isFetching: action.isFetching },
      };
      return {
        ...state,
        fetching: newFetching,
      };
    }

    case ActionType.RESET_STATE: {
      return {
        ...initialState,
      };
    }

    case ActionType.SET_PLAYING_AURALIZATION: {
      return {
        ...state,
        isPlayingAuralization: action.isPlaying,
      };
    }

    case ActionType.SET_HAS_INITIALIZED: {
      return {
        ...state,
        hasInitialized: action.hasInitialized,
      };
    }

    case ActionType.SET_AVAILABLE_SOUNDS: {
      return {
        ...state,
        auralizerSounds: action.auralizerSounds,
      };
    }

    case ActionType.SET_AUDIO_NODE_DICT: {
      let newAudioNodeDict = {};
      if (action.simId) {
        newAudioNodeDict = {
          ...state.audioNodesDict,
          [action.simId]: action.audioNodesDict,
        };
      }

      return {
        ...state,
        audioNodesDict: newAudioNodeDict,
      };
    }

    default: {
      handleUnknownAction(action);
    }
  }
};

const AuralizerProvider = ({ children }: AuralizerProviderProps) => {
  const [auralizerState, dispatch] = useReducer(auralizerReducer, initialState);

  const { isAuralizerOpen } = useEditorContext();

  useEffect(() => {
    if (!isAuralizerOpen) {
      dispatch({
        type: ActionType.RESET_STATE,
      });
    }
  }, [isAuralizerOpen]);

  useEffect(() => {
    if (isAuralizerOpen) {
      dispatch({
        type: ActionType.SET_AVAILABLE_SOUNDS,
        auralizerSounds: SORTED_SOUNDS,
      });
    }
  }, [isAuralizerOpen]);

  const value = useMemo(() => {
    return {
      ...auralizerState,
      dispatch,
    };
  }, [auralizerState]);

  return <AuralizerContext.Provider value={value}>{children}</AuralizerContext.Provider>;
};

const useAuralizerContext = () => {
  const context = useContext(AuralizerContext);
  if (context === undefined) {
    throw new Error('useAuralizerContext must be used within AuralizerProvider');
  }
  return context;
};

export { ActionType, AuralizerProvider, useAuralizerContext };
