import { useEffect, useState } from 'react';

import { useBaseContext } from '@/context/BaseContext';
import { useSimulationContext } from '@/context/SimulationContext';

import { SidePanelBottomRow } from '@/components';
import { LoadingSpinner } from '../Shared/LoadingSpinner';
import { EmptySimulations } from './EmptySimulations';
import { SidePanelTabs } from './SidePanelTabs';
import { SidePanelTopRow } from './SidePanelTopRow';

import './styles.scss';

type SimulationSidePanel = {
  modelId?: string;
  isLoading?: boolean;
};

export const SimulationSidePanel = ({ modelId, isLoading }: SimulationSidePanel) => {
  const {
    simulationState: { availableSimulations, selectedSimulation },
  } = useSimulationContext();

  const {
    state: { sidepanelCollapsed },
  } = useBaseContext();

  const [simStatus, setSimStatus] = useState<number | null>(null);
  const hasSimulations = availableSimulations ? availableSimulations.length > 0 : false;

  useEffect(() => {
    setSimStatus(selectedSimulation?.extra.status);
  }, [selectedSimulation?.extra.status]);

  return (
    <div
      className={`simulations container panel ${simStatus === 1 ? 'inactive' : ''} ${!hasSimulations ? 'dark' : ''}`}>
      {availableSimulations && modelId && !isLoading ? (
        hasSimulations ? (
          <>
            <SidePanelTopRow />
            <SidePanelTabs sidepanelCollapsed={sidepanelCollapsed} />
            <SidePanelBottomRow sidepanelCollapsed={sidepanelCollapsed} />
          </>
        ) : (
          <EmptySimulations />
        )
      ) : (
        <LoadingSpinner />
      )}
    </div>
  );
};
