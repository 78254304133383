import { FC } from 'react';
import { Size } from '@react-three/fiber';
import { DynamicDrawUsage, Texture, TextureLoader, Vector3 } from 'three';

import { calculateDistance } from '@/components/Results/components/OpenOfficeParameters/utils';
/** Images */
import roundBigImageUrl from './images/round_big.png';
import roundWithSpeakerImageUrl from './images/round_with_speaker.png';

import { useCreatePointLabel } from '../../hooks/useCreateLabel';

const pointTexture = new TextureLoader().load(roundBigImageUrl);
const pointWithSpeakerTexture = new TextureLoader().load(roundWithSpeakerImageUrl);

type PointProps = {
  id: string;
  index: number;
  type: 'SourcePoint' | 'ReceiverPoint';
  x: number;
  y: number;
  z: number;
  defaultTexture?: Texture;
  originPoint: Vector3;
  canvasSize: Size;
};
export const PointAuralizer: FC<PointProps> = ({
  id,
  index,
  type,
  x,
  y,
  z,
  defaultTexture = pointTexture,
  originPoint,
  canvasSize,
}) => {
  const startSize = 4.4; // arbitrary number that works
  const ballSize = startSize / (canvasSize.height * 0.01); // set size of point
  let farAway = false;
  let closeBy = false;

  // get distance from the camera position to the point
  const dist = calculateDistance([x, y, z], [originPoint.x, originPoint.y, originPoint.z]);

  if (dist > 12) {
    farAway = true;
  } else if (dist < 1 && dist > 0) {
    closeBy = true;
  }

  // properties to set the size and position of the labels
  const textHeight = farAway ? 12 : 120;
  const xOffset = farAway ? -1.75 : closeBy ? -2.5 : -1.75;
  const sizeAttenuation = farAway || closeBy ? false : true;

  const pointLabel = useCreatePointLabel((index + 1).toString(), type, false, textHeight, xOffset, sizeAttenuation);

  return (
    <points uuid={id} name={type} position={new Vector3(x, y, z)} renderOrder={10}>
      <primitive object={pointLabel} renderOrder={10}></primitive>

      <bufferGeometry attach="geometry">
        <bufferAttribute
          attach="attributes-position"
          count={1}
          array={new Float32Array([0, 0, 0])}
          itemSize={3}
          usage={DynamicDrawUsage}
        />
      </bufferGeometry>
      {closeBy || farAway ? (
        // if source is far away or very close, then render point material as sizeAttenuation={false}
        // and set a fixed size, farAway = 20 & closeBy = 300
        <pointsMaterial
          attach="material"
          map={type === 'SourcePoint' ? pointWithSpeakerTexture : defaultTexture}
          color={type === 'SourcePoint' ? '#65f6b0' : '#00A3FE'}
          size={farAway ? 20 : 300}
          sizeAttenuation={false}
          transparent={true}
          opacity={0.85}
          alphaTest={0.2}
        />
      ) : (
        // else then set sizeAttenuation={true} and set custom size depending on window size and distance
        <pointsMaterial
          attach="material"
          map={type === 'SourcePoint' ? pointWithSpeakerTexture : defaultTexture}
          color={type === 'SourcePoint' ? '#65f6b0' : '#00A3FE'}
          size={ballSize}
          sizeAttenuation={true}
          transparent={true}
          opacity={0.85}
        />
      )}
    </points>
  );
};
