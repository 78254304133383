import dayjs from 'dayjs';

import { TrblCancelledIcon, TrblCompletedIcon, TrblErrorIcon, TrblInprogressIcon, TrblWarningIcon } from '../Icons';

import { RunStatus } from '@/types';

import classes from '@/components/RecentSimulations/styles.module.scss';

interface ModelSimulationStatusDisplayProps {
  simulationStatus?: RunStatus;
  created: string;
}

export const ModelSimulationStatusDisplay = ({ simulationStatus, created }: ModelSimulationStatusDisplayProps) => {
  let progressMessage = 'In progress';
  if (simulationStatus === RunStatus.ProcessingResults) progressMessage = 'Processing results';
  else if (simulationStatus === RunStatus.Queued) progressMessage = 'Queued';
  else if (simulationStatus === RunStatus.Created) progressMessage = 'Started';

  const createdText = 'Created ' + dayjs(created).format('MMM DD');

  return (
    <>
      <div className={classes.status}>
        {!simulationStatus && <p className={classes.status_text}>{createdText}</p>}

        {simulationStatus === RunStatus.Completed && (
          <>
            <TrblCompletedIcon />
            <p className={classes.status_text}>Completed · {createdText}</p>
          </>
        )}

        {simulationStatus &&
          [RunStatus.Created, RunStatus.InProgress, RunStatus.Queued, RunStatus.ProcessingResults].includes(
            simulationStatus
          ) && (
            <>
              <TrblInprogressIcon />
              <p className={classes.status_text}>
                {progressMessage} · {createdText}
              </p>
            </>
          )}

        {simulationStatus === RunStatus.Cancelled && (
          <>
            <TrblCancelledIcon />
            <p className={classes.status_text}>Cancelled · {createdText}</p>
          </>
        )}

        {simulationStatus && [RunStatus.Error, RunStatus.TaskError].includes(simulationStatus) && (
          <>
            <TrblErrorIcon />
            <p className={classes.status_text}>Error · {createdText}</p>
          </>
        )}

        {simulationStatus === RunStatus.InsufficientSimulationTime && (
          <>
            <TrblWarningIcon />
            <p className={classes.status_text}>Insufficient tokens · {createdText}</p>
          </>
        )}
      </div>
    </>
  );
};
