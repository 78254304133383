import { GridColDef } from '@mui/x-data-grid';
import dayjs from 'dayjs';

import { SxProps, Theme } from '@mui/material';

import { TrblTooltip } from '@/components/Shared';
import { RowActions } from './RowActions';
import { SwitchCell } from './SwitchCell';

import { UserAccess } from '../types';

import styles from './styles.module.scss';

export const gridStyleOverrides: SxProps<Theme> = {
  '&.MuiDataGrid-root .MuiDataGrid-cell:focus-within': {
    outline: 'none !important',
  },
  '& .MuiDataGrid-columnHeader:last-child .MuiDataGrid-columnSeparator': {
    display: 'none',
  },
};

export const getColumns = (
  currentUserId: string,
  onEditUser: (userId: string) => void,
  onDeleteUser: (userId: string) => void,
  onToggleIsAdmin: (userId: string, isAdmin: boolean) => void,
  onToggleIsEnabled: (userId: string, isEnabled: boolean) => void,
  isSuperUser: boolean,
  hasAvailableSeats: boolean,
  automaticBillingFeature: boolean,
  isUsingSso: boolean
): GridColDef<UserAccess>[] => {
  const columns: GridColDef<UserAccess>[] = [
    {
      field: 'email',
      headerName: 'Email',
      width: 200,
      editable: false,
      headerClassName: styles['data-grid-header'],
    },
    {
      field: 'firstName',
      headerName: 'First name',
      width: 150,
      editable: false,
      headerClassName: styles['data-grid-header'],
    },
    {
      field: 'lastName',
      headerName: 'Last name',
      width: 200,
      editable: false,
      headerClassName: styles['data-grid-header'],
    },
    {
      field: 'createdAt',
      headerName: 'Created at',
      type: 'date',
      width: 150,
      editable: false,
      renderCell: (params) => (params.value ? dayjs(params.value).format('DD/MM/YYYY') : ''),
      headerClassName: styles['data-grid-header'],
    },
    {
      field: 'actions',
      type: 'actions',
      width: 100,
      renderCell: (params) => (
        <RowActions params={params} onEditUser={onEditUser} onDeleteUser={onDeleteUser} isUsingSso={isUsingSso} />
      ),
      headerClassName: styles['data-grid-header'],
    },
  ];

  if (automaticBillingFeature) {
    columns.splice(3, 0, {
      field: 'isEnabled',
      headerName: 'Is enabled?',
      width: 110,
      disableColumnMenu: true,
      renderCell: (params) => (
        <TrblTooltip
          title={
            !params.value && !hasAvailableSeats
              ? 'You can not enable this user - please add more seats'
              : params.value && currentUserId === params.row.id
              ? 'You can not disable your own account'
              : ''
          }>
          <span>
            <SwitchCell
              id={`isEnabled_${params.row.id}`}
              value={params.value}
              // If the user is not enabled and there are no available seats, the switch should be disabled
              // Also you should not be able to disable yourself (the logged in user)
              disabled={(!params.value && !hasAvailableSeats) || (params.value && currentUserId === params.row.id)}
              onToggle={(value) => onToggleIsEnabled(params.row.id, value)}
              confirmationText={
                !params.value
                  ? `Are you sure you want to enable this user?`
                  : `Are you sure you want to disable this user?`
              }
            />
          </span>
        </TrblTooltip>
      ),
      headerClassName: styles['data-grid-header'],
    });
  }

  if (isSuperUser) {
    columns.splice(automaticBillingFeature ? 4 : 3, 0, {
      field: 'isAdmin',
      headerName: 'Is admin?',
      width: 100,
      disableColumnMenu: true,
      renderCell: (params) => (
        <SwitchCell
          id={`isAdmin_${params.row.id}`}
          value={params.value}
          disabled={false}
          onToggle={(value) => onToggleIsAdmin(params.row.id, value)}
          confirmationText={
            !params.value
              ? `Are you sure you want to grant Admin permissions to this user?`
              : `Are you sure you want to revoke Admin permissions for this user?`
          }
        />
      ),
      headerClassName: styles['data-grid-header'],
    });
  } else {
    columns.splice(automaticBillingFeature ? 4 : 3, 0, {
      field: 'isAdmin',
      headerName: 'Role',
      width: 100,
      disableColumnMenu: true,
      valueGetter: (params) => (params.value ? 'Admin' : 'User'),
      headerClassName: styles['data-grid-header'],
    });
  }

  return columns;
};
