import { ActionType as EdActionType, useEditorContext } from '@/context/EditorContext';

import { ActionType, useLibraryPanelContext } from '@/components/LibraryPanel/LibraryPanelContext';

export const useHandleOutsideClickForRow = () => {
  const { dispatch: editorDisp } = useEditorContext();
  const { dispatch } = useLibraryPanelContext();

  const handleOutsideClickForRow = (event: MouseEvent) => {
    const target = event.target as Element;
    // de-select layer if user clicks anywhere the sidepanel component, that is not a layer row, button or dropdown
    if (
      target.matches('.simulations.container.panel *') &&
      !target.matches('.treble-layer-row, .treble-layer-row *, button, .sim-selector-container *')
    ) {
      window.removeEventListener('click', handleOutsideClickForRow);
      window.eventAddedForOutsideClick = false;
      dispatch({
        type: ActionType.CLOSE_LIBRARY_PANEL,
      });
      dispatch({
        type: ActionType.SET_MULTI_SELECT_ITEMS,
        multiSelectedItemIds: [],
      });
      editorDisp({
        type: EdActionType.CLEAR_SELECTED,
      });
    }
  };
  return handleOutsideClickForRow;
};
