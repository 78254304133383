import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { useEditorContext } from '@/context/EditorContext';

import { useAuralizerContext } from '@/components/Auralizer/AuralizerContext';

import { getSimulationById } from '@/hooks';

export const useSetPresetRoute = () => {
  const { isPlayingAuralization } = useAuralizerContext();
  const { isCameraInsideModel } = useEditorContext();
  const navigate = useNavigate();

  const setPresetRoute = async (presetId: string, simulationId: string) => {
    try {
      const simulation = await getSimulationById(simulationId);

      if (simulation) {
        navigate(
          `/auralizer?presetId=${presetId}&cameraInside=${isCameraInsideModel}&isPlaying=${isPlayingAuralization}&mid=${simulation.modelId}&sid=${simulation.id}`
        );
      }
    } catch (error) {
      toast.error('Preset could not be loaded - it contains a simulation that does not exist anymore');
    }
  };

  return setPresetRoute;
};
