/* eslint-disable no-prototype-builtins */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { datadogRum } from '@datadog/browser-rum';
import Omnitone, { HOARenderer } from 'omnitone/build/omnitone.min.esm.js';

import { crossProduct, normalize } from './utils';

import { AudioNodesDict, AudioSettings, RecUrl, SimSrcRecHash } from './types';

const AMBISONICS_CHANNEL_COUNT = 9;
const BINAURAL_CHANNEL_COUNT = 2;
export class AudioEngine {
  private static instance: AudioEngine;

  private constructor() {
    this.audioSettings.firstSimNormMax = {
      relMax: 1,
      rulingSource: null,
      firstSim: null,
    };
  }

  public static getInstance(): AudioEngine {
    if (!AudioEngine.instance) {
      AudioEngine.instance = new AudioEngine();
    }

    return AudioEngine.instance;
  }

  // @ts-expect-error the AudioContext can not be initialized because it must be resumed (or created) after a user gesture on the page. https://goo.gl/7K7WLu
  audioContext: AudioContext;

  utf8decoder = new TextDecoder();

  sourceIdArray: string[] = [];
  originalSourceIdArray: string[] = []; // former editor.sourceIdArray

  debugRecList = [];

  recUrlsObject: { [key: string]: RecUrl } = {};

  // @ts-expect-error look at this later
  soaRenderer: HOARenderer;

  isMultichannelEnabled: boolean | null = null;
  isAmbisonicsActive: boolean | null = null;

  simSrcRecHash: SimSrcRecHash = {};

  audioSettings: AudioSettings = {} as AudioSettings;

  bypassInputGainDict: { [key: string]: GainNode } = {};

  storedReceiverGain: { [key: string]: GainNode } = {};

  rotationMatrix3 = new Float32Array(9);

  audioElementSourceDictionary: any = {};

  sourceLibrary: any = {};

  sourceGainDictionary: {
    [key: string]: {
      disconnect: any;
      connect: any;
      id: string;
      gain: {
        value: number;
        channelCount: number;
      };
    };
  } = {};

  storedConvolverNode: any = {};

  audioElementDictionary: { [key: string]: HTMLAudioElement } = {};

  receiverList: string[] = [];

  resetAudioEngine = () => {
    console.log('[Auralization] RESET AURAL');

    this.audioContext = new AudioContext({
      latencyHint: 'interactive',
      sampleRate: 32000,
    });
    // remove nodes from audioElementDictionary if they exist
    if (Object.keys(this.audioElementDictionary).length > 0) {
      this.pauseAllSources();
      this.audioElementDictionary = {};
    }
    this.sourceGainDictionary = {};
    this.recUrlsObject = {};
    this.storedConvolverNode = {};
  };

  updateSources = (sourceId: string, isPlaying: boolean, soundPath: string = '') => {
    if (this.audioElementDictionary[sourceId]) {
      this.audioElementDictionary[sourceId].src = soundPath;
      if (soundPath === '') {
        // if src attr is empty then it equals the current route wtf
        // we need to remove the src attr if the path is empty...
        this.audioElementDictionary[sourceId].removeAttribute('src');
        this.audioElementDictionary[sourceId].pause();
      }
    }

    this.audioElementDictionary[sourceId].load();

    // keep playing...
    if (isPlaying) {
      this.audioElementDictionary[sourceId].play();
    }
  };

  createGain = (sourceId: string) => {
    try {
      const newAudioElement = document.createElement('audio');
      newAudioElement.id = 'audio-' + sourceId;
      newAudioElement.loop = true;
      newAudioElement.crossOrigin = 'anonymous';

      this.audioElementDictionary[sourceId] = newAudioElement;
      this.bypassInputGainDict[sourceId] = this.audioContext.createGain();
      this.bypassInputGainDict[sourceId].gain.value = 1 / 4;

      // @ts-expect-error Property 'id' is missing in type 'GainNode' but required in type '{ disconnect: any; connect: any; id: string; gain: { value: number; channelCount: number; }; }'
      this.sourceGainDictionary[sourceId] = this.audioContext.createGain();
      this.sourceGainDictionary[sourceId].gain.value = 1;
      this.sourceGainDictionary[sourceId].gain.channelCount = 1;
      this.sourceGainDictionary[sourceId].id = sourceId;

      this.audioElementSourceDictionary[sourceId] = this.audioContext.createMediaElementSource(
        this.audioElementDictionary[sourceId]
      );

      this.audioElementSourceDictionary[sourceId].connect(this.sourceGainDictionary[sourceId]);

      this.sourceGainDictionary[sourceId].connect(this.bypassInputGainDict[sourceId]);

      this.storedReceiverGain[sourceId] = this.bypassInputGainDict[sourceId];

      this.bypassInputGainDict[sourceId].connect(this.soaRenderer.input);
    } catch (error) {
      console.log('error creating gain', error);
    }
  };

  bypassAll = () => {
    // To bybass the reverb we need to loop through the sourceGainDictionary
    // and make sure that all the items are connected to the bypassInputGainDict.
    // All these if statements are here for TS-checks, but if implemented correctly
    // these should not be necessary... please take a look later @Aslaug
    for (const source_id of this.originalSourceIdArray) {
      if (this.sourceGainDictionary[source_id] && this.bypassInputGainDict[source_id]) {
        if (!this.storedReceiverGain[source_id]) {
          this.sourceGainDictionary[source_id].connect(this.bypassInputGainDict[source_id]);
        } else {
          this.sourceGainDictionary[source_id].disconnect(this.storedReceiverGain[source_id]);
          this.sourceGainDictionary[source_id].connect(this.bypassInputGainDict[source_id]);
        }

        this.storedReceiverGain[source_id] = this.bypassInputGainDict[source_id];
        this.bypassInputGainDict[source_id].connect(this.soaRenderer.input);
      }
    }
  };

  updateStartAngle = (horRot: number, verRot: number) => {
    this.update(horRot, verRot);
  };

  update(azimuth: number, elevation: number) {
    const theta = azimuth;
    const phi = elevation;

    const forward = [Math.sin(theta) * Math.cos(phi), Math.sin(phi), Math.cos(theta) * Math.cos(phi)];

    const upInitial = [0, 1, 0];

    const right = normalize(crossProduct(forward, upInitial));
    const up = normalize(crossProduct(right, forward));

    this.rotationMatrix3[0] = right[0];
    this.rotationMatrix3[1] = right[1];
    this.rotationMatrix3[2] = right[2];
    this.rotationMatrix3[3] = up[0];
    this.rotationMatrix3[4] = up[1];
    this.rotationMatrix3[5] = up[2];
    this.rotationMatrix3[6] = forward[0];
    this.rotationMatrix3[7] = forward[1];
    this.rotationMatrix3[8] = forward[2];

    this.soaRenderer.setRotationMatrix3(this.rotationMatrix3);
  }

  // change this for element or all chained
  playbackToggle = (isPlaying: boolean) => {
    if (isPlaying) {
      this.audioContext.resume();
      this.playAllSources();
    } else {
      this.audioContext.resume();
      this.pauseAllSources();
    }
  };

  pauseAllSources = () => {
    Object.values(this.audioElementDictionary).forEach((element: HTMLAudioElement) => {
      try {
        element.pause();
      } catch (error) {
        console.log('error pausing all sources', error);
      }
    });
  };

  playAllSources = () => {
    Object.values(this.audioElementDictionary).forEach((element: HTMLAudioElement) => {
      if (element.src && element.src.length > 0) {
        element.play();
      }
    });
  };

  /**
   * read initial max and apply factor based on some stored data
   * @param {any} unitValue
   * @param {any} scaling
   */

  rescaleGainBasedOnNormFactor = (unitValue: number, scaling: number) => {
    const originalMax = this.audioSettings.firstSimNormMax.relMax;
    const ratio = scaling / originalMax;

    return unitValue * ratio;
  };

  // master gain
  setMasterGain = (value: number, audioNodesDict: { [sourceId: string]: AudioNodesDict }) => {
    const unitValue = Math.pow(10, value / 20);

    Object.entries(audioNodesDict).forEach((sim: any) => {
      const simId = sim[0];
      Object.entries(sim[1]['receiverGains']).forEach((receiverGainObject: any) => {
        const sourceId = receiverGainObject[0];
        Object.entries(receiverGainObject[1]).forEach((recInputNode: any) => {
          const normFactor = this.audioSettings[simId]?.[sourceId].normFactor;
          const newValue = this.rescaleGainBasedOnNormFactor(unitValue, normFactor);
          if (isFinite(newValue) && newValue !== null) {
            recInputNode[1].gain.value = newValue;
          } else {
            // We need to figure out why some simulations don't have a numberFactor
            // and this is also an experiment to see how custom errors work in DD :)
            const error = new Error(
              `NumberFactor is infinite for sim ${simId} and source ${sourceId}. RelMax is ${this.audioSettings.firstSimNormMax.relMax}`
            );
            datadogRum.addError(error);
          }
        });
      });
    });

    Object.keys(this.bypassInputGainDict).forEach((bypassGainNodeKey) => {
      this.bypassInputGainDict[bypassGainNodeKey].gain.value = unitValue / 4;
    });
  };

  updateSingleGain = (value: number, sourceId: string) => {
    // todo bypass
    const unitValue = Math.pow(10, value / 20);
    if (this.sourceGainDictionary.hasOwnProperty(sourceId)) {
      this.sourceGainDictionary[sourceId].gain.value = unitValue;
    }
  };

  muteSingleGain = (sourceId: string) => {
    let node = null;
    if (this.sourceGainDictionary.hasOwnProperty(sourceId)) {
      node = this.sourceGainDictionary[sourceId];
    } else {
      const hash = this.simSrcRecHash[sourceId]?.['srcHmapReverse']?.[sourceId];
      console.log("We can't escape it right now since the ids are modified: ", hash);
    }
    if (node) {
      // todo bypass
      node.gain.value = 0.0;
    }
  };

  setOutputBinaural = () => {
    this.audioContext.destination.channelCount = BINAURAL_CHANNEL_COUNT;
    // disconnectthe direct connection if we were in ambisonics mode
    if (this.isAmbisonicsActive) this.soaRenderer._hoaRotator.output.disconnect(this.audioContext.destination);
    this.soaRenderer.output.connect(this.audioContext.destination);
    this.isAmbisonicsActive = false;
    console.log('Binaural mode, channels out: ', this.audioContext.destination.channelCount);
  };

  setOutputAmbisonics = () => {
    this.audioContext.destination.channelCount = AMBISONICS_CHANNEL_COUNT;
    // disconnect the binaural output first
    // consider setting the gain to zero
    this.soaRenderer.output.disconnect(this.audioContext.destination);
    this.soaRenderer._hoaRotator.output.connect(this.audioContext.destination);
    this.isAmbisonicsActive = true;
    console.log('Ambisonics mode, channels out: ', this.audioContext.destination.channelCount);
  };

  // returns true if browser sound output device has more at least 9 channels
  checkMultiChannelOutput = () => {
    const maxChannelCount = this.audioContext.destination.maxChannelCount;
    if (maxChannelCount >= AMBISONICS_CHANNEL_COUNT) {
      this.isMultichannelEnabled = true;
    } else {
      this.isMultichannelEnabled = false;
    }
    console.log('Max channels: ', this.audioContext.destination.maxChannelCount);
    return this.isMultichannelEnabled;
  };

  onLoad = (newSourceArray: string[]) => {
    console.log('[Auralization] START AURAL');
    this.resetAudioEngine();
    this.originalSourceIdArray = newSourceArray;

    this.soaRenderer = Omnitone.createHOARenderer(this.audioContext, {
      ambisonicOrder: 2,
    });

    // initialize on binaural output
    this.setOutputBinaural();

    this.soaRenderer.setRenderingMode('ambisonic');

    this.soaRenderer.initialize();

    this.originalSourceIdArray.forEach((sourceId: string) => {
      this.createGain(sourceId);
    });
  };
}
