import { FC } from 'react';

import { SelectOption, TrblSelect } from '@/components/Shared/TrblSelect';

import styles from './styles.module.scss';

type FilterItemProps = {
  label: string;
  menuItems: SelectOption[];
  value: string;
  setValue: (value: string) => void;
  disabled?: boolean;
};

export const FilterItem: FC<FilterItemProps> = ({ label, menuItems, value, setValue, disabled = false }) => (
  <div className={styles['filter-item']}>
    <div className={styles['label']}>{label}</div>
    <TrblSelect
      menuItems={menuItems}
      value={value}
      disabled={disabled}
      setValue={setValue}
      minimal
      className={styles['custom-select']}
    />
  </div>
);
