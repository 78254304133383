import { useEffect, useState } from 'react';
import { datadogRum } from '@datadog/browser-rum';

import { BufferLoadingScreen } from '@/components/Shared/BufferLoadingScreen';
import { Checkbox } from '@/components/Shared/Checkbox';
import { DarkBox } from '@/components/Shared/Popup';
import { Text } from '@/components/Shared/Text';
import { ABS_PLOT_TITLE, ABS_RANGE, TICK_VALS } from '@/components/MaterialDetailsPopup/constants';
import { MaterialDetailsPlot } from '@/components/MaterialDetailsPopup/MaterialDetailsPlot';
import { ComplexInputTable } from './ComplexInputTable';
import { MaterialFileUpload } from './MaterialFileUpload';
import { MaterialTextInput } from './MaterialTextInput';
import { MissingKeys } from './MissingKeys';
import { UploadFileToggle } from './UploadFileToggle';

import {
  FREQUENCY_RANGE_FULL_OCTAVE,
  FREQUENCY_RANGE_THIRD_OCTAVE_NUM,
  PLOT_WIDTH,
  VALID_CSV_TYPES,
} from '../constants';

import { getValuesByPropName } from '../utils/getValuesByPropName';
import { parseCSV } from '../utils/parseCsv';
import { parseInput } from '../utils/parseInput';
import { parseObjectToStringComplex } from '../utils/parseObjectToStringComplex';

import { ComplexData, MaterialInfoJsonDto } from '../types';
import { MaterialDto } from '@/types';

import styles from '../styles.module.scss';

type ComplexInputProps = {
  data: ComplexData;
  setData: React.Dispatch<React.SetStateAction<ComplexData>>;
  label: string;
  rowLabels: string[];
  specificRowLabels?: string[];
  formDisabled: boolean | undefined;
  fittedData: MaterialDto | null;
  setFittedData: React.Dispatch<React.SetStateAction<MaterialDto | null>>;
  shouldReset: boolean;
  plotHeight: number;
  range?: number[];
  isSpecific?: boolean;
  setIsSpecific?: React.Dispatch<React.SetStateAction<boolean>>;
};

const PLACEHOLDER = (label: string, isNormalized?: boolean) => `Paste ${
  isNormalized ? 'specific ' : ''
}${label} data for each one-third octave band from 50 Hz - 10.000 Hz separated by space and in a new line e.g.
  50	  0.45	0.78
  ...
  or switch to  upload mode, to upload a .csv or .txt directly.`;

export const ComplexInput: React.FC<ComplexInputProps> = ({
  data,
  setData,
  label,
  rowLabels,
  formDisabled,
  fittedData,
  setFittedData,
  plotHeight,
  range,
  isSpecific,
  specificRowLabels,
  setIsSpecific,
  shouldReset,
}) => {
  const [textInput, setTextInput] = useState<string>('');
  const [uploadedFile, setUploadedFile] = useState<{ file: File; contents: string | ArrayBuffer | null | undefined }>();
  const [enableUploadFile, setEnableUploadFile] = useState(true);
  const [realValues, setRealValues] = useState<number[]>([]);
  const [imagValues, setImagValues] = useState<number[]>([]);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [disableEdit, setDisableEdit] = useState<boolean>(false);
  const [parsedMaterialJson, setParsedMaterialJson] = useState<MaterialInfoJsonDto | null>();

  useEffect(() => {
    setDisableEdit(formDisabled || fittedData !== null);
  }, [formDisabled, fittedData]);

  useEffect(() => {
    if (shouldReset) {
      setData({});
      setFittedData(null);
      if (setIsSpecific) setIsSpecific(false);
    }
  }, [shouldReset]);

  useEffect(() => {
    if (fittedData) {
      setParsedMaterialJson(JSON.parse(fittedData.materialJson));
    } else {
      setParsedMaterialJson(null);
    }
  }, [fittedData]);

  useEffect(() => {
    const newTextInput = parseObjectToStringComplex(data);
    setTextInput(newTextInput);
    const realValues = getValuesByPropName('real', data);
    const imagValues = getValuesByPropName('imag', data);
    setRealValues(realValues);
    setImagValues(imagValues);
  }, [data]);

  useEffect(() => {
    if (uploadedFile) {
      setErrorMessage(null);
      setFittedData(null);

      if (VALID_CSV_TYPES.includes(uploadedFile.file.type)) {
        const parsedTxt = parseCSV(uploadedFile.contents as string);
        if (Object.keys(parsedTxt).length === 0) {
          datadogRum.addError(`Invalid or empty file: ${parsedTxt}`);
          setErrorMessage('Invalid or empty file');
        } else {
          datadogRum.addAction(`Successful CSV file upload ${parsedTxt}`);
          setData(parsedTxt);
        }
      } else {
        const parsedTxt = parseInput(uploadedFile.contents as string, true);
        if (Object.keys(parsedTxt).length === 0) {
          datadogRum.addError(`Invalid or empty file: ${parsedTxt}`);
          setErrorMessage('Invalid or empty file');
        } else {
          datadogRum.addAction(`Successful text file upload ${parsedTxt}`);
          setData(
            parsedTxt as Record<
              number,
              {
                real: number;
                imag: number;
              }
            >
          );
        }
      }
    }
  }, [uploadedFile]);

  const changeInput = (newInput: string) => {
    setTextInput(newInput);
  };

  const validateInput = (newInput: string) => {
    const parsedTxt = parseInput(newInput, true);
    setData(
      parsedTxt as Record<
        number,
        {
          real: number;
          imag: number;
        }
      >
    );
  };

  return (
    <div>
      <div style={{ overflow: 'hidden' }}>
        <div className={styles.space_between}>
          <div className={`${styles.justify_left} ${styles.align_center}`}>
            <p className={styles.text_input_title}> {label}</p>

            {isSpecific !== undefined && (
              <div
                className={styles.align_center}
                style={{
                  opacity: disableEdit ? 0.6 : 1,
                  cursor: disableEdit ? 'not-allowed' : 'inherit',
                  marginLeft: '60px',
                }}>
                <Text
                  style={{
                    marginRight: '10px',
                  }}
                  type="semibold-10px">
                  Specific
                </Text>
                <Checkbox
                  id="ImpedanceType"
                  title="Normalized"
                  isChecked={isSpecific}
                  onChange={setIsSpecific}
                  disabled={disableEdit}
                />
              </div>
            )}
          </div>

          <div className={styles.justify_right}>
            <UploadFileToggle toggle={setEnableUploadFile} isToggled={enableUploadFile} disabled={disableEdit} />
          </div>
        </div>
        {enableUploadFile ? (
          <MaterialFileUpload
            setUploadedFile={setUploadedFile}
            errorMessage={errorMessage}
            setErrorMessage={setErrorMessage}
            shouldReset={shouldReset}
            disabled={disableEdit}
          />
        ) : (
          <MaterialTextInput
            textInput={textInput}
            changeInput={changeInput}
            validateInput={validateInput}
            placeholder={PLACEHOLDER(label, isSpecific)}
            disabled={disableEdit}
          />
        )}

        <div style={{ overflow: 'auto' }}>
          <ComplexInputTable
            firstRowLabel={isSpecific && specificRowLabels ? specificRowLabels[0] : rowLabels[0]}
            secondRowLabel={isSpecific && specificRowLabels ? specificRowLabels[1] : rowLabels[1]}
            data={data}
          />
        </div>
        <MissingKeys data={data} keys={FREQUENCY_RANGE_THIRD_OCTAVE_NUM} />
      </div>
      <DarkBox>
        <div className={styles.space_between}>
          {formDisabled && <BufferLoadingScreen />}
          <MaterialDetailsPlot
            ticktext={FREQUENCY_RANGE_FULL_OCTAVE}
            range={range}
            tickvals={TICK_VALS}
            xData={Object.keys(data)}
            yData={realValues}
            secondYData={imagValues}
            yDataName={'Real'}
            secondYDataName={'Imag'}
            yAxesTitle={`${isSpecific ? 'Specific ' : ''}${label}`.toUpperCase()}
            plotHeight={plotHeight}
            plotWidth={PLOT_WIDTH}
          />
          <MaterialDetailsPlot
            range={ABS_RANGE}
            ticktext={FREQUENCY_RANGE_FULL_OCTAVE}
            tickvals={TICK_VALS}
            xData={TICK_VALS}
            yData={parsedMaterialJson?.FittedAbsorptionCoefficients ?? []}
            secondYData={parsedMaterialJson?.InputAbsorptionCoefficients ?? []}
            yDataName={'Fitted'}
            secondYDataName={'Input'}
            yAxesTitle={ABS_PLOT_TITLE}
            plotHeight={plotHeight}
            plotWidth={PLOT_WIDTH}
          />
        </div>
      </DarkBox>
    </div>
  );
};
