import { useEffect, useState } from 'react';

import { TrblTooltip } from '@/components/Shared';
import { AudioEngine } from '../Auralizer/AudioEngine';
import { useAuralizerContext } from '../Auralizer/AuralizerContext';
import { usePresetContext } from '../Auralizer/PresetContext';
import { TrblAddIcon, TrblReferenceCurveIcon } from '../Icons';
import { SoundLibraryPopup } from './SoundLibraryPopup';
import { TrblTruncate } from './TrblTruncate';

import { config } from '@/__config__/config';

import { SourceSettings } from '../AuralizerPresets/types';

const { cdnUrl } = config;

import styles from './styles.module.scss';

export const SoundLibrary = ({
  sourceId,
  sourceSettings,
  sourceIndex,
}: {
  sourceId: string;
  sourceSettings?: SourceSettings;
  sourceIndex: number;
}) => {
  const [soundLibraryOpen, setSoundLibraryOpen] = useState(false);
  const [soundName, setSoundName] = useState<string | null>(null);
  const { isPlayingAuralization, auralizerSounds } = useAuralizerContext();
  const { selectedSounds, selectedPresetEdited } = usePresetContext();

  useEffect(() => {
    if (
      // soundPath is by default null
      sourceSettings?.soundPath === null ||
      // presets with empty sound have it as empty string
      sourceSettings?.soundPath === '' ||
      (sourceSettings === undefined && !selectedPresetEdited)
    ) {
      setSoundName(null);
    }
  }, [sourceSettings]);

  useEffect(() => {
    const audioEngine = AudioEngine.getInstance();

    if (selectedSounds[sourceIndex]?.urlObject) {
      // local sound to a source
      setSoundName(selectedSounds[sourceIndex]?.name);
      audioEngine.updateSources(sourceId, isPlayingAuralization, selectedSounds[sourceIndex].urlObject ?? '');
      setSoundLibraryOpen(false);
    } else if (selectedSounds[sourceIndex]) {
      if (selectedSounds[sourceIndex].soundPath !== '' && selectedSounds[sourceIndex].name !== '') {
        // loading a sound from sound library
        setSoundName(selectedSounds[sourceIndex].name);
        const newSoundPath = `${cdnUrl}${selectedSounds[sourceIndex].soundPath}`;
        audioEngine.updateSources(sourceId, isPlayingAuralization, newSoundPath);
      } else if (selectedSounds[sourceIndex].id !== '') {
        const librarySound = auralizerSounds.find((sound) => sound.id === selectedSounds[sourceIndex].id);
        if (librarySound) {
          setSoundName(librarySound?.name);
          const newSoundPath = `${cdnUrl}${librarySound.soundPath}`;
          audioEngine.updateSources(sourceId, isPlayingAuralization, newSoundPath);
        }
      } else {
        // if there is no sound assigned to this source
        audioEngine.updateSources(sourceId, false, '');
      }
    } else {
      // if there is no sound assigned to this source
      audioEngine.updateSources(sourceId, false, '');
    }
  }, [selectedSounds, sourceId]);

  return (
    <>
      <div className={styles.add_sound_container}>
        <button className={styles.add_sound_button} onClick={() => setSoundLibraryOpen(true)}>
          {soundName ? (
            <TrblTooltip title={soundName.length > 19 ? soundName : ''}>
              <span className={styles.has_sound_text_container}>
                <span className={styles.has_sound_text}>
                  <TrblTruncate text={soundName} strLen={22} />
                </span>
                <TrblReferenceCurveIcon fill="#39BD9D" height="12" width="12" />
              </span>
            </TrblTooltip>
          ) : (
            <>
              <span className={styles.add_sound_text}>Add sound</span>
              <TrblAddIcon fill="#adadad" width="13" height="12" />
            </>
          )}
        </button>
      </div>
      {soundLibraryOpen && (
        <SoundLibraryPopup
          showPopup={soundLibraryOpen}
          setShowPopup={setSoundLibraryOpen}
          sourceSettings={sourceSettings}
          sourceIndex={sourceIndex}
        />
      )}
    </>
  );
};
