import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useEditorContext } from '@/context/EditorContext';
import { ModelActionType, useModelContext } from '@/context/ModelContext';
import { ActionType, useSimulationContext } from '@/context/SimulationContext';

import { Button } from '@mui/material';

import { TrblResultsIcon } from '../Icons';
import { TrblTooltip } from '../Shared';

import { Simulation } from '@/types';

import './styles.scss';

export const ResultsButton = ({
  status,
  selectedSimulation,
  originalSimulation,
  userTouched,
}: {
  status: number | null;
  selectedSimulation: Simulation | null;
  originalSimulation: Simulation | null;
  userTouched: boolean;
}) => {
  const navigate = useNavigate();

  const { isInResultsMode } = useEditorContext();
  const { dispatch } = useSimulationContext();
  const { dispatch: modelDispatch } = useModelContext();

  const [showResultButton, setShowResultButton] = useState(false);

  useEffect(() => {
    if (isInResultsMode) {
      setShowResultButton(true);
    } else {
      switch (status) {
        case 1:
          // running
          setShowResultButton(false);
          break;
        case 2:
          // completed
          setShowResultButton(true);
          break;
        case 3:
          // cancelled
          setShowResultButton(false);
          break;
        case 4:
          // error
          setShowResultButton(false);
          break;
        default:
          setShowResultButton(false);
      }
    }
  }, [status, userTouched, isInResultsMode]);

  const toggleResults = () => {
    if (selectedSimulation) {
      const currentSim = isInResultsMode ? originalSimulation : selectedSimulation;
      if (isInResultsMode && currentSim) {
        modelDispatch({
          type: ModelActionType.SET_CURRENT_MODEL_ID,
          modelId: currentSim.modelId,
        });
        dispatch({
          type: ActionType.SET_SELECTED_SIMULATION,
          simulation: { ...currentSim },
        });
        navigate(`/editor?mid=${currentSim.modelId}&sid=${currentSim.id}`);
      } else {
        navigate(`/results?mid=${selectedSimulation.modelId}&sid=${selectedSimulation.id}`);
      }
    }
  };

  return (
    <>
      {showResultButton && (
        <TrblTooltip title={`${userTouched && status === 2 ? 'Results can not be viewed while in edit mode' : ''}`}>
          <span>
            <Button
              disabled={!isInResultsMode && userTouched && status === 2}
              className="result-button"
              variant="text"
              startIcon={<TrblResultsIcon />}
              onClick={toggleResults}>
              {isInResultsMode ? 'Exit Results' : 'Results'}
            </Button>
          </span>
        </TrblTooltip>
      )}
    </>
  );
};
