import { resultTypes } from './constants';

import {
  PointResult,
  ResultType,
  ResultTypeOption,
  SolveTask,
  SourceResultForGridReceiverDto,
  SourceResults,
  TaskResultForReceiver,
} from '@/types';

const getAvailableSources = (sourceResult: SourceResults[] | SourceResultForGridReceiverDto[]) => {
  const uniqueSources: PointResult[] = [];

  sourceResult
    .sort(
      (a: SourceResults | SourceResultForGridReceiverDto, b: SourceResults | SourceResultForGridReceiverDto) =>
        a.orderNumber - b.orderNumber
    )
    .forEach((s) => {
      if (uniqueSources.findIndex((x) => x.id === s.sourcePointId) === -1) {
        uniqueSources.push({
          id: s.sourcePointId,
          name: `${s.label ? s.label : 'Source ' + (uniqueSources.length + 1)}`,
          coords: `(${s.sourceX}, ${s.sourceY}, ${s.sourceZ})`,
        });
      }
    });

  return uniqueSources;
};

const getAvailableReceivers = (selectedSource: SourceResults) => {
  const receiverResults = selectedSource.taskResultForReceiver
    ?.sort((a: TaskResultForReceiver, b: TaskResultForReceiver) => (a?.orderNumber ?? 0) - (b?.orderNumber ?? 0))
    .map((receiver, index) => ({
      id: receiver.pointId,
      name: `${receiver.label ? receiver.label : 'Receiver ' + (index + 1)}`,
      coords: `(${receiver.x}, ${receiver.y}, ${receiver.z})`,
    }));

  return receiverResults;
};

const getAvailableGridReceivers = (selectedSource?: SourceResultForGridReceiverDto) => {
  const gridRecieverResults = selectedSource?.gridReceiverResults
    .sort((a, b) => a.orderNumber - b.orderNumber)
    .map((receiver, index: number) => ({
      id: receiver.pointId,
      name: `${receiver.label ? receiver.label : 'Surface receiver ' + (index + 1)}`,
      coords: `(${receiver.userInput.centerPoint.x}, ${receiver.userInput.centerPoint.y}, ${receiver.userInput.centerPoint.z})`,
    }));

  return gridRecieverResults || [];
};

export const getSourcesAndReceivers = (
  lastSolveResults: SolveTask,
  selectedResultType: string,
  selectedSourcePointId?: string,
  selectedReceiverPointIds?: string[]
) => {
  const availableSources = getAvailableSources(lastSolveResults.sourceResults);
  // If no source is selected we default to the first one in the list
  const sourcePointId = selectedSourcePointId || availableSources[0].id;

  const selectedSourceObject = lastSolveResults?.sourceResults.find(
    (source: SourceResults) => source.sourcePointId === sourcePointId && source.resultType === selectedResultType
  ) as SourceResults;

  const availableReceivers = getAvailableReceivers(selectedSourceObject);

  // When initializing we default to having the first receiver selected (given that we have some receivers to select from)
  let receiverPointIds: string[] = [];
  if (selectedReceiverPointIds === undefined && availableReceivers.length) {
    receiverPointIds = [availableReceivers[0].id];
  } else if (selectedReceiverPointIds?.length) {
    receiverPointIds = selectedReceiverPointIds;
  }

  const selectedReceiverObjects = selectedSourceObject?.taskResultForReceiver?.filter((receiver) =>
    receiverPointIds.includes(receiver.pointId)
  );

  return {
    selectedSourceObject,
    selectedReceiverObjects,
    availableSources,
    availableReceivers,
  };
};

export const getSourcesAndGridReceivers = (
  lastGridResults: SourceResultForGridReceiverDto[],
  selectedResultType: string,
  selectedSourcePointId?: string,
  selectedGridReceiverPointIds?: string[]
) => {
  const availableSources = getAvailableSources(lastGridResults);
  // If no source is selected we default to the first one in the list
  const sourcePointId = selectedSourcePointId || availableSources[0].id;

  const selectedSourceObject = lastGridResults.find(
    (source) => source.sourcePointId === sourcePointId && source.resultType === selectedResultType
  );

  const availableGridReceivers = getAvailableGridReceivers(selectedSourceObject);

  // If no reciever is selected we default to them all selected
  const gridReceiverPointIds = selectedGridReceiverPointIds ?? availableGridReceivers.map((x) => x.id);

  const selectedGridReceiverObjects = selectedSourceObject?.gridReceiverResults?.filter((receiver) =>
    gridReceiverPointIds.includes(receiver.pointId)
  );

  return {
    selectedSourceObject,
    selectedGridReceiverObjects,
    availableSources,
    availableGridReceivers,
  };
};

/** Filter available result types based on what we have in the simulation results */
export const getAvailableResultTypes = (results: SourceResults[] | SourceResultForGridReceiverDto[]) => {
  const availableResultTypes: Array<ResultTypeOption> = [];

  resultTypes.forEach((x) => {
    if (results.some((r) => r.resultType === x.id)) {
      availableResultTypes.push(x);
    }
  });

  return availableResultTypes;
};

/** Sets the default result type when new results have been loaded.
 * If we already had a result type selected we try to keep that selection if it's in the list of available result types.
 * If nothing was selected previously we default to Hybrid.
 * If Hybrid is not in the simulation we default to whatever type we have there.
 */
export const getDefaultResultType = (availableResultTypes: Array<ResultTypeOption>, selectedResultType?: string) => {
  let defaultResultType = selectedResultType || ResultType.Hybrid;

  if (!availableResultTypes.some((x) => x.id === defaultResultType)) {
    defaultResultType = availableResultTypes[0].id;
  }

  return defaultResultType;
};
