import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

import { ActionType as SimActionType, useSimulationContext } from '@/context/SimulationContext';

import { useGetAuralizationPresets } from '@/components/AuralizerPresets/hooks/useGetAuralizationPresets';
import { ActionType, useAuralizerContext } from '../AuralizerContext';
import { ActionType as PreActionType, usePresetContext } from '../PresetContext';
import { useConnectIRS } from './useConnectIRS';

import { Simulation } from '@/types';

export const useAuralizerPreset = () => {
  const { dispatch, availableSimsToCompare, simSrcRecHash, selectedAurSim, audioNodesDict } = useAuralizerContext();
  const { dispatch: preDispatch, availablePresets, selectedPreset } = usePresetContext();
  const {
    dispatch: simDispatch,
    simulationState: { originalSim },
  } = useSimulationContext();

  useGetAuralizationPresets();

  const [searchParams] = useSearchParams();
  const queryPresetId = searchParams.get('presetId') || '';

  const connectIRS = useConnectIRS();

  useEffect(() => {
    // get and set the selected preset from query param - if available
    if (availablePresets.length > 0 && queryPresetId !== '') {
      const currentPreset = availablePresets.find((preset) => preset.id === queryPresetId);
      if (currentPreset) {
        preDispatch({
          type: PreActionType.SET_SELECTED_PRESET,
          preset: currentPreset,
        });
      }
    }
  }, [queryPresetId, availablePresets]);

  useEffect(() => {
    if (selectedPreset && availableSimsToCompare && simSrcRecHash) {
      const allSimulationsInSpace: Simulation[] = availableSimsToCompare.flatMap((space) => space.simulations);
      const simsToCompare: Simulation[] =
        selectedPreset.simulations?.reduce((acc: Simulation[], simulationId) => {
          const foundSim = allSimulationsInSpace.find((s) => s.id === simulationId);
          if (foundSim) {
            return [...acc, foundSim];
          } else {
            return [...acc];
          }
        }, [] as Simulation[]) ?? [];

      if (selectedAurSim === null) {
        dispatch({ type: ActionType.SET_SELECTED_AUR_SIM, simulation: simsToCompare[0] });
      } else {
        const selectedAurSimBelongsToPreset = simsToCompare.find((sim) => sim.id === selectedAurSim.id);
        if (!selectedAurSimBelongsToPreset) {
          dispatch({ type: ActionType.SET_SELECTED_AUR_SIM, simulation: simsToCompare[0] });
        }
      }

      dispatch({
        type: ActionType.SET_INITIAL_SIMS_TO_COMPARE,
        simulations: simsToCompare,
      });
      simDispatch({
        type: SimActionType.SET_SELECTED_SIMULATION,
        simulation: simsToCompare[0],
      });

      // get the IRS files for each comparison except the first one (it is fetched elsewhere)
      // so they are ready when the user starts switching simulations
      simsToCompare.forEach((sim, index) => {
        if (audioNodesDict?.[sim.id] !== undefined) {
          // do nothing - this simulation has already been connected earlier
          dispatch({
            type: ActionType.SET_FETCHING,
            simId: sim.id,
            isFetching: false,
          });
        } else if (index > 0) {
          connectIRS(sim, simsToCompare[0], simSrcRecHash);
        }
      });
    } else {
      dispatch({
        type: ActionType.SET_INITIAL_SIMS_TO_COMPARE,
        simulations: originalSim ? [originalSim] : [],
      });
    }
  }, [selectedPreset?.id, availableSimsToCompare, simSrcRecHash]);
};
