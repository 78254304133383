import { FC, useEffect, useState } from 'react';
import { useThree } from '@react-three/fiber';
import { Camera, Vector3 } from 'three';

import { Grid } from '@/components/Editor/components/Viewport/Grid';
import { PointAuralizer } from '@/components/Editor/components/Viewport/PointAuralizer';
import { useFirstPersonCamera } from '@/components/Editor/hooks';
import { Model3D } from '../../Editor/components/Viewport/Model3d';

import { ModelLayerGroup } from '@/context/ModelContext/types';
import { Source } from '@/types';

type ViewportContentProps = {
  canvasRef: React.RefObject<HTMLCanvasElement>;
  sources: Source[];
  currentPosition: Vector3;
  lookingAt: Vector3;
  layerGroups: ModelLayerGroup[];
  layerColors: Record<string, string>;
  onModelRendered?: () => void;
  updateStartAngle?: (theta: number, phi: number) => void;
};

let cameraVector = new Vector3(0, 0, 0);

export const ViewportContent: FC<ViewportContentProps> = ({
  canvasRef,
  sources,
  currentPosition,
  lookingAt,
  layerGroups,
  layerColors,
  onModelRendered,
  updateStartAngle,
}) => {
  const { size } = useThree();
  const [isModelRendered, setIsModelRendered] = useState(false);

  const syncAudioWithCamera = (camera: Camera) => {
    cameraVector = camera.getWorldDirection(cameraVector);

    const theta = Math.atan2(cameraVector.y, cameraVector.x);
    const phi = Math.asin(cameraVector.z / 1);
    if (updateStartAngle) {
      updateStartAngle(theta, phi);
    }
  };

  const { updateCamera } = useFirstPersonCamera(canvasRef, syncAudioWithCamera, true, true);

  useEffect(() => {
    updateCamera(currentPosition, lookingAt);
  }, [currentPosition, lookingAt]);

  useEffect(() => {
    if (onModelRendered && isModelRendered) {
      onModelRendered();
    }
  }, [isModelRendered]);

  return (
    <>
      <hemisphereLight name="Default light" args={[0xffffff, 0xaaaaaa, 2.25]} position={[50, 100, 0]} />
      <Grid showAxes={false} />
      <group>
        <Model3D
          layerGroups={layerGroups}
          layerColors={layerColors}
          onRendered={
            !isModelRendered
              ? () => {
                  setIsModelRendered(true);
                }
              : undefined
          }
        />
        {isModelRendered &&
          sources.map((s, index) =>
            s.x !== undefined && s.y !== undefined && s.z !== undefined ? (
              <PointAuralizer
                key={s.id}
                id={s.id}
                index={index}
                type="SourcePoint"
                x={s.x!}
                y={s.y!}
                z={s.z!}
                canvasSize={size}
                originPoint={currentPosition}
              />
            ) : null
          )}
      </group>
    </>
  );
};
