import { createContext, ReactNode, useContext, useEffect, useReducer, useState } from 'react';
import * as THREE from 'three';
import { Vector3 } from 'three';

import { GridReceiver, Receiver, ResultsView, SelectedDetails, Source, View3DType } from './types';
import { Simulation, Source as SourceDTO } from '@/types';

type EditorProviderProps = { children: ReactNode };

enum ActionType {
  RESET_STATE = 'RESET_STATE',
  SET_SELECTED = 'SET_SELECTED',
  SET_FOCUS_ITEM = 'SET_FOCUS_ITEM',
  SET_MULTI_SELECTED = 'SET_MULTI_SELECTED',
  CLEAR_SELECTED = 'CLEAR_SELECTED',
  SET_SOURCES = 'SET_SOURCES',
  SET_RECEIVERS = 'SET_RECEIVERS',
  SET_GRID_RECEIVERS = 'SET_GRID_RECEIVERS',
  SET_IS_POPUP_OPEN = 'SET_IS_POPUP_OPEN',
  SET_IS_CREATE_MENU_OPEN = 'SET_IS_CREATE_MENU_UPEN',
  SET_IS_IN_AURALIZER_MODE = 'SET_IS_IN_AURALIZER_MODE',
  SET_IS_CAMERA_INSIDE_MODEL = 'SET_IS_CAMERA_INSIDE_MODEL',
  SET_IS_IN_RESULTS_MODE = 'SET_IS_IN_RESULTS_MODE',
  SET_RESULTS_VIEW = 'SET_RESULTS_VIEW',
  SHOW_EDIT_MODAL = 'SHOW_EDIT_MODAL',
  SET_AUTO_SAVE_TEXT = 'SET_AUTO_SAVE_TEXT',
  SET_RESULT_COMPARISONS = 'SET_RESULT_COMPARISONS',
  SET_HIDDEN_SURFACE_RECEIVERS = 'SET_HIDDEN_SURFACE_RECEIVERS',
  SET_3D_VIEW = 'SET_3D_VIEW',
  USER_SET_3D_VIEW = 'USER_SET_3D_VIEW',
  UPDATE_COORDINATES = 'UPDATE_COORDINATES',
  SET_SELECTED_AUR_SOURCE = 'SET_SELECTED_AUR_SOURCE',
  SET_TASK_TYPE = 'SET_TASK_TYPE',
  SET_READONLY = 'SET_READONLY',
}

type EditorContextAction =
  | {
      type: ActionType.RESET_STATE;
    }
  | {
      type: ActionType.SET_SELECTED;
      selected: SelectedDetails | null;
    }
  | {
      type: ActionType.SET_MULTI_SELECTED;
      multiSelected: SelectedDetails[];
    }
  | { type: ActionType.SET_FOCUS_ITEM; focusItem: string | null }
  | { type: ActionType.CLEAR_SELECTED }
  | { type: ActionType.SET_SOURCES; sources: Source[] }
  | { type: ActionType.SET_RECEIVERS; receivers: Receiver[] }
  | { type: ActionType.SET_GRID_RECEIVERS; gridReceivers: GridReceiver[] }
  | { type: ActionType.SET_IS_POPUP_OPEN; isOpen: boolean; popupName?: string }
  | { type: ActionType.SET_IS_CREATE_MENU_OPEN; isOpen: boolean }
  | { type: ActionType.SET_IS_IN_AURALIZER_MODE; isOpen: boolean }
  | { type: ActionType.SET_IS_CAMERA_INSIDE_MODEL; payload: boolean }
  | { type: ActionType.SET_IS_IN_RESULTS_MODE; payload: boolean }
  | { type: ActionType.SET_RESULTS_VIEW; payload: ResultsView }
  | { type: ActionType.SHOW_EDIT_MODAL; editSimulation: EditModal }
  | { type: ActionType.SET_AUTO_SAVE_TEXT; text: string }
  | { type: ActionType.SET_HIDDEN_SURFACE_RECEIVERS; hiddenSurfaceReceivers: GridReceiver[] }
  | { type: ActionType.SET_3D_VIEW; view3D: View3DType }
  | { type: ActionType.USER_SET_3D_VIEW; userSelectedView3D: View3DType }
  | { type: ActionType.UPDATE_COORDINATES; coordinates: [Vector3, Vector3] | null }
  | { type: ActionType.SET_TASK_TYPE; taskType: string }
  | { type: ActionType.SET_READONLY; readonly: boolean }
  | {
      type: ActionType.SET_SELECTED_AUR_SOURCE;
      source: SourceDTO | null;
      index: number | null;
    };

type EditModal = {
  showModal: boolean;
  updatedSimulation: Simulation | null;
  saveText?: string;
};

type State = {
  selected: SelectedDetails | null;
  multiSelected: SelectedDetails[];
  focusItem: string | null;
  isLoading: boolean;
  sources: Source[];
  receivers: Receiver[];
  gridReceivers: GridReceiver[];
  dispatch: (action: EditorContextAction) => void;
  isAuralizerOpen: boolean;
  isCameraInsideModel: boolean;
  isInResultsMode: boolean;
  resultsView: ResultsView;
  isPopupOpen: boolean;
  popupName: string;
  isCreateMenuOpen: boolean;
  editSimulation: EditModal;
  autoSaveText: string;
  hiddenSurfaceReceivers: GridReceiver[];
  view3D: View3DType;
  userSelectedView3D: View3DType;
  coordinates: [Vector3, Vector3] | null;
  selectedAurSource: { source: SourceDTO; index: number } | null;
  taskType: string;
  readonly: boolean;
};

const initialState: State = {
  selected: null,
  multiSelected: [],
  focusItem: null,
  isLoading: false,
  sources: [],
  receivers: [],
  gridReceivers: [],
  dispatch: () => {},
  isAuralizerOpen: false,
  isCameraInsideModel: false,
  isInResultsMode: false,
  resultsView: ResultsView.ResultsModelView,
  isPopupOpen: false,
  popupName: '',
  isCreateMenuOpen: false,
  editSimulation: {
    showModal: false,
    updatedSimulation: null,
    saveText: '',
  },
  autoSaveText: '',
  hiddenSurfaceReceivers: [],
  view3D: 'ghosted',
  userSelectedView3D: 'ghosted',
  coordinates: null,
  selectedAurSource: null,
  taskType: '',
  readonly: false,
};

const EditorContext = createContext(initialState);

function handleUnknownAction(action: never): never;
function handleUnknownAction(action: EditorContextAction) {
  throw new Error(`Unhandled action type: ${action.type}`);
}

const editorReducer = (state: State, action: EditorContextAction): State => {
  switch (action.type) {
    case ActionType.RESET_STATE: {
      return {
        ...initialState,
      };
    }
    case ActionType.SET_IS_POPUP_OPEN: {
      return {
        ...state,
        isPopupOpen: action.isOpen,
        popupName: action.popupName || '',
      };
    }
    case ActionType.SET_IS_CREATE_MENU_OPEN: {
      return {
        ...state,
        isCreateMenuOpen: action.isOpen,
      };
    }
    case ActionType.SET_IS_IN_AURALIZER_MODE: {
      return {
        ...state,
        isAuralizerOpen: action.isOpen,
      };
    }

    case ActionType.SET_IS_CAMERA_INSIDE_MODEL: {
      return {
        ...state,
        isCameraInsideModel: action.payload,
        view3D: action.payload ? 'shaded' : state.userSelectedView3D,
      };
    }
    case ActionType.SET_IS_IN_RESULTS_MODE: {
      return {
        ...state,
        isInResultsMode: action.payload,
      };
    }
    case ActionType.SET_RESULTS_VIEW: {
      return {
        ...state,
        resultsView: action.payload,
      };
    }
    case ActionType.SET_SOURCES: {
      return {
        ...state,
        sources: action.sources,
      };
    }
    case ActionType.SET_RECEIVERS: {
      return {
        ...state,
        receivers: action.receivers,
      };
    }
    case ActionType.SET_GRID_RECEIVERS: {
      return {
        ...state,
        gridReceivers: action.gridReceivers,
      };
    }
    case ActionType.SET_SELECTED: {
      return {
        ...state,
        selected: action.selected,
        multiSelected: [],
        focusItem: null,
      };
    }
    case ActionType.SET_MULTI_SELECTED: {
      return {
        ...state,
        multiSelected: action.multiSelected,
        selected: null,
      };
    }

    case ActionType.SET_FOCUS_ITEM: {
      return {
        ...state,
        focusItem: action.focusItem,
      };
    }

    case ActionType.CLEAR_SELECTED: {
      return {
        ...state,
        selected: initialState.selected,
        multiSelected: initialState.multiSelected,
        focusItem: initialState.focusItem,
      };
    }

    case ActionType.SHOW_EDIT_MODAL: {
      return {
        ...state,
        editSimulation: { ...action.editSimulation },
      };
    }

    case ActionType.SET_AUTO_SAVE_TEXT: {
      return {
        ...state,
        autoSaveText: action.text,
      };
    }

    case ActionType.SET_HIDDEN_SURFACE_RECEIVERS: {
      return {
        ...state,
        hiddenSurfaceReceivers: action.hiddenSurfaceReceivers,
      };
    }

    case ActionType.SET_3D_VIEW: {
      return {
        ...state,
        view3D: action.view3D,
      };
    }

    case ActionType.USER_SET_3D_VIEW: {
      return {
        ...state,
        view3D: action.userSelectedView3D,
        userSelectedView3D: action.userSelectedView3D,
      };
    }

    case ActionType.UPDATE_COORDINATES: {
      return {
        ...state,
        coordinates: action.coordinates,
      };
    }

    case ActionType.SET_SELECTED_AUR_SOURCE: {
      return {
        ...state,
        selectedAurSource:
          action.source && action.index !== null ? { source: action.source, index: action.index } : null,
      };
    }

    case ActionType.SET_TASK_TYPE: {
      return {
        ...state,
        taskType: action.taskType,
      };
    }

    case ActionType.SET_READONLY: {
      return {
        ...state,
        readonly: action.readonly,
      };
    }

    default: {
      handleUnknownAction(action);
    }
  }
};

const EditorProvider = ({ children }: EditorProviderProps) => {
  const [editorState, dispatch] = useReducer(editorReducer, initialState);
  const [isLoading, setIsLoading] = useState(true);

  THREE.Object3D.DEFAULT_UP.set(0, 0, 1);

  const setup = async () => {
    setIsLoading(true);
    setIsLoading(false);
  };

  useEffect(() => {
    setup();
  }, []);

  useEffect(() => {
    dispatch({
      type: ActionType.SET_READONLY,
      readonly: editorState.isAuralizerOpen || editorState.isInResultsMode,
    });
  }, [editorState.isAuralizerOpen, editorState.isInResultsMode]);

  const value = { ...editorState, isLoading, dispatch };

  return <EditorContext.Provider value={value}>{children}</EditorContext.Provider>;
};

const useEditorContext = () => {
  const context = useContext(EditorContext);
  if (context === undefined) {
    throw new Error('useEditorContext must be used within EditorProvider');
  }
  return context;
};

export { ActionType, EditorProvider, useEditorContext };
