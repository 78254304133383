import { Frequency } from './types';

export const FREQUENCY_RANGE_FULL_OCTAVE = ['63', '125', '250', '500', '1k', '2k', '4k', '8k'];
export const FULL_OCTAVES: Frequency[] = [63, 125, 250, 500, 1000, 2000, 4000, 8000];
export const DEFAULT_SCATTERING = 0.3;

export const FREQUENCY_RANGE_THIRD_OCTAVE_NUM = [
  50, 63, 80, 100, 125, 160, 200, 250, 315, 400, 500, 630, 800, 1000, 1250, 1600, 2000, 2500, 3150, 4000, 5000, 6300,
  8000, 10000,
];

export const FREQUENCY_RANGE_THIRD_OCTAVE_NUM_SOLVI = [
  50, 63, 80, 100, 125, 160, 200, 250, 320, 400, 500, 640, 800, 1000, 1250, 1600, 2000, 2500, 3200, 4000, 5000, 6400,
  8000, 10000,
];

export const FREQUENCY_RANGE_THIRD_OCTAVE_SOLVI = [
  '50',
  '63',
  '80',
  '100',
  '125',
  '160',
  '200',
  '250',
  '320',
  '400',
  '500',
  '640',
  '800',
  '1k',
  '1.25k',
  '1.6k',
  '2k',
  '2.5k',
  '3.2k',
  '4k',
  '5k',
  '6.4k',
  '8k',
  '10k',
];

export const FREQUENCY_RANGE_THIRD_OCTAVE = [
  '50',
  '63',
  '80',
  '100',
  '125',
  '160',
  '200',
  '250',
  '315',
  '400',
  '500',
  '630',
  '800',
  '1k',
  '1.25k',
  '1.6k',
  '2k',
  '2.5k',
  '3.15k',
  '4k',
  '5k',
  '6.3k',
  '8k',
  '10k',
];

export const INITIAL_VALUES_FULL_OCTAVE: { [freq in Frequency]: number } = {
  63: 0.1,
  125: 0.1,
  250: 0.1,
  500: 0.1,
  1000: 0.1,
  2000: 0.1,
  4000: 0.1,
  8000: 0.1,
};

export const EXAMPLE_INPUT_FULL_OCTAVE = `63        0.1
125       0.1
250       0.1
500       0.1
1000      0.1
2000      0.1
4000      0.1
8000      0.1`;

export const FORM_INITIAL = { name: '', category: '', description: '', defaultScattering: DEFAULT_SCATTERING };

export const VALID_CSV_TYPES = [
  'text/csv',
  'application/csv',
  'application/excel',
  'application/vnd.ms-excel',
  'application/vnd.msexcel',
];

export const VALID_FILE_TYPES = [
  'text/csv',
  'application/csv',
  'application/excel',
  'application/vnd.ms-excel',
  'application/vnd.msexcel',
  'text/plain',
];

export const PLOT_HEIGHT = 270;
export const PLOT_WIDTH = 450;

export const PLOT_HEIGHT_SMALL = 210;
