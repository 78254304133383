import { ReactElement, ReactNode } from 'react';

import { NameMarkerRow } from '../Shared/NameMarker';
import { Breadcrumbs } from './Breadcrumbs';

import { UserBasicDto } from '@/types';

import './styles.scss';

type Breadcrumb = {
  text: string | ReactNode;
  to?: string;
  icon?: ReactElement;
};

export const ProjectBreadcrumbs = ({
  items,
  projectUsers,
  loggedInUserId,
  creatorId,
  background,
  action,
}: {
  items: Breadcrumb[];
  projectUsers: UserBasicDto[];
  loggedInUserId?: string;
  creatorId: string;
  background?: string;
  action?: ReactElement;
}) => {
  return (
    <div className="project-breadcrumbs">
      <Breadcrumbs items={items} />
      {action !== undefined && action}
      {projectUsers.length > 1 && (
        <NameMarkerRow
          projectUsers={projectUsers}
          loggedInUserId={loggedInUserId}
          creatorId={creatorId}
          size="medium"
          background={background}
        />
      )}
    </div>
  );
};
