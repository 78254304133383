import { toast } from 'react-toastify';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import axios from '@/axios';

type DeleteUserRequest = {
  userId: string;
  organizationId: string;
};

const deleteUser = async (organizationId: string, userId: string) => {
  await axios.patch(`/api/Organization/RemoveUserFromOrganization`, null, {
    params: {
      organizationId,
      userId,
    },
  });

  const response = await axios.delete(`/api/Admin/DeleteUser`, {
    params: {
      id: userId,
    },
  });

  return response;
};

export const useDeleteUser = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async (variables: DeleteUserRequest) => await deleteUser(variables.organizationId, variables.userId),
    {
      onSuccess: (_, variables) => {
        queryClient.invalidateQueries(['subscription', variables.organizationId]);
        queryClient.invalidateQueries(['product-accesses', variables.organizationId]);
      },
      onError: () => {
        toast.error('An error occurred while deleting the user!');
      },
    }
  );
};
