import { ReactElement } from 'react';

import { Dialog, DialogActions, DialogContent, DialogProps, DialogTitle, IconButton, styled } from '@mui/material';

import { TrblCloseIcon } from '@/components/Icons';

import styles from './styles.module.scss';

interface TrblPopupProps extends DialogProps {
  width?: number | string;
  height?: number | string;
  minheight?: number | string;
  maxheight?: number | string;
  minwidth?: number | string;
  maxwidth?: number | string;
  boxShadow?: string;
}

export const TrblPopup = styled(Dialog)<TrblPopupProps>(
  ({ width, height, minheight, maxheight, minwidth, maxwidth, boxShadow }) => ({
    '& .MuiPaper-root': {
      backgroundColor: 'transparent',
      backgroundImage: 'none',
      fontSize: '12px',
      width: width || '400px',
      height: height || 'initial',
      minHeight: minheight || 'initial',
      maxHeight: maxheight || 'initial',
      minWidth: minwidth || 'initial',
      maxWidth: maxwidth || 'none',
      borderRadius: '4px',
      boxShadow: boxShadow || '0px 6px 20px rgb(0 0 0 / 50%)',
    },
    '.MuiIconButton-root.Mui-disabled': {
      opacity: '0.5',
    },
  })
);

type TrblPopupTitleProps = {
  children?: React.ReactNode;
  onClose?: () => void;
  disabled?: boolean | false;
  backgroundColor?: string;
  closeIcon?: ReactElement | null;
};

export const TrblPopupTitle = ({
  children,
  onClose,
  disabled,
  backgroundColor,
  closeIcon = <TrblCloseIcon />,
}: TrblPopupTitleProps) => {
  return (
    <DialogTitle className={styles['popup-title']} style={{ backgroundColor }}>
      {children}
      {onClose ? (
        <IconButton
          disabled={disabled}
          className={styles['popup-close']}
          aria-label="close"
          onClick={(e: { stopPropagation: () => void }) => {
            e.stopPropagation();
            onClose();
          }}>
          {closeIcon ?? <TrblCloseIcon />}
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

type TrblPopupContentProps = {
  children?: React.ReactNode;
  minimal?: boolean;
  style?: React.CSSProperties;
};

export const TrblPopupContent = (props: TrblPopupContentProps) => {
  return (
    <DialogContent
      className={`${props.minimal ? styles['popup-content-minimal'] : styles['popup-content']}  `}
      style={props.style}>
      {props.children}
    </DialogContent>
  );
};

type TrblPopupActionsProps = {
  children?: React.ReactNode;
  framed?: boolean;
};

export const TrblPopupActions = (props: TrblPopupActionsProps) => {
  return (
    <DialogActions className={`${styles['popup-actions']} ${props.framed ? styles['framed'] : ''}`}>
      {props.children}
    </DialogActions>
  );
};
