import { TrblEditIcon, TrblUploadIcon } from '@/components/Icons';

import styles from '../styles.module.scss';

type UploadFileToggleProps = {
  isToggled: boolean;
  toggle: React.Dispatch<React.SetStateAction<boolean>>;
  disabled: boolean | undefined;
};

export const UploadFileToggle: React.FC<UploadFileToggleProps> = ({ isToggled, toggle, disabled }) => {
  return (
    <button
      disabled={disabled}
      className={styles.upload_button}
      onClick={(event) => {
        event.preventDefault();
        toggle(!isToggled);
      }}>
      {isToggled ? <TrblEditIcon height="10" width="10" /> : <TrblUploadIcon height="10" width="10" />}
      {isToggled ? 'Input text' : 'Upload file'}
    </button>
  );
};
