import { FC, useEffect, useState } from 'react';

import { useEditorContext } from '@/context/EditorContext';

import { Box } from '@mui/material';

import { EMPTY_GUID } from '@/components/Shared/constants';
import { TrblPopup, TrblPopupActions, TrblPopupContent, TrblPopupTitle } from '@/components/Shared/Popup';
import { ActionType, useLibraryPanelContext } from '@/components/LibraryPanel/LibraryPanelContext';
import { FrequenciesTable } from '@/components/SourceDefinition/FrequenciesTable';
import { FrequenciesTableInput } from '@/components/SourceDefinition/FrequenciesTableInput';
import { SourceDefinitionFooter } from '@/components/SourceDefinition/SourceDefinitionFooter';
import { SourceDefinitionPlots } from '@/components/SourceDefinition/SourceDefinitionPlots';
import { SourceDefinitionWarning } from '@/components/SourceDefinition/SourceDefinitionWarning';
import { TableDivider } from '@/components/SourceDefinition/TableDivider';
import { Marker } from '../Marker';
import { SourceDefinitionInfo } from './SourceDefinitionInfo';

import { Source } from '@/context/EditorContext/types';
import { SourceDefinition } from '@/types';

import styles from './styles.module.scss';

type SourceSettingsProps = {
  source: Source;
  index: number;
  sourceDefinitions: SourceDefinition[];
  onOverallGainChange: (value: number) => void;
  onEQChange: (value: number[]) => void;
  onCorrectIrChange: (value: boolean) => void;
  onUseSplForStiChange: (value: boolean) => void;
  onClose: () => void;
  readonly?: boolean;
};

export const SourceSettingsPopup: FC<SourceSettingsProps> = ({
  source,
  index,
  sourceDefinitions,
  onOverallGainChange,
  onEQChange,
  onCorrectIrChange,
  onUseSplForStiChange,
  onClose,
  readonly = false,
}) => {
  const { sources } = useEditorContext();
  const { dispatch, selectedSourceDefinition } = useLibraryPanelContext();
  const [thisSource, setThisSource] = useState(source);
  const [sourceDefinition, setSourceDefinition] = useState<SourceDefinition | undefined>(undefined);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [sourceDefinitionJson, setSourceDefinitionJson] = useState<any>(null);
  const [eqData, setEqData] = useState<number[] | null>(null);
  const [showDropdown, setShowDropdown] = useState(false);

  const downloadAndSetJson = async (url: string) => {
    fetch(url, {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (myJson) {
        setSourceDefinitionJson(myJson);
      });
  };

  useEffect(() => {
    if (sourceDefinition) {
      downloadAndSetJson(sourceDefinition.directivity2dJsonDownloadUrl);
    }
  }, [sourceDefinition]);

  useEffect(() => {
    if (sources.length) {
      setThisSource(sources[index]);
      if (
        thisSource.params.directivityPattern !== sources[index].params.directivityPattern &&
        selectedSourceDefinition
      ) {
        setSourceDefinition(selectedSourceDefinition);
        setShowDropdown(false);
      }
    }
  }, [sources]);

  useEffect(() => {
    let thisSourceDefinition = undefined;
    // for backwards compatability, if a source is EMPTY_GUID, then it is a Omni
    if (source.params.directivityPattern === EMPTY_GUID) {
      thisSourceDefinition = sourceDefinitions.find((sd) => sd.name === 'Omni');
    } else {
      thisSourceDefinition = sourceDefinitions.find((sd) => sd.id === source.params.directivityPattern);
    }
    setSourceDefinition(thisSourceDefinition);
    dispatch({
      type: ActionType.SET_SELECTED_SOURCE,
      source: source,
      sourceDefinition: thisSourceDefinition ?? null,
    });
  }, []);

  return (
    <TrblPopup aria-labelledby={'Source settings'} width={1120} minheight={458} open={true} onClose={onClose}>
      <TrblPopupTitle onClose={onClose}>
        <div className={styles['marker-title']}>
          Source settings:
          <Marker color="green" label={(index + 1).toString()} small />
          <p>{source.label || `Source ${index + 1}`}</p>
        </div>
      </TrblPopupTitle>
      <TrblPopupContent>
        {sourceDefinition && sourceDefinitionJson && (
          <Box component={'div'} display={'inline-flex'} width={'100%'}>
            <div className={styles['box-left']}>
              <div>
                <SourceDefinitionInfo
                  sd={sourceDefinition}
                  showDropdown={showDropdown}
                  setShowDropdown={setShowDropdown}
                  readonly={readonly}
                />
                <TableDivider />
                <FrequenciesTable sourceDefinitionJson={sourceDefinitionJson} />

                <TableDivider></TableDivider>
                <FrequenciesTableInput
                  source={thisSource}
                  sourceDefinitionJson={sourceDefinitionJson}
                  onOverallGainChange={onOverallGainChange}
                  onEQChange={onEQChange}
                  setEqData={setEqData}
                  readonly={readonly}
                />
              </div>
              <SourceDefinitionFooter
                source={thisSource}
                onCorrectIrChange={onCorrectIrChange}
                onUseSplForStiChange={onUseSplForStiChange}
                readonly={readonly}
              />
            </div>
            <div className={styles['box-right']}>
              <SourceDefinitionPlots sourceDefinitionJson={sourceDefinitionJson} eqData={eqData} />
            </div>
          </Box>
        )}
      </TrblPopupContent>

      <TrblPopupActions framed>
        <SourceDefinitionWarning />
      </TrblPopupActions>
    </TrblPopup>
  );
};
