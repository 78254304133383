import audioWorkerUrl from '@/components/Auralizer/AudioWorker.js?url';
import { deinterleaveBuffers } from './deinterleaveBuffers';

export const spawnWorker = async (arrayBuffer: ArrayBuffer) => {
  const start = new Date().getTime();
  return new Promise((resolve) => {
    if (window.Worker) {
      const worker = new Worker(audioWorkerUrl);

      if (arrayBuffer.byteLength === 0) {
        worker.postMessage(arrayBuffer);
      } else {
        // Transferables are supported.
        worker.postMessage(arrayBuffer, [arrayBuffer]);
      }

      worker.onmessage = (event) => {
        resolve(event.data);
        worker.terminate();
        const end = new Date().getTime() - start;
        console.log('[Auralization] worker time', end, 'ms');
      };
      worker.onerror = (error) => {
        throw error;
      };
    } else {
      resolve(deinterleaveBuffers(arrayBuffer));
    }
  });
};
