import { useEffect, useState } from 'react';

import { ActionType, useEditorContext } from '@/context/EditorContext';
import { useModelContext } from '@/context/ModelContext';
import { useSimulationContext } from '@/context/SimulationContext';

import { TrblSelect } from '@/components/Shared/TrblSelect';
import { useFeatureFlags } from '@/components/FeatureToggles';
import { ResultsContainer } from '../Results/ResultsContainer';
import { ResultsComparisonsPanel } from '../ResultsComparisonPanel';
import { ResultsSubHeader } from '../ResultsSubHeader';
import { Viewport } from '../Viewport/Viewport';

import { VIEW_3D_OPTIONS } from './constants';

import { showInvalidSourcesInfo } from './utils';

import { ResultsView, View3DType } from '@/context/EditorContext/types';

import styles from './styles.module.scss';

export const EditorContent = ({ showResults }: { showResults: boolean }) => {
  const { resultsView, view3D, userSelectedView3D, isCameraInsideModel, sources, taskType, dispatch } =
    useEditorContext();
  const {
    simulationState: { selectedSimulation, originalSim },
  } = useSimulationContext();
  const { modelInformation } = useModelContext();
  const [prevTaskType, setPrevTaskType] = useState('');
  const [prevSimulation, setPrevSimulation] = useState('');
  const { sourceProximityGAFeature } = useFeatureFlags();

  const handle3DViewChanged = (selectedTab: string) => {
    dispatch({
      type: ActionType.USER_SET_3D_VIEW,
      userSelectedView3D: selectedTab as View3DType,
    });
  };

  useEffect(() => {
    // change the model-3D-view to wireframe when opening the
    // GridReceiversView or ReflectogramView so the heatmap / reflections are properly shown
    if (resultsView == ResultsView.ResultsGridReceiversView || resultsView == ResultsView.ResultsReflectogramView) {
      if (view3D !== 'wireframe') {
        dispatch({
          type: ActionType.SET_3D_VIEW,
          view3D: 'wireframe',
        });
      }
      // change the model-3D-view to whatever the user had previously selected, if not already selected
    } else if (view3D !== userSelectedView3D) {
      dispatch({
        type: ActionType.SET_3D_VIEW,
        view3D: userSelectedView3D,
      });
    }
  }, [resultsView]);

  useEffect(() => {
    if (sourceProximityGAFeature) {
      // If it's the same simulation and previous tasktype was GA, then trigger showInvalidSourcesInfo()
      if (taskType !== 'GA' && prevTaskType == 'GA' && prevSimulation == selectedSimulation?.id) {
        showInvalidSourcesInfo(sources);
      }
      setPrevTaskType(taskType);
      setPrevSimulation(selectedSimulation?.id || '');
    }
  }, [taskType]);

  const showSubHeader =
    showResults &&
    (resultsView === ResultsView.ResultsReflectogramView || resultsView === ResultsView.ResultsGridReceiversView);

  return (
    <div className={`${styles['editor-main']} ${showResults ? styles['results-mode'] : ''}`}>
      {showSubHeader && <ResultsSubHeader resultsView={resultsView} />}
      <div className={styles['content-and-footer-panel']}>
        <div
          className={styles['content']}
          style={{
            gap:
              resultsView !== ResultsView.ResultsModelView && resultsView !== ResultsView.ResultsReflectogramView
                ? 0
                : undefined,
          }}>
          {showResults && <ResultsContainer view={resultsView} />}
          {/*  We keep the viewport rendered since we don't want to canvas to initialize again when switching views */}
          <div
            style={{
              visibility: resultsView === ResultsView.ResultsReportView ? 'hidden' : undefined,
              flex: resultsView === ResultsView.ResultsReportView ? '0 1 0' : undefined,
            }}
            className={styles['viewport-container']}>
            <div
              className={`${styles['change-3d-tabs']} ${
                showResults && resultsView === ResultsView.ResultsGridReceiversView ? styles['grid-receivers'] : ''
              }`}>
              {!isCameraInsideModel && (
                <TrblSelect
                  menuItems={VIEW_3D_OPTIONS}
                  value={view3D}
                  setValue={handle3DViewChanged}
                  minimal
                  style={{
                    height: '24px',
                    fontSize: '12px',
                    backgroundColor: 'transparent',
                  }}
                />
              )}
            </div>
            <Viewport />
          </div>
        </div>
        {showResults && modelInformation && originalSim && selectedSimulation && (
          <ResultsComparisonsPanel
            key={originalSim.id}
            originalModelInformation={modelInformation}
            selectedSimulation={selectedSimulation}
          />
        )}
      </div>
    </div>
  );
};
