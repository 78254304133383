import { useEffect, useMemo, useRef, useState } from 'react';
import { Size, ThreeEvent, useThree } from '@react-three/fiber';
import { Group, Sprite, SpriteMaterial, TextureLoader } from 'three';
import SpriteText from 'three-spritetext';

import { ActionType, useResultsContext } from '@/components/Results/context/ResultsContext';
import { useEditorContext } from '@/context/EditorContext';

import targetImageUrl from '../images/target.png';
import { ColorScale } from './ColorScale';
import { Heatmap } from './Heatmap';

import { useObjectClickHandler } from '../../../hooks';

// @ts-expect-error Could not find a declaration file for module '@/utils/ColorBar'.
import { ColorBar } from '@/utils/ColorBar';
import { getParameterDecimals, roundFloat } from '@/utils/trebleFunctions';

import { ResultsView } from '@/context/EditorContext/types';
import { GridReceiver } from '@/types';

const targetTexture = new TextureLoader().load(targetImageUrl);
let pressureTextArray: SpriteText[] = [];
let parameterDecimals = 1;

export const GridReceiverResults = ({
  isExporting,
  setIsExporting,
}: {
  isExporting: boolean;
  setIsExporting: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const pressureGroup = useRef<any>(null);
  const { resultsView, hiddenSurfaceReceivers } = useEditorContext();
  const {
    selectedComparisonIndex,
    availableComparisons,
    surfaceReceiversIsInterpolated: isInterpolated,
    surfaceReceiversSelectedParameter: selectedParameter,
    surfaceReceiversSelectedFrequency: selectedFrequency,
    surfaceReceiversSelectedNcCurve: selectedNcCurve,
    targetValue,
    targetValueMax,
    targetType,
    dispatch,
  } = useResultsContext();

  const [customMin, setCustomMin] = useState<number | null>(null);
  const [customMax, setCustomMax] = useState<number | null>(null);

  // We reset the custom min and max if we are changing paremter, frequency, selected comparison or grid receivers
  const selectedComparison = availableComparisons[selectedComparisonIndex];
  const gridReceiverResultsToDisplay = selectedComparison.formState?.simulationData?.selectedGridReceiverObjects || [];

  const { size, invalidate } = useThree();

  useEffect(() => {
    setCustomMin(null);
    setCustomMax(null);

    parameterDecimals = getParameterDecimals(selectedParameter!);

    if (pressureTextArray.length) updatePressureTexts(pressureTextArray);
  }, [selectedParameter]);

  useEffect(() => {
    if (pressureTextArray.length) updatePressureTexts(pressureTextArray);
  }, [selectedFrequency, gridReceiverResultsToDisplay]);

  const parameterValuesForSelectedSource = useMemo(() => {
    const allParameterValues = selectedComparison.formState?.simulationData?.gridReceiverParameterValues;
    const selectedSourcePointId = selectedComparison.formState?.sourcePointId;

    if (
      allParameterValues &&
      Object.keys(allParameterValues).length &&
      selectedSourcePointId &&
      allParameterValues[selectedSourcePointId]
    ) {
      return allParameterValues[selectedSourcePointId];
    }

    return null;
  }, [
    selectedComparison.formState?.simulationData?.gridReceiverParameterValues,
    selectedComparison.formState?.sourcePointId,
  ]);

  const colorBar = useMemo(() => {
    if (parameterValuesForSelectedSource && selectedParameter) {
      if (selectedParameter !== 'sti' && selectedParameter !== 'd50' && selectedFrequency) {
        const colorBar = new ColorBar();

        let min: number | null = null;
        let max: number | null = null;

        const decimals = getParameterDecimals(selectedParameter);
        const visibleGridReceiverIds = gridReceiverResultsToDisplay.map((x) => x.pointId);
        Object.keys(parameterValuesForSelectedSource)
          .filter((x) => visibleGridReceiverIds.includes(x))
          .forEach((gridReceiverId: string) => {
            const valuesChosen: number[] =
              parameterValuesForSelectedSource[gridReceiverId][selectedParameter][selectedFrequency];

            // Filter out non finite numbers (NaN, Infinity) and values outside interval -150 to 150
            const validValuesChosen = valuesChosen
              .filter((n: number) => Math.abs(n) < 150 && isFinite(n))
              .map((value) => roundFloat(value, decimals));
            const minValue = Math.min(...validValuesChosen);
            const maxValue = Math.max(...validValuesChosen);

            if (min === null || minValue < min) {
              min = minValue;
            }
            if (max === null || maxValue > max) {
              max = maxValue;
            }
          });

        colorBar.setMin(customMin !== null ? customMin : min);
        colorBar.setMax(customMax !== null ? customMax : max);

        return colorBar;
      } else if (selectedParameter === 'sti' || selectedParameter === 'd50') {
        const colorBar = selectedParameter === 'sti' ? new ColorBar('sti') : new ColorBar();
        colorBar.setMin(0);
        colorBar.setMax(1);
        return colorBar;
      }
    }

    return null;
  }, [
    customMin,
    customMax,
    parameterValuesForSelectedSource,
    gridReceiverResultsToDisplay,
    selectedFrequency,
    selectedParameter,
  ]);

  const targetValueColorBar = useMemo(() => {
    if (targetValue !== undefined && parameterValuesForSelectedSource && selectedParameter && selectedFrequency) {
      const colorBar = new ColorBar('targetValue');
      const visibleGridReceiverIds = gridReceiverResultsToDisplay.map((x) => x.pointId);
      let min: number | null = null;
      let max: number | null = null;
      let offset = 0;
      let validValuesChosen: number[] = [];
      Object.keys(parameterValuesForSelectedSource)
        .filter((x) => visibleGridReceiverIds.includes(x))
        .forEach((gridReceiverId: string) => {
          const valuesChosen: number[] =
            parameterValuesForSelectedSource[gridReceiverId][selectedParameter][
              selectedParameter !== 'sti' ? selectedFrequency : selectedNcCurve!
            ];

          // Filter out non finite numbers (NaN, Infinity) and values outside interval -150 to 150
          validValuesChosen = valuesChosen
            .filter((n: number) => Math.abs(n) < 150 && isFinite(n))
            .map((value) => roundFloat(value, parameterDecimals));

          const minValue = Math.min(...validValuesChosen);
          const maxValue = Math.max(...validValuesChosen);

          if (min === null || minValue < min) {
            min = minValue;
          }
          if (max === null || maxValue > max) {
            max = maxValue;
          }
          offset = Math.abs(max - min) / 1000;
        });

      if (targetValueMax !== undefined && targetType === 'Range') {
        colorBar.setMin(targetValue);
        colorBar.setMax(targetValueMax);
      } else if (targetType === 'Above' || targetType === 'Range') {
        colorBar.setMin(targetValue);
        colorBar.setMax(max);
      } else {
        colorBar.setMin(min);
        colorBar.setMax(targetValue + offset);
      }

      // calculate percentage
      const valuesWithinTarget = validValuesChosen.filter(
        (value) => value <= colorBar.getMax() && value >= colorBar.getMin()
      );
      const targetPercentage = (valuesWithinTarget.length / validValuesChosen.length) * 100;

      dispatch({
        type: ActionType.SET_TARGET_PERCENTAGE,
        payload: roundFloat(targetPercentage, 1),
      });

      return colorBar;
    }

    return null;
  }, [targetValue, targetValueMax, targetType, colorBar]);

  const updatePressureTexts = (textArray: SpriteText[]) => {
    if (!parameterValuesForSelectedSource || !selectedParameter || !selectedFrequency) return;

    for (let i = textArray.length - 1; i >= 0; i--) {
      let gridVisible = false;
      const pressureText = textArray[i];
      //check if grid receiver is selected in the result comparison multiselect
      gridReceiverResultsToDisplay.forEach((visibleGrid) => {
        if (visibleGrid.pointId == pressureText.userData.pointId) {
          gridVisible = true;
        }
      });

      if (gridVisible) {
        // select the correct array of values from list of grid receiver result
        const pointsArray =
          parameterValuesForSelectedSource[pressureText.userData.pointId][selectedParameter][
            selectedParameter !== 'sti' ? selectedFrequency : selectedNcCurve!
          ];

        let textUpdated = '';

        if (pointsArray) {
          let pointValue;
          // update all pressure texts with new values from correct array
          for (let k = 0; k < pointsArray.length; k++) {
            pointValue = pointsArray[k];

            if (k == pressureText.userData.index) {
              //check if non finite numbers (NaN, Infinity) and values outside interval -150 to 150
              if (Math.abs(pointValue) < 150 && isFinite(pointValue)) {
                textUpdated = roundFloat(pointValue, parameterDecimals).toString();
              }
            }
          }
        }

        pressureText.text = textUpdated;
        pressureText.scale.set(pressureText.scale.x / size.height, pressureText.scale.y / size.height, 0);
      } else {
        textArray.splice(i, 1);
        pressureGroup.current.children.splice(i, 1);
      }
    }

    // Re-render canvas
    invalidate();
  };

  const clickHandlerProps = useObjectClickHandler((event: ThreeEvent<PointerEvent>) => {
    const square = event.object;

    if (!event.ctrlKey) {
      pressureGroup.current.children = [];
      pressureTextArray = [];
    }
    const valueText = roundFloat(square.userData.pressure, parameterDecimals).toString();
    const pressureText = makePressureText(valueText, size, [square.position.x, square.position.y, square.position.z]);
    pressureText.userData.index = square.userData.index;
    pressureText.userData.pointId = square.parent?.userData.pointId;
    const cursorTarget = makeCursorTarget(size, [square.position.x, square.position.y, square.position.z]);

    const textAndTarget = new Group();
    textAndTarget.add(pressureText);
    textAndTarget.add(cursorTarget);

    pressureGroup.current.add(textAndTarget);
    pressureTextArray.push(pressureText);

    // Re-render canvas
    invalidate();
  }, true);

  const handleOutsideClick = () => {
    pressureGroup.current.children = [];
    pressureTextArray = [];
    // Re-render canvas
    invalidate();
  };

  return colorBar ? (
    <>
      <group {...clickHandlerProps} onPointerMissed={handleOutsideClick} name="heatmaps">
        {gridReceiverResultsToDisplay.map((result) => (
          <Heatmap
            key={`${result.id}`}
            isExporting={isExporting}
            result={result}
            colorBar={targetValueColorBar ?? colorBar}
            valuesChosen={
              parameterValuesForSelectedSource![result.pointId][selectedParameter!][
                selectedParameter !== 'sti' ? selectedFrequency! : selectedNcCurve!
              ] || []
            }
            isInterpolated={isInterpolated}
            isVisible={
              resultsView === ResultsView.ResultsGridReceiversView &&
              !hiddenSurfaceReceivers.find((surface: GridReceiver) => surface.id === result.pointId)
            }
            decimals={getParameterDecimals(selectedParameter!)}
            targetValue={targetValueColorBar}
          />
        ))}
      </group>

      <group ref={pressureGroup} renderOrder={20}></group>

      {gridReceiverResultsToDisplay.length && selectedFrequency && selectedParameter && selectedNcCurve && (
        <ColorScale
          isExporting={isExporting}
          setIsExporting={setIsExporting}
          colorBar={colorBar}
          visible={resultsView === ResultsView.ResultsGridReceiversView}
          selectedFrequency={selectedFrequency}
          selectedParameter={selectedParameter}
          selectedNcCurve={selectedNcCurve}
          onCustomMaxChange={setCustomMax}
          onCustomMinChange={setCustomMin}
          disabled={selectedParameter === 'sti' || selectedParameter === 'd50'}
        />
      )}
    </>
  ) : null;
};

const targetMaterial = new SpriteMaterial({ map: targetTexture });
targetMaterial.transparent = true;
targetMaterial.alphaTest = 0.1;
targetMaterial.sizeAttenuation = false;
targetMaterial.depthTest = false;
targetMaterial.depthWrite = false;
targetMaterial.color.set(0xcccccc);

const makePressureText = (text: string, size: Size, pos: number[]) => {
  const myText = new SpriteText(text, 9.5);
  myText.position.set(pos[0], pos[1], pos[2]);
  myText.fontFace = '"Inter", Helvetica, Arial';
  myText.fontWeight = '500';
  myText.fontSize = 24;
  myText.color = '#ffffff';
  myText.center.set(-0.325, 0.515);
  myText.backgroundColor = '#282828';
  myText.borderColor = '#444444';
  myText.borderWidth = 0.5;
  myText.borderRadius = 4;
  myText.padding = 3;

  myText.scale.set(myText.scale.x / size.height, myText.scale.y / size.height, 0);
  myText.material.alphaTest = 0.15;
  myText.material.sizeAttenuation = false;
  myText.material.color.set(0xaaaaaa);
  myText.material.depthTest = false;
  myText.material.depthWrite = false;
  myText.renderOrder = 20;

  return myText;
};

const makeCursorTarget = (size: Size, pos: number[]) => {
  const sprite = new Sprite(targetMaterial);
  sprite.position.set(pos[0], pos[1], pos[2]);
  sprite.renderOrder = 20;
  sprite.scale.set(16, 16, 1);
  sprite.scale.set(sprite.scale.x / size.height, sprite.scale.y / size.height, 0);
  return sprite;
};
