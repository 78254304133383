import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { useModelContext } from '@/context/ModelContext';
import { useSimulationContext } from '@/context/SimulationContext';

import { AudioEngine } from '@/components/Auralizer/AudioEngine';
import { useAuralizerContext } from '@/components/Auralizer/AuralizerContext';
import { ActionType, usePresetContext } from '@/components/Auralizer/PresetContext';
import { ActionType as LibActionType, useLibraryPanelContext } from '@/components/LibraryPanel/LibraryPanelContext';
import { useCreateAuralizationPreset, useDeleteAuralizationPreset, useUpdateAuralizationPreset } from '.';

import { AuralizationPresetDto } from '../types';

export const useAuralizerPresetActions = () => {
  const { modelInformation } = useModelContext();
  const { simsToCompare } = useAuralizerContext();
  const {
    dispatch,
    masterVolume,
    sourceVolumes,
    selectedSounds,
    selectedPreset,
    sourceMuteSettings,
    availablePresets,
  } = usePresetContext();

  const {
    simulationState: { originalSim },
  } = useSimulationContext();

  const { dispatch: libDispatch } = useLibraryPanelContext();

  const { mutate: createAuralizationPreset } = useCreateAuralizationPreset();
  const { mutate: updateAuralizationPreset } = useUpdateAuralizationPreset();
  const { mutate: deletePreset } = useDeleteAuralizationPreset();

  const navigate = useNavigate();

  const createPreset = async (name: string, description: string): Promise<boolean> => {
    return new Promise((resolve) => {
      if (modelInformation && simsToCompare.length > 0) {
        const spaceId = modelInformation.spaceId;
        if (originalSim) {
          // custom sounds have soundPath as empty string - no sounds are null
          const containsLocalSound = Object.keys(selectedSounds).find((key) => selectedSounds[key].urlObject !== null);
          if (containsLocalSound) {
            toast.warning('You can not create a preset with local sound');
            resolve(false);
          } else {
            const simsWithoutOrigin = simsToCompare.filter((sim) => sim.id !== originalSim?.id);
            const simulations = simsWithoutOrigin.map((x) => x.id);
            const sources = [...simsToCompare[0].sources];
            const sourceSettings = sources
              .sort((a, b) => a.orderNumber - b.orderNumber)
              .map((_, index) => ({
                // we are treating soundPath as an id until we have the backend in place
                soundPath: selectedSounds[index]?.id ?? '',
                volume: sourceVolumes[index] ?? 0,
                isMuted: sourceMuteSettings[index] ?? false,
              }));

            createAuralizationPreset(
              {
                name,
                description,
                simulations: [originalSim?.id, ...simulations],
                spaceId,
                mixerSettings: {
                  masterVolume: masterVolume,
                  sourceSettings,
                },
              },
              {
                onSuccess: (data) => {
                  navigate(`/auralizer?presetId=${data.id}&mid=${originalSim?.modelId}&sid=${originalSim?.id}`);

                  const newAvailablePresets = [data, ...availablePresets];
                  dispatch({
                    type: ActionType.SET_AVAILABLE_PRESETS,
                    presets: newAvailablePresets,
                  });
                  libDispatch({
                    type: LibActionType.SET_HIGHLIGHTED_ITEM,
                    highlightedItemId: data.id,
                  });
                  resolve(true);
                },
                onError: () => {
                  resolve(false);
                },
              }
            );
          }
        }
      }
    });
  };

  const updatePresetDetails = async (newName: string, newDescription: string) => {
    return new Promise((resolve) => {
      if (selectedPreset) {
        updateAuralizationPreset(
          {
            ...selectedPreset,
            name: newName ?? selectedPreset.name,
            description: newDescription ?? selectedPreset.description,
          },
          {
            onSuccess: (data) => {
              updatePreset(data);
              resolve(true);
            },
            onError: () => {
              resolve(false);
            },
          }
        );
      }
    });
  };

  const updatePresetSettings = () => {
    if (selectedPreset && simsToCompare[0] && modelInformation && simsToCompare.length > 0) {
      const containsLocalSound = Object.keys(selectedSounds).find((key) => selectedSounds[key].urlObject !== null);
      if (containsLocalSound) {
        toast.warning('You can not save a preset with local sound');
        return;
      } else {
        const simulations = simsToCompare.map((x) => x.id);

        const sources = [...simsToCompare[0].sources];
        const sourceSettings = sources
          .sort((a, b) => a.orderNumber - b.orderNumber)
          .map((_, index) => ({
            // we are treating soundPath as an id until we have the backend in place
            soundPath: selectedSounds[index]?.id ?? '',
            volume: sourceVolumes[index] ?? 0,
            isMuted: sourceMuteSettings[index] ?? false,
          }));

        updateAuralizationPreset(
          {
            ...selectedPreset,
            simulations,
            mixerSettings: {
              masterVolume: masterVolume,
              sourceSettings,
            },
          },
          {
            onSuccess: (data) => {
              updatePreset(data);
            },
          }
        );
      }
    }
  };

  const updatePreset = (data: AuralizationPresetDto) => {
    if (selectedPreset) {
      dispatch({
        type: ActionType.SET_SELECTED_PRESET,
        preset: data,
      });
      libDispatch({
        type: LibActionType.SET_HIGHLIGHTED_ITEM,
        highlightedItemId: data.id,
      });
      const presetIndex = availablePresets.findIndex((preset) => preset.id === selectedPreset.id);
      const newAvailablePresets = [...availablePresets];
      newAvailablePresets[presetIndex] = data;
      dispatch({
        type: ActionType.SET_AVAILABLE_PRESETS,
        presets: newAvailablePresets,
      });
    }
  };

  const onConfirmDelete = () => {
    if (selectedPreset) {
      deletePreset(
        { presetId: selectedPreset.id, presetName: selectedPreset.name },
        {
          onSuccess: () => {
            AudioEngine.getInstance().playbackToggle(false);
            const newAvailablePresets = availablePresets.filter((preset) => preset.id !== selectedPreset.id);
            dispatch({
              type: ActionType.SET_AVAILABLE_PRESETS,
              presets: newAvailablePresets,
            });
            removePreset(true);
          },
        }
      );
    }
  };

  const onClearPreset = () => {
    removePreset();
  };

  const removePreset = (replace: boolean = false) => {
    navigate(`/auralizer?mid=${originalSim?.modelId}&sid=${originalSim?.id}`, { replace });
    dispatch({
      type: ActionType.SET_SELECTED_PRESET,
      preset: null,
    });
    libDispatch({ type: LibActionType.SET_HIGHLIGHTED_ITEM, highlightedItemId: null });
  };

  return {
    createPreset,
    updatePresetDetails,
    updatePresetSettings,
    onConfirmDelete,
    onClearPreset,
  };
};
