import { MouseEventHandler, useCallback } from 'react';

import { ReflectionDetails } from '@/components/Results/context/ReflectogramResultsContext';

/**
 * Finds the closest reflection to the clicked point on the polar plot and selects it
 */
export const usePolarPlotClickHandler = (
  data: ReflectionDetails[],
  radialAxisRange: [number, number] | null,
  selectedAngleType: string,
  plotContainerRef: React.RefObject<HTMLDivElement>,
  onSelectReflection: (index: number) => void
): MouseEventHandler<HTMLDivElement> => {
  return useCallback(
    (event) => {
      const plotContainerElement = plotContainerRef.current;
      const plotAreaElement = plotContainerElement?.querySelector('.polar .maindrag') as HTMLElement;
      if (!plotAreaElement || !radialAxisRange) return;

      const { left, top, width, height } = plotAreaElement.getBoundingClientRect();
      const [clickX, clickY] = [event.clientX - left, event.clientY - top];
      const [centerX, centerY] = [width / 2, height / 2];
      const [dx, dy] = [clickX - centerX, clickY - centerY];

      let radius = Math.sqrt(dx * dx + dy * dy);
      let angle = (Math.atan2(dy, dx) * 180) / Math.PI + 180;

      // If plot type is "elevation" then we need to make sure that the angle is between -180 and 180
      if (selectedAngleType === 'elevation') {
        angle = ((angle + 180) % 360) - 180;
      }

      // Adjust the radius to match the radial axis range instead of the plot area size in pixels
      const [radialMin, radialMax] = radialAxisRange;
      radius = (radius / (width / 2)) * (radialMax - radialMin) + radialMin;

      let closestIndex = -1;
      let minDistance = Infinity;

      data.forEach((d, index) => {
        const theta = selectedAngleType === 'elevation' ? d.elevationRelative : d.azimuthRelative;
        const r = d.spl;

        const distance = Math.sqrt((r - radius) ** 2 + (theta - angle) ** 2);

        if (distance < minDistance) {
          minDistance = distance;
          closestIndex = index;
        }
      });

      if (closestIndex !== -1) {
        onSelectReflection(closestIndex);
      }
    },
    [data, radialAxisRange, selectedAngleType, plotContainerRef, onSelectReflection]
  );
};
