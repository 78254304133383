import { useSimulationContext } from '@/context/SimulationContext';

import { EMPTY_GUID } from '@/components/Shared/constants';
import { useConfirmEdit } from '@/components/EditSimulation/hooks/useConfirmEdit';
import { useSaveUpdatedSimulation } from '@/components/EditSimulation/hooks/useSaveUpdatedSimulation';

import { SOURCE_AND_RECEIVER_SAVE_TEXT } from '../constants';

import { Receiver as EditorReceiver, Source as EditorSource } from '@/context/EditorContext/types';
import { Receiver, Simulation, Source, SourceParameter } from '@/types';

export const useSaveSourcesAndReceivers = () => {
  const saveSimulation = useSaveUpdatedSimulation();
  const confirmEdit = useConfirmEdit();

  const {
    simulationState: { selectedSimulation },
  } = useSimulationContext();

  const saveSourcesAndReceivers = async (
    sources: EditorSource[],
    receivers: EditorReceiver[],
    saveText: string = SOURCE_AND_RECEIVER_SAVE_TEXT
  ) => {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise<Simulation | null>(async (resolve) => {
      const canContinue = await confirmEdit();
      const newSources: Source[] = sources.map((s, index) => ({
        id: s.id,
        label: s.label,
        x: s.x!,
        y: s.y!,
        z: s.z!,
        orderNumber: index,
      }));

      const newReceivers: Receiver[] = receivers.map((r, index) => ({
        ...r,
        x: r.x!,
        y: r.y!,
        z: r.z!,
        orderNumber: index,
      }));

      const newSourceParameters: SourceParameter[] = sources.map((s) => ({
        sourceId: s.id,
        directivityPattern: s.params?.directivityPattern || EMPTY_GUID,
        azimuth: s.params?.azimuth || 0,
        elevation: s.params?.elevation || 0,
        rotation: s.params?.rotation || 0,
        eq: s.params?.eq || null,
        overallGain: s.params?.overallGain || null,
        correctIrByOnAxisSpl: s.params?.correctIrByOnAxisSpl || false,
        useSplForSti: s.params?.useSplForSti || false,
      }));

      const updatedSimulation = {
        ...selectedSimulation,
        sources: [...newSources],
        receivers: [...newReceivers],
        sourceParameters: [...newSourceParameters],
      };

      if (canContinue) {
        // @ts-expect-error Types of property 'id' are incompatible. Type 'string | undefined' is not assignable to type 'string'.
        saveSimulation(updatedSimulation, saveText);
        resolve(null);
      } else {
        // @ts-expect-error extra?: any; }' is not assignable to type 'Simulation'
        resolve(updatedSimulation);
      }
    });
  };

  return { saveSourcesAndReceivers };
};
