import { useEffect } from 'react';

import { useModelContext } from '@/context/ModelContext';
import { useSimulationContext } from '@/context/SimulationContext';

import { AudioEngine } from '../AudioEngine';
import { ActionType, useAuralizerContext } from '../AuralizerContext';
import { useConnectIRS } from './useConnectIRS';

import { useGetSimulationsBySpaceId } from '@/hooks';

import { createAllSimsAurObject, createAllSimSrcRecHash, getSimFromLastSimRun, getSourceList } from '../utils/';

import { AllSimsAurObject } from '../types';

export const useInitializeAuralizer = () => {
  const { dispatch, availableSimsToCompare } = useAuralizerContext();
  const { modelInformation } = useModelContext();

  const {
    simulationState: { originalSim },
  } = useSimulationContext();

  const audioEngine = AudioEngine.getInstance();

  const connectIRS = useConnectIRS();

  // fetch all simulations in a space
  const { data: simulationsFromSpace } = useGetSimulationsBySpaceId(modelInformation?.spaceId || '');

  useEffect(() => {
    // initialize the Auralizer whenever the route changes, the route changes only
    // when the original simulation changes, not when we switch between simulations
    if (originalSim) {
      const newSelectedSim = getSimFromLastSimRun(originalSim);
      const sourceIdList = getSourceList(newSelectedSim);

      audioEngine.originalSourceIdArray = sourceIdList;
      audioEngine.onLoad(sourceIdList);
      dispatch({
        type: ActionType.SET_AUDIO_NODE_DICT,
        simId: null,
        audioNodesDict: null,
      });
      dispatch({
        type: ActionType.SET_INITIAL_AUR_SIM,
        simulation: newSelectedSim,
      });
      // reset if coming from a different simulation
      dispatch({
        type: ActionType.SET_SELECTED_RECEIVER,
        receiver: null,
        index: null,
      });
    }
  }, [originalSim]);

  useEffect(() => {
    if (simulationsFromSpace) {
      dispatch({
        type: ActionType.SET_AVAILABLE_SIMS_TO_COMPARE,
        allSimulations: simulationsFromSpace,
      });
    }
  }, [simulationsFromSpace]);

  useEffect(() => {
    if (modelInformation) {
      // reset srcRecHash when modelInfo changes
      dispatch({ type: ActionType.SET_SIM_SRC_REC_HASH, simSrcRecHash: null });
    }
  }, [modelInformation]);

  useEffect(() => {
    // reset srcRecHash and aurSim if the original simulation changes
    if (originalSim && availableSimsToCompare && availableSimsToCompare.length > 0) {
      const allSimulationsInSpace = availableSimsToCompare.flatMap((space) => space.simulations);

      // create the SimSrcRecHash object that we use to filter out simulations we can not compare
      const simSrcRecHash = createAllSimSrcRecHash(allSimulationsInSpace);
      const simsAurObject: AllSimsAurObject = createAllSimsAurObject(allSimulationsInSpace);
      // add to the global Auralizer state
      audioEngine.simSrcRecHash = simSrcRecHash;
      dispatch({ type: ActionType.SET_SIM_SRC_REC_HASH, simSrcRecHash });
      dispatch({ type: ActionType.SET_AUR_SIMS_OBJECT, simsAurObject });

      dispatch({
        type: ActionType.SET_HAS_INITIALIZED,
        hasInitialized: true,
      });

      connectIRS(originalSim, originalSim, simSrcRecHash);
    }
  }, [originalSim, availableSimsToCompare]);
};
