import { useEffect, useRef, useState } from 'react';
import ScrollContainer from 'react-indiana-drag-scroll';

import { useSimulationContext } from '@/context/SimulationContext';

import { useAuralizerContext } from '../AuralizerContext';
import { usePresetContext } from '../PresetContext';
import { SourcePointColumn } from './SourcePointColumn';

import { Source } from '@/types';

import '../style.scss';

export const SourcePoints = () => {
  const sourceContainerRef = useRef<HTMLDivElement>(null);

  const { selectedAurSim, simsAurObject, error } = useAuralizerContext();
  const { selectedPreset } = usePresetContext();
  const {
    simulationState: { originalSim },
  } = useSimulationContext();
  const [availableSources, setAvailableSources] = useState<Source[]>([]);

  const [hasError, setHasError] = useState<boolean>(false);

  useEffect(() => {
    if (originalSim && Object.keys(simsAurObject).length > 0) {
      setAvailableSources(simsAurObject[originalSim.id]?.sources);
    }
  }, [originalSim, simsAurObject]);

  useEffect(() => {
    if (selectedAurSim && error && error[selectedAurSim.id]) {
      setHasError(true);
    } else {
      setHasError(false);
    }
  }, [error, selectedAurSim]);

  return (
    <div ref={sourceContainerRef} className={`source-points-container ${hasError ? 'has-error' : ''}`}>
      <ScrollContainer
        horizontal={true}
        vertical={false}
        hideScrollbars={false}
        ignoreElements=".slider-gain, .gain-number-input"
        className="source-points-scroll">
        {availableSources.map((source, index: number) => (
          <SourcePointColumn
            key={source.id}
            source={source}
            sourceIndex={index}
            sourceSettings={selectedPreset?.mixerSettings?.sourceSettings[index]}
          />
        ))}
      </ScrollContainer>
    </div>
  );
};
