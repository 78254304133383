import { useMemo } from 'react';
import { Data } from 'plotly.js';

import { ReflectionDetails } from '@/components/Results/context/ReflectogramResultsContext';

import { getTimeOfArrivalColor } from '@/components/Editor/components/Viewport/ReflectogramResults/utils';

import { TimeOfArrivalGroup } from '../../../SubHeaders/ReflectogramResultsHeader/constants';

import { roundFloat } from '@/utils/trebleFunctions';

import { AxisType } from '../types';

type UsePlotDataProps = {
  data: ReflectionDetails[];
  selectedReflectionIndex: number | null;
  selectedFrequencyBandIndex: number | null;
  timeOfArrivalGroups: TimeOfArrivalGroup[];
  selectedXAxisType: AxisType;
};

export const useReflectionsPlotData = ({
  data,
  selectedReflectionIndex,
  selectedFrequencyBandIndex,
  timeOfArrivalGroups,
  selectedXAxisType,
}: UsePlotDataProps): Data[] => {
  return useMemo(() => {
    const baseDataPoints = data.map(
      (d, index): Data => ({
        x: [selectedXAxisType === 'linear' ? d.timeOfArrivalRelative * 1000 : index + 1],
        y: selectedFrequencyBandIndex !== null ? [d.splPerBand[selectedFrequencyBandIndex]] : [d.spl],
        type: 'bar',
        width: selectedXAxisType === 'linear' ? 0.5 : undefined,
        marker: {
          color:
            selectedXAxisType === 'ordered' && selectedReflectionIndex === index
              ? '#edf1ff'
              : getTimeOfArrivalColor(d.timeOfArrivalRelative, timeOfArrivalGroups),
          opacity: selectedXAxisType === 'ordered' ? 1 : 0.7,
          line: {
            color: getTimeOfArrivalColor(d.timeOfArrivalRelative, timeOfArrivalGroups),
          },
        },
        text: `Reflection: ${index + 1} of ${data.length}<br>SPL: ${roundFloat(
          selectedFrequencyBandIndex !== null ? d.splPerBand[selectedFrequencyBandIndex] : d.spl,
          2
        )} db<br>Time (Rel. direct): ${roundFloat(d.timeOfArrivalRelative * 1000, 2)} ms`,
        hoverinfo: `text`,
        textposition: 'none',
      })
    );

    // Adding an additional data point for the selected reflection to make it appear in front of other bars
    if (
      selectedXAxisType === 'linear' &&
      selectedReflectionIndex !== null &&
      selectedReflectionIndex >= 0 &&
      selectedReflectionIndex < data.length
    ) {
      const selectedData = data[selectedReflectionIndex];
      const selectedDataPoint: Data = {
        x: [selectedData.timeOfArrivalRelative * 1000],
        y:
          selectedFrequencyBandIndex !== null
            ? [selectedData.splPerBand[selectedFrequencyBandIndex]]
            : [selectedData.spl],
        type: 'bar',
        width: selectedXAxisType === 'linear' ? 0.5 : undefined,
        marker: {
          color: '#edf1ff',
          line: {
            color: '#edf1ff',
          },
        },
        text: `Reflection: ${selectedReflectionIndex + 1} of ${data.length}, SPL: ${roundFloat(
          selectedFrequencyBandIndex !== null ? selectedData.splPerBand[selectedFrequencyBandIndex] : selectedData.spl,
          2
        )} db, Time (Rel. direct): ${roundFloat(selectedData.timeOfArrivalRelative * 1000, 2)} ms`,
        hoverinfo: `text`,
        textposition: 'none',
      };

      baseDataPoints.push(selectedDataPoint);
    }

    return baseDataPoints;
  }, [data, selectedXAxisType, selectedFrequencyBandIndex, selectedReflectionIndex, timeOfArrivalGroups]);
};
