import { useMemo } from 'react';
import { Data } from 'plotly.js';

import { ReflectionDetails } from '@/components/Results/context/ReflectogramResultsContext';

import { alpha } from '@mui/material';

import { getTimeOfArrivalColor } from '@/components/Editor/components/Viewport/ReflectogramResults/utils';

import { TimeOfArrivalGroup } from '../../../SubHeaders/ReflectogramResultsHeader/constants';

import { roundFloat } from '@/utils/trebleFunctions';

export const usePolarPlotData = (
  data: ReflectionDetails[],
  selectedReflectionIndex: number | null,
  selectedFrequencyBandIndex: number | null,
  selectedAngleType: string,
  timeOfArrivalGroups: TimeOfArrivalGroup[],
  dbAxisRange: [number, number]
): Partial<Data>[] => {
  return useMemo(() => {
    if (!data || data.length === 0) {
      // Return a default scatterpolar plot configuration when no data is available
      return [
        {
          type: 'scatterpolar',
          mode: 'lines',
        },
      ];
    }

    const createPlotPoint = (d: ReflectionDetails, index: number, color: string): Partial<Data> => ({
      type: 'scatterpolar',
      mode: 'lines',
      r: [dbAxisRange[0], selectedFrequencyBandIndex ? d.splPerBand[selectedFrequencyBandIndex] : d.spl],
      theta:
        selectedAngleType === 'elevation'
          ? [d.elevationRelative, d.elevationRelative]
          : [d.azimuthRelative, d.azimuthRelative],
      line: { shape: 'linear', color, width: 3 },
      showlegend: false,
      text: `Reflection: ${index + 1} of ${data.length}<br>SPL: ${
        selectedFrequencyBandIndex ? roundFloat(d.splPerBand[selectedFrequencyBandIndex], 2) : roundFloat(d.spl, 2)
      } db<br>${
        selectedAngleType === 'elevation'
          ? `Elevation: ${roundFloat(d.elevationRelative, 2)}°`
          : `Azimuth: ${roundFloat(d.azimuthRelative, 2)}`
      }`,
      hoverinfo: `text`,
    });

    const dataPoints = data.map((d, index) =>
      createPlotPoint(
        d,
        index,
        selectedReflectionIndex === index
          ? '#edf1ff'
          : alpha(getTimeOfArrivalColor(d.timeOfArrivalRelative, timeOfArrivalGroups), 0.6)
      )
    );

    // Add an additional point for the selected reflection so that it overlays all other lines
    if (selectedReflectionIndex !== null && selectedReflectionIndex >= 0 && selectedReflectionIndex < data.length) {
      const selectedData = data[selectedReflectionIndex];
      dataPoints.push(createPlotPoint(selectedData, selectedReflectionIndex, '#edf1ff'));
    }

    return dataPoints;
  }, [data, selectedReflectionIndex, selectedFrequencyBandIndex, selectedAngleType, timeOfArrivalGroups, dbAxisRange]);
};
