import { ThreeEvent } from '@react-three/fiber';

import { useAppContext } from '@/context/AppContext';
import { ActionType as EditorActionType, useEditorContext } from '@/context/EditorContext';
import { useSimulationContext } from '@/context/SimulationContext';

import { EMPTY_GUID } from '@/components/Shared/constants';
import { useHandleOutsideClickForRow } from '@/components/LayersTable/hooks/useHandleOutsideClickForRow';
import { useMultiSelectLayers } from '@/components/LayersTable/hooks/useMultiSelectLayers';
import { ActionType, useLibraryPanelContext } from '@/components/LibraryPanel/LibraryPanelContext';

import { Material, MaterialLayer } from '@/types';

export const useClickMeshHandler = () => {
  const {
    appState: { filteredMaterials },
  } = useAppContext();
  const {
    simulationState: { surfaceLayers },
  } = useSimulationContext();
  const { dispatch: editorDispatch, multiSelected, selected } = useEditorContext();
  const { isMaterialsLibraryOpen, multiSelectedItemIds, dispatch } = useLibraryPanelContext();
  const multiSelectLayers = useMultiSelectLayers();
  const handleOutsideClickForRow = useHandleOutsideClickForRow();
  const clickMeshHandler = (object: THREE.Object3D<THREE.Event>, event: ThreeEvent<PointerEvent>) => {
    if (object.parent) {
      const isLayerGroup = object.parent.children.length > 1 ? false : true;
      const selectedLayerId = isLayerGroup ? object.parent.uuid : object.userData.attributes?.id;

      if (event.ctrlKey || event.metaKey || event.shiftKey) {
        if (!window.eventAddedForOutsideClick) {
          window.addEventListener('click', handleOutsideClickForRow);
          window.eventAddedForOutsideClick = true;
        }

        if (multiSelectedItemIds.includes(selectedLayerId)) {
          // deselect layer if it is already selected
          const newMultiSelectedItemIds = multiSelectedItemIds.filter((item) => item !== selectedLayerId);
          const newMultiSelected = multiSelected.filter((item) => item.id !== selectedLayerId);

          dispatch({
            type: ActionType.SET_MULTI_SELECT_ITEMS,
            multiSelectedItemIds: newMultiSelectedItemIds,
          });
          editorDispatch({
            type: EditorActionType.SET_MULTI_SELECTED,
            multiSelected: newMultiSelected,
          });
        } else if (multiSelected.length === 0 && selected) {
          multiSelectLayers([...(selected ? [selected.id] : []), selectedLayerId]);
        } else {
          multiSelectLayers([selectedLayerId]);
        }
      } else {
        editorDispatch({
          type: EditorActionType.SET_SELECTED,
          selected: {
            type: isLayerGroup ? 'LayerGroup' : 'Layer',
            id: selectedLayerId,
          },
        });
      }

      if (isMaterialsLibraryOpen) {
        let selectedLayer: MaterialLayer | null = null;
        let selectedMaterial: Material | null = null;

        if (isLayerGroup) {
          // if selected mesh is a layer group, then find the layer (group)
          selectedLayer = surfaceLayers.find((layerGroup) => layerGroup.id === selectedLayerId) || selectedLayer;
        } else {
          // else if selected mesh is a single surface layer, then find the surface within the layers (groups)
          for (const layerGroup of surfaceLayers) {
            for (const layer of layerGroup.children) {
              if (layer.id === selectedLayerId) {
                selectedLayer = layer;
                break;
              }
            }
          }
        }

        if (selectedLayer?.materialId !== EMPTY_GUID) {
          selectedMaterial =
            filteredMaterials.find((material) => material.id === selectedLayer?.materialId) || selectedMaterial;
        }

        dispatch({
          type: ActionType.SET_MATERIALS_PANEL_OPEN,
          isOpen: true,
        });
        dispatch({
          type: ActionType.SET_SELECTED_MATERIAL,
          material: selectedMaterial,
          highlightedItemId: selectedMaterial?.id ?? null,
        });
      }
    }
  };

  return clickMeshHandler;
};
