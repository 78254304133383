import { Fragment, ReactElement, ReactNode, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { IconButton } from '@mui/material';

import { TrblChevronLeft } from '../Icons';

import './styles.scss';

type Breadcrumb = {
  text: string | ReactNode;
  to?: string;
  icon?: ReactElement;
};

export const Breadcrumbs = ({ items }: { items: Breadcrumb[] }) => {
  const [currentLocation, setCurrentLocation] = useState<Breadcrumb>();
  const [previousPaths, setPreviousPaths] = useState<Breadcrumb[]>([]);

  useEffect(() => {
    setCurrentLocation(items.pop());
    setPreviousPaths(items);
  }, [items]);

  const navigate = useNavigate();

  return (
    <>
      {currentLocation ? (
        <div className="breadcrumbs-div">
          <IconButton className="back-btn" onClick={() => navigate(-1)}>
            <TrblChevronLeft />
          </IconButton>
          <div className="top-info-text">
            {previousPaths.map((item: Breadcrumb, index: number) => (
              <Fragment key={index}>
                {item.icon && <div className="breadcrumbs-icon">{item.icon}</div>}
                {item.to && <Link to={item.to}>{item.text}</Link>}
                {index < items.length && <span className="arrow"> &rsaquo; </span>}
              </Fragment>
            ))}
            {currentLocation.icon && <div className="breadcrumbs-icon">{currentLocation.icon}</div>}
            <span className="active">{currentLocation.text}</span>
          </div>
        </div>
      ) : null}
    </>
  );
};
