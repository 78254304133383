import { ActionType, useAuralizerContext } from '../AuralizerContext';
import { useConnectNewIRS } from './useConnectNewIRS';

import { getNewIdsFromRecHashes, getNewIdsFromSourceHashes } from '../utils/';

import { SimSrcRecHash } from '../types';
import { Simulation } from '@/types';

export const useConnectIRS = () => {
  const { dispatch } = useAuralizerContext();
  const connectNewIRS = useConnectNewIRS();

  const connectIRS = async (newSimulation: Simulation, originalSim: Simulation, simSrcRecHash: SimSrcRecHash) => {
    dispatch({
      type: ActionType.SET_FETCHING,
      isFetching: true,
      simId: newSimulation?.id,
    });
    const currentSourceIdList: string[] = getNewIdsFromSourceHashes(newSimulation, simSrcRecHash) ?? [];
    const currentReceiverIdList: string[] = getNewIdsFromRecHashes(newSimulation, simSrcRecHash) ?? [];

    connectNewIRS(newSimulation, originalSim, currentSourceIdList, currentReceiverIdList, simSrcRecHash);
  };

  return connectIRS;
};
