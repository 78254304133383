import { useState } from 'react';

import { TrblTooltip } from '@/components/Shared';
import { SabineEstimateTable } from './components/SabineEstimateTable';
import { TrblCaretDownIcon, TrblCaretRightIcon, TrblInfoIcon } from '../Icons';

import { infoText } from './constants';

import styles from './styles.module.scss';

export const StatisticalEstimates = () => {
  const [isOpen, setIsOpen] = useState(true);

  return (
    <div className={`${styles['room-settings-container']} ${styles[isOpen ? 'show' : '']}`}>
      <button className={styles['room-settings-button']} onClick={() => setIsOpen(!isOpen)}>
        <div>
          <span className={styles['room-settings-toggle']}>
            {isOpen ? <TrblCaretDownIcon /> : <TrblCaretRightIcon />}
          </span>
          <span className={styles['room-settings-header']}> RT estimates </span>
        </div>

        <TrblTooltip title={infoText} placement="left">
          <span>
            <TrblInfoIcon />
          </span>
        </TrblTooltip>
      </button>
      <div>
        <div className={`${styles['room-settings-content']}  ${styles[isOpen ? 'show' : '']}`}>
          {isOpen && <SabineEstimateTable />}
        </div>
      </div>
    </div>
  );
};
