import React, { useCallback, useRef, useState } from 'react';
import { Figure } from 'react-plotly.js';
import { Layout } from 'plotly.js';

import { ReflectionDetails } from '@/components/Results/context/ReflectogramResultsContext';

import { useMediaQuery } from '@mui/material';

import { TabButtons } from '@/components/Shared/TabButtons';
import { PolarPlot } from './PolarPlot';

import { useDbAxisRange, usePolarPlotClickHandler, usePolarPlotData } from './hooks';

import { TimeOfArrivalGroup } from '../../SubHeaders/ReflectogramResultsHeader/constants';

import classes from './styles.module.scss';

type ReflectionAnglesProps = {
  data: ReflectionDetails[];
  selectedReflectionIndex: number | null;
  selectedFrequencyBandIndex: number | null;
  timeOfArrivalGroups: TimeOfArrivalGroup[];
  onSelectReflection: (index: number) => void;
};

export const ReflectionAnglePlots: React.FC<ReflectionAnglesProps> = ({
  data,
  selectedReflectionIndex,
  selectedFrequencyBandIndex,
  timeOfArrivalGroups,
  onSelectReflection,
}) => {
  const plotContainerRef = useRef<HTMLDivElement>(null);
  const [layout, setLayout] = useState<Partial<Layout>>({});
  const isSmallHeightScreen = useMediaQuery('(max-height: 1000px)');
  const [selectedAngleType, setSelectedAngleType] = useState<string>('elevation');

  const dbAxisRange = useDbAxisRange(data, selectedFrequencyBandIndex);
  const polarPlotDataPoints = usePolarPlotData(
    data,
    selectedReflectionIndex,
    selectedFrequencyBandIndex,
    selectedAngleType,
    timeOfArrivalGroups,
    dbAxisRange
  );

  const handlePlotClick = usePolarPlotClickHandler(
    data,
    (layout.polar?.radialaxis?.range as [number, number]) ?? null,
    selectedAngleType,
    plotContainerRef,
    onSelectReflection
  );

  const handleInitializePlot = useCallback((figure: Readonly<Figure>) => {
    setLayout(figure.layout);
  }, []);

  const handleUpdatePlot = useCallback((figure: Readonly<Figure>) => {
    setLayout((prevLayout) => {
      // Only update the layout state if it has actually changed
      return JSON.stringify(prevLayout) !== JSON.stringify(figure.layout) ? figure.layout : prevLayout;
    });
  }, []);

  return (
    <div className={classes['plot-container']}>
      <div className={classes['plot-header']}>
        <div className={classes['tabs-container']}>
          <TabButtons
            small={isSmallHeightScreen}
            options={[
              { key: 'elevation', label: 'Elevation' },
              { key: 'azimuth', label: 'Azimuth' },
            ]}
            selectedValue={selectedAngleType}
            onChange={setSelectedAngleType}
          />
        </div>
      </div>
      <div className={classes['plot']} onClick={handlePlotClick} ref={plotContainerRef}>
        <PolarPlot
          plotData={polarPlotDataPoints}
          dbAxisRange={dbAxisRange}
          isSmallHeightScreen={isSmallHeightScreen}
          onInitialized={handleInitializePlot}
          onUpdate={handleUpdatePlot}
        />
      </div>
    </div>
  );
};
