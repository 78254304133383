import { FC, useEffect, useRef } from 'react';
import { GizmoHelper, Html, OrbitControls, TransformControls } from '@react-three/drei';
import { useThree } from '@react-three/fiber';
import { Camera, PerspectiveCamera, Vector3 } from 'three';

import { useEditorContext } from '@/context/EditorContext';
import { useModelContext } from '@/context/ModelContext';

import { AxisHelper } from '@/components/Shared/AxisHelper';
import { AudioEngine } from '@/components/Auralizer/AudioEngine';
import { useGridReceivers, useReceivers, useSources } from '@/components/SourceRecieverSettings/hooks';

import { useFirstPersonCamera } from '../../hooks';

import { DEFAULT_CAMERA_POSITION, VIEWPORT_CENTER } from './constants';

import { getCameraLookAtFromModel, getCameraPositionFromModel } from './utils';

import styles from './styles.module.scss';

type ViewportControlsProps = {
  canvasRef: React.RefObject<HTMLCanvasElement>;
};

let cameraVector = new Vector3(0, 0, 0);

export const ViewportControls: FC<ViewportControlsProps> = ({ canvasRef }) => {
  const { modelVolume, modelBoundingBox, modelCenter } = useModelContext();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const controlRef = useRef<any>();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const transformControlRef = useRef<any>();

  const { isCameraInsideModel, selected, sources, receivers, gridReceivers, isInResultsMode, coordinates } =
    useEditorContext();

  const { scene, camera } = useThree();

  const syncAudioWithCamera = (camera: Camera) => {
    cameraVector = camera.getWorldDirection(cameraVector);

    const theta = Math.atan2(cameraVector.y, cameraVector.x);
    const phi = Math.asin(cameraVector.z / 1);
    AudioEngine.getInstance().updateStartAngle(theta, phi);
  };

  // Controls and camera when inside model
  const { updateCamera } = useFirstPersonCamera(
    canvasRef,
    syncAudioWithCamera,
    coordinates !== null,
    coordinates !== null
  );

  // Update camera if changing receivers when auralizer is open
  useEffect(() => {
    if (coordinates) {
      updateCamera(coordinates[0], coordinates[1]);
    } else {
      updateCamera(DEFAULT_CAMERA_POSITION, new Vector3(0, 0, 1.5));
    }
  }, [coordinates]);

  useEffect(() => {
    if (modelCenter && modelBoundingBox && !isCameraInsideModel) {
      const cameraLookat = getCameraLookAtFromModel(modelCenter);
      const cameraPosition = getCameraPositionFromModel(modelCenter, modelBoundingBox);

      controlRef.current.target = cameraLookat;
      camera.position.set(cameraPosition.x, cameraPosition.y, cameraPosition.z);
    }
  }, [modelCenter]);

  // Change the camera FOV when inside model
  useEffect(() => {
    const fov = isCameraInsideModel ? 60 : 50;

    (camera as PerspectiveCamera).fov = fov;
    camera.updateProjectionMatrix();
  }, [isCameraInsideModel, camera]);

  const { handleSourceCoordineChange } = useSources();
  const { handleReceiverCoordinateChange } = useReceivers();
  const { handleGridReceiverCoordinateChange } = useGridReceivers();

  const selectedPointId =
    selected &&
    (selected.type === 'ReceiverPoint' || selected.type === 'SourcePoint' || selected.type === 'GridReceiver')
      ? selected.id
      : null;
  const pointIndex = selectedPointId
    ? selected!.type === 'SourcePoint'
      ? sources.findIndex((s) => s.id === selectedPointId)
      : selected!.type === 'GridReceiver'
      ? gridReceivers.findIndex((s) => s.id === selectedPointId)
      : receivers.findIndex((r) => r.id === selectedPointId)
    : null;

  return (
    <>
      {/* makeDefault makes the controls known to r3f, now transform-controls can auto-disable them when active */}
      <OrbitControls
        ref={controlRef}
        enabled={!isCameraInsideModel}
        makeDefault={true}
        minPolarAngle={0}
        maxPolarAngle={Math.PI}
        dampingFactor={0.25}
        target={VIEWPORT_CENTER}
      />
      {selectedPointId && !isInResultsMode && (
        <TransformControls
          ref={transformControlRef}
          object={scene.getObjectByProperty('uuid', selectedPointId)}
          size={0.325}
          mode={'translate'}
          matrixAutoUpdate={false}
          onMouseUp={() => {
            if (pointIndex != null && transformControlRef.current) {
              const { positionStart, offset } = transformControlRef.current;
              if (selected!.type === 'SourcePoint') {
                handleSourceCoordineChange(
                  pointIndex,
                  +(positionStart.x + offset.x).toFixed(2),
                  +(positionStart.y + offset.y).toFixed(2),
                  +(positionStart.z + offset.z).toFixed(2)
                );
              } else if (selected!.type === 'ReceiverPoint') {
                handleReceiverCoordinateChange(
                  pointIndex,
                  +(positionStart.x + offset.x).toFixed(2),
                  +(positionStart.y + offset.y).toFixed(2),
                  +(positionStart.z + offset.z).toFixed(2)
                );
              } else if (selected!.type === 'GridReceiver') {
                handleGridReceiverCoordinateChange(
                  pointIndex,
                  +(positionStart.x + offset.x).toFixed(2),
                  +(positionStart.y + offset.y).toFixed(2),
                  +(positionStart.z + offset.z).toFixed(2)
                );
              }

              // Clear the offset after movement (due to bug TROOM-1416)
              transformControlRef.current.offset.set(0, 0, 0);
            }
          }}
        />
      )}
      {!isCameraInsideModel && (
        <GizmoHelper alignment="bottom-right" onTarget={() => controlRef.current.target}>
          <AxisHelper />

          <Html wrapperClass={styles['model-details-wrapper']} className={styles['model-details']}>
            <div>Grid size: 1x1 m</div>
            {modelVolume !== null && (
              <div>
                {`Volume: ${modelVolume.toFixed(2)} m`}
                <sup>3</sup>
              </div>
            )}
          </Html>
        </GizmoHelper>
      )}
    </>
  );
};
