import { useEffect, useState } from 'react';

import { Stack } from '@mui/material';

import { TrblTooltip } from '@/components/Shared';
import { AlphabetOption } from '@/components/Shared/Auralizer';
import { TrblIconButton } from '@/components/Shared/Buttons';
import { SelectOptionWithGrouping } from '@/components/Shared/TrblSelect';
import { TrblDeleteIcon } from '@/components/Icons';
import { useAuralizerContext } from '../AuralizerContext';
import { SimulationPicker } from './SimulationPicker';

import { useSelectSimulationAsAurSim } from '../hooks/useSelectSimulationAsAurSim';

import { Simulation } from '@/types';

import '../style.scss';

export const SimulationsSelector = ({
  originalSim,
  disabled = false,
  defaultValue,
  menuItems,
  index,
  removeFromComparison,
}: {
  originalSim: Simulation;
  disabled?: boolean;
  defaultValue?: string;
  menuItems: SelectOptionWithGrouping[];
  index: number;
  removeFromComparison?: (index: number, id?: string) => void;
}) => {
  const { selectedAurSim, simsToCompare, fetching } = useAuralizerContext();

  const [selectedSimId, setSelectedSimId] = useState('');
  const [selectedSim, setSelectedSim] = useState<Simulation>();
  const [showDelete, setShowDelete] = useState(false);

  const selectSimulationAsAurSim = useSelectSimulationAsAurSim();

  useEffect(() => {
    if (defaultValue) {
      setSelectedSimId(defaultValue);
    }
  }, [defaultValue]);

  useEffect(() => {
    if (selectedSimId) {
      const newAurSimulation = simsToCompare.find((sim) => sim.id === selectedSimId);
      setSelectedSim(newAurSimulation);
    }
  }, [selectedSimId]);

  const removeSimulation = () => {
    if (removeFromComparison) {
      removeFromComparison(index, selectedSimId);
    }
  };

  return (
    <div
      className={`simulation-selector-container ${
        selectedAurSim && selectedAurSim.id === selectedSimId ? 'selected' : ''
      }`}
      onMouseEnter={() => setShowDelete(true)}
      onMouseLeave={() => setShowDelete(false)}>
      <TrblTooltip title={fetching?.[selectedSimId]?.isFetching ? 'Loading simulation data...' : ''} disableInteractive>
        <span>
          <AlphabetOption
            disabled={selectedSimId === '' || fetching?.[selectedSimId]?.isFetching}
            selectedOptionId={selectedAurSim?.id}
            optionId={selectedSimId}
            index={index}
            onSelect={selectSimulationAsAurSim}
          />
        </span>
      </TrblTooltip>
      {disabled ? (
        <p className="original-simulation">
          <span>
            {originalSim.name} {''}
            {originalSim.hasBeenEdited && <span>[editing]</span>}
          </span>
          {selectedSim?.lastSimulationRun?.taskType === 'GA' && <span className="task-type-label">GA</span>}
        </p>
      ) : (
        <Stack display="flex" width="100%" flexDirection="row" justifyContent="space-between">
          <SimulationPicker
            originalSim={originalSim}
            menuItems={menuItems}
            selectedSimId={selectedSimId}
            setSelectedSimId={setSelectedSimId}
            taskType={selectedSim?.lastSimulationRun?.taskType}
          />
          <TrblIconButton
            className={`remove-comparison-button ${disabled ? 'hide' : ''} ${!showDelete ? 'hide' : ''}`}
            onClick={removeSimulation}
            label="Delete comparison"
            icon={<TrblDeleteIcon />}
          />
        </Stack>
      )}
    </div>
  );
};
