import { Vector3 } from 'three';

export const SOURCE_AND_RECEIVER_SAVE_TEXT = 'Sources / Receivers saved  ✓';
export const GRID_RECEIVER_SAVE_TEXT = 'Surface Receivers saved  ✓';
export const MAX_RECEIVERS = 100;
export const DEFAULT_SOURCE_POSITION = new Vector3(2, 2, 1.5);
export const DEFAULT_RECEIVER_POSITION = new Vector3(1, 1, 1.5);
export const SOURCE_OFFSET_Y = -0.5;
export const RECEIVER_OFFSET_Y = 0.5;
export const GRID_RECEIVER_OFFSET_Y = 0;
export const POINT_Z_HEIGHT = 1.5;
export const POINT_OFFSET_X = 0.5;
