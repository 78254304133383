import { FC, useEffect, useState } from 'react';
import ScrollContainer from 'react-indiana-drag-scroll';
import { useLocation, useNavigate } from 'react-router-dom';

import { ActionType as ResultsActionType, useResultsContext } from '@/components/Results/context/ResultsContext';
import { ModelActionType, useModelContext } from '@/context/ModelContext';
import { ActionType as SimActionType, useSimulationContext } from '@/context/SimulationContext';

import { TrblTooltip } from '@/components/Shared';
import { TrblIconButton } from '@/components/Shared/Buttons';
import { TrblChevronDownIcon, TrblCloseIcon } from '@/components/Icons';
import { AddComparisonButton, ResultComparison, ResultComparisonProvider } from '@/components/Results/components';
import { MAX_COMPARISONS } from '@/components/Results/constants';

import { useGetModelInformation, useLoadAndExtractFileFromUrl } from '@/hooks';

import { ModelInformationDto, Simulation } from '@/types';

import styles from './styles.module.scss';

type ResultsComparisonsPanel = {
  originalModelInformation: ModelInformationDto;
  selectedSimulation: Simulation;
};

export const ResultsComparisonsPanel: FC<ResultsComparisonsPanel> = ({
  originalModelInformation,
  selectedSimulation,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const {
    simulationState: { originalSim },
    dispatch: simDispatch,
  } = useSimulationContext();
  const { availableComparisons, selectedComparisonIndex, dispatch: resultsDispatch } = useResultsContext();

  const { models3d, addModelFromFile, currentModelId, dispatch: modelDispatch } = useModelContext();
  const [newModelId, setNewModelId] = useState<string | null>(null);
  const [newModelSimulation, setNewModelSimulation] = useState<Simulation | null>(null);
  const [isMinimized, setIsMinimized] = useState(false);

  const { data: modelInformationData = null } = useGetModelInformation(newModelId);
  const { data: modelFile } = useLoadAndExtractFileFromUrl(modelInformationData?.modelUrl || null, newModelId);
  const [active, setActive] = useState(false);

  const selectSimulation = (comparisonSimulation: Simulation) => {
    if (comparisonSimulation.lastSimulationRun) {
      // We take the simulation object but use most of the properties from the last simulation run
      const newSelectedSim: Simulation = {
        ...comparisonSimulation,
        sources: comparisonSimulation.lastSimulationRun.sources,
        receivers: comparisonSimulation.lastSimulationRun.receivers,
        modelSettings: comparisonSimulation.lastSimulationRun.modelSettings,
        settingsPreset: comparisonSimulation.lastSimulationRun.settingsPreset || null,
        solverSettings: comparisonSimulation.lastSimulationRun.solverSettings,
        sourceParameters: comparisonSimulation.lastSimulationRun.sourceParameters,
        gridReceivers: comparisonSimulation.gridReceivers ?? [],
        taskType: comparisonSimulation.lastSimulationRun.taskType || null,
      };
      simDispatch({
        type: SimActionType.SET_SELECTED_SIMULATION,
        simulation: newSelectedSim,
      });
    }
  };

  useEffect(() => {
    if (modelFile && newModelId) {
      const handleModelLoaded = () => {
        modelDispatch({
          type: ModelActionType.SET_CURRENT_MODEL_ID,
          modelId: newModelId,
        });

        if (newModelSimulation) {
          selectSimulation(newModelSimulation);
        }
      };

      addModelFromFile(newModelId, modelFile, handleModelLoaded);
    }
  }, [modelFile, newModelId]);

  const selectedComparison = availableComparisons[selectedComparisonIndex];
  const selectedComparisonSim = selectedComparison.formState?.selectedSimulation;
  useEffect(() => {
    // We wait for the modelId to be set on the selected simulation object (after useGetSimulationById has been executed and object updated in ResultsComparison component)
    if (selectedComparisonSim?.modelId) {
      // Check if selected result comparison contains a different model
      if (selectedComparisonSim.modelId !== currentModelId) {
        if (!models3d[selectedComparisonSim.modelId]) {
          // Store the model that needs to be loaded and the simulation that needs to be set (after model is loaded)
          setNewModelId(selectedComparisonSim.modelId);
          setNewModelSimulation(selectedComparisonSim);
        } else {
          // If the model is already loaded we can set the model id and simulation right away
          modelDispatch({
            type: ModelActionType.SET_CURRENT_MODEL_ID,
            modelId: selectedComparisonSim.modelId,
          });

          // If we are changing model we know we changed the
          selectSimulation(selectedComparisonSim);
        }
      } else if (selectedSimulation && selectedComparisonSim && selectedComparisonSim.id !== selectedSimulation.id) {
        selectSimulation(selectedComparisonSim);
      }
    }
  }, [selectedComparisonIndex, selectedComparisonSim?.modelId]);

  useEffect(() => {
    setActive(true);

    return () => {
      setActive(false);

      resultsDispatch({
        type: ResultsActionType.RESET_STATE,
      });
    };
  }, []);

  const toggleMinimize = () => {
    setIsMinimized((isMinimized) => !isMinimized);

    // hacky code to trigger Plotly to resize nicely while the panel is being minimized
    const resizeInterval = debounce(() => {
      window.dispatchEvent(new Event('resize'));
    });

    window.addEventListener('resize', resizeInterval);

    window.dispatchEvent(new Event('resize'));

    setTimeout(() => {
      window.removeEventListener('resize', resizeInterval);
      window.dispatchEvent(new Event('resize'));
    }, 300);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function debounce(func: any) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let timer: any;
    return function (event: Event) {
      if (timer) clearTimeout(timer);
      timer = setTimeout(func, 100, event);
    };
  }

  const handleCloseResults = () => {
    const currentSim = originalSim ?? selectedSimulation;
    if (currentSim) {
      modelDispatch({
        type: ModelActionType.SET_CURRENT_MODEL_ID,
        modelId: currentSim.modelId,
      });
      simDispatch({
        type: SimActionType.SET_SELECTED_SIMULATION,
        simulation: { ...currentSim },
      });
      navigate(`/editor?mid=${currentSim.modelId}&sid=${currentSim.id}`);
    } else {
      navigate(location.pathname.replace('results', 'editor') + location.search);
    }
  };
  return (
    <div
      className={`${styles['results-comparisons-panel']}  ${active ? styles['active'] : ''} ${
        isMinimized ? styles['minimized'] : ''
      }  ${availableComparisons.length === MAX_COMPARISONS ? styles['no-comparison-button'] : ''}`}>
      <div style={{ display: 'flex' }}>
        <ScrollContainer
          horizontal={true}
          vertical={false}
          ignoreElements="input"
          hideScrollbars={false}
          className={styles['comparisons-scroll']}>
          {availableComparisons?.map(({ color, formState }, index: number) => (
            <ResultComparisonProvider key={color + formState?.simulationId}>
              <ResultComparison
                modelInformationData={originalModelInformation}
                defaultState={formState}
                index={index}
                color={color}
                isSelected={selectedComparisonIndex === index}
                isMinimized={isMinimized}
              />
            </ResultComparisonProvider>
          ))}
        </ScrollContainer>
        {availableComparisons.length < MAX_COMPARISONS && <AddComparisonButton />}

        <div className={styles['right-content']}>
          <TrblIconButton
            className={styles['minimize']}
            icon={<TrblChevronDownIcon fill="#DADADA" />}
            label="Minimize"
            edge="start"
            onClick={toggleMinimize}></TrblIconButton>
          <TrblTooltip title="Exit results">
            <span>
              <TrblIconButton
                icon={<TrblCloseIcon />}
                label="Exit result"
                edge="start"
                onClick={handleCloseResults}></TrblIconButton>
            </span>
          </TrblTooltip>
        </div>
      </div>
    </div>
  );
};
