import { useEffect, useState } from 'react';

import { Box, Stack } from '@mui/material';

import { PrimaryButton } from '@/components/Shared/Buttons';
import { Stepper, TrblPopup, TrblPopupActions, TrblPopupContent, TrblPopupTitle } from '@/components/Shared/Popup';
import { Divider } from '../Shared/Divider';
import { MultiSpaceImportStep1 } from './MultiSpaceImportStep1';
import { MultiSpaceImportStep2 } from './MultiSpaceImportStep2';

import { useGetSpaceExtractionResult } from '@/hooks';

import { SpaceExtractionResults } from './types';

export const SimpleMultiSpaceImportPopup = ({
  setShowPopup,
  uploadModelDone,
}: {
  setShowPopup: (value: boolean) => void;
  uploadModelDone: (spaceId?: string) => void;
}) => {
  const [stepSelected, setStepSelected] = useState(0);
  const [file, setFile] = useState<File | null>(null);
  const [importStarted, setImportStarted] = useState(false);
  const [multiSpaceResults, setMultiSpaceResults] = useState<SpaceExtractionResults | null>();
  const [importError, setImportError] = useState<string | null>(null);

  const [fetchSpaceExtractionId, setFetchSpaceExtractionId] = useState<string | null>(null);
  const { data: spaceExtractionResultsData } = useGetSpaceExtractionResult(fetchSpaceExtractionId);

  // when import has completed
  useEffect(() => {
    if (multiSpaceResults) {
      setStepSelected(1);
    }
  }, [multiSpaceResults]);

  useEffect(() => {
    // if you go to Step 1, then set File as null to disable import button
    if (stepSelected === 0) {
      setFile(null);
      setMultiSpaceResults(null);
    }
  }, [stepSelected]);

  // trigger start import in Step 1
  const handleStartImport = () => {
    setImportStarted(true);
  };

  const goBack = () => {
    setFile(null);
    setStepSelected(0);
  };

  useEffect(() => {
    if (spaceExtractionResultsData) {
      setMultiSpaceResults(spaceExtractionResultsData);
      setFetchSpaceExtractionId(null);
    }
  }, [spaceExtractionResultsData]);

  return (
    <TrblPopup
      minwidth={stepSelected === 0 ? 500 : 1340}
      maxwidth={stepSelected === 0 ? 1600 : 2000}
      width={'84vw'}
      minheight={400}
      maxheight={900}
      height={'84vh'}
      aria-labelledby={'Import model'}
      open={true}>
      <TrblPopupTitle onClose={() => setShowPopup(false)}>{'Import model'}</TrblPopupTitle>
      <TrblPopupContent style={{ overflowX: 'hidden' }}>
        <Stack margin={'0 -20px'} display={'flex'} position={'relative'}>
          <Stack width={'380px'} margin={'0 auto 12px'}>
            <Stepper steps={['Upload file', 'Import model']} stepSelected={stepSelected} />
          </Stack>

          <Divider />
        </Stack>
        <Stack
          margin={'0 -20px -16px'}
          width={'calc(100% + 40px)'}
          height={'calc(100% - 23px)'}
          flexDirection={'row'}
          position={'relative'}
          overflow={'hidden'}>
          {stepSelected === 0 && (
            <MultiSpaceImportStep1
              file={file}
              setFile={setFile}
              importStarted={importStarted}
              setImportStarted={setImportStarted}
              setMultiSpaceResults={setMultiSpaceResults}
              setImportError={setImportError}
            />
          )}

          {stepSelected === 1 && multiSpaceResults && file && (
            <MultiSpaceImportStep2
              multiSpaceResults={multiSpaceResults}
              thisFilename={file?.name}
              uploadFinished={uploadModelDone}
              goBack={goBack}
            />
          )}
        </Stack>
      </TrblPopupContent>

      {stepSelected === 0 && (
        <Stack
          width={'100%'}
          flexDirection={'row'}
          position={'relative'}
          overflow={'hidden'}
          justifyContent={'right'}
          flexShrink={0}>
          <TrblPopupActions framed>
            <Box component="div" display="flex" justifyContent="flex-end" alignItems={'center'} gap={2} width={'100%'}>
              {importError && (
                <div style={{ color: '#ff8a8a', maxWidth: '680px', lineHeight: '1.25' }}>{importError}</div>
              )}

              <PrimaryButton
                width={'auto'}
                label="Upload file"
                disabled={!file || importStarted || importError !== null}
                onClick={handleStartImport}
              />
            </Box>
          </TrblPopupActions>
        </Stack>
      )}
    </TrblPopup>
  );
};
