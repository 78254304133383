import React, { useMemo } from 'react';

import { useReflectogramResultsContext } from '@/components/Results/context/ReflectogramResultsContext';

import { TIME_OF_ARRIVAL_SCALES } from '@/components/Results/components/SubHeaders/ReflectogramResultsHeader/constants';
import { ReflectionPath } from './ReflectionPath';

import { getTimeOfArrivalColor } from './utils';

import { View3DType } from '@/context/EditorContext/types';

export type ReflectogramResultsProps = {
  view3D: View3DType;
};

export const ReflectogramResults: React.FC<ReflectogramResultsProps> = ({ view3D }) => {
  const {
    state: { filteredReflectionsData, selectedReflectionIndex, selectedScale, reflectionsDataDownloadInfo },
  } = useReflectogramResultsContext();

  const selectedTimeOfArrivalScale = TIME_OF_ARRIVAL_SCALES.find((scale) => scale.id === selectedScale);

  const reflectionsToDisplay = useMemo(() => {
    if (selectedReflectionIndex !== null) {
      return [filteredReflectionsData[selectedReflectionIndex]];
    }
    return filteredReflectionsData;
  }, [filteredReflectionsData, selectedReflectionIndex]);

  return (
    <>
      {reflectionsToDisplay.map((reflection, index) => (
        <ReflectionPath
          key={`${reflectionsDataDownloadInfo!.id}-${index}`}
          points={reflection.path}
          color={getTimeOfArrivalColor(
            reflection.timeOfArrivalRelative,
            selectedTimeOfArrivalScale!.timeOfArrivalGroups
          )}
          lineWidth={view3D === 'shaded' ? 0.5 : 1.5}
        />
      ))}
    </>
  );
};
