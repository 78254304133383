import { ResultComparisonAction } from './actions';

import { ActionType } from '../constants';

import {
  getAvailableResultTypes,
  getDefaultResultType,
  getSourcesAndGridReceivers,
  getSourcesAndReceivers,
} from '../utils';

import { ResultComparisonState } from '../../../types';
import { SolveTask } from '@/types';

export const ResultComparisonReducer = (
  state: ResultComparisonState,
  action: ResultComparisonAction
): ResultComparisonState => {
  switch (action.type) {
    case ActionType.SELECT_SIMULATION: {
      const { selectedSimulation, spaceName, modelName } = action.payload;
      return {
        ...state,
        modelName,
        spaceName,
        title: selectedSimulation.name,
        simulationId: selectedSimulation.id,
        selectedSimulation: { ...selectedSimulation },
      };
    }

    case ActionType.UPDATE_SOLVE_RESULTS: {
      const lastSolveResults = action.payload;

      const availableResultTypes = getAvailableResultTypes(lastSolveResults.sourceResults);
      const defaultResultType = getDefaultResultType(availableResultTypes, state.resultType);
      const { selectedSourceObject, selectedReceiverObjects, availableSources, availableReceivers } =
        getSourcesAndReceivers(lastSolveResults, defaultResultType, state.sourcePointId, state.receiverPointIds);

      return {
        ...state,
        lastSolveResults,
        availableResultTypes,
        resultType: defaultResultType,
        availableSources,
        sourcePointId: selectedSourceObject.sourcePointId,
        availableReceivers,
        receiverPointIds: state.receiverPointIds ?? selectedReceiverObjects?.map((x) => x.pointId),
        simulationData: {
          ...state.simulationData,
          selectedSourceObject,
          selectedReceiverObjects,
        },
      };
    }
    case ActionType.UPDATE_GRID_RECEIVERS_SOLVE_RESULTS: {
      const availableResultTypes = getAvailableResultTypes(action.payload);
      // For grid receivers we always default to Hybrid result (if available)
      const defaultResultType = getDefaultResultType(availableResultTypes);
      const { selectedSourceObject, selectedGridReceiverObjects, availableSources, availableGridReceivers } =
        getSourcesAndGridReceivers(action.payload, defaultResultType, state.sourcePointId, state.gridReceiverPointIds);

      return {
        ...state,
        lastGridResults: action.payload,
        availableResultTypes,
        resultType: defaultResultType,
        availableSources,
        sourcePointId: selectedSourceObject?.sourcePointId,
        availableGridReceivers,
        gridReceiverPointIds: selectedGridReceiverObjects?.map((x) => x.pointId) || [],
        simulationData: {
          ...state.simulationData,
          selectedSourceObject,
          selectedGridReceiverObjects,
        },
      };
    }
    case ActionType.UPDATE_GRID_RECEIVER_RESULT_PARAMETERS: {
      return {
        ...state,
        simulationData: {
          ...state.simulationData,
          gridReceiverParameterValues: action.payload,
        },
      };
    }
    case ActionType.UPDATE_LABEL: {
      return {
        ...state,
        title: action.payload,
      };
    }
    case ActionType.SELECT_SOURCE: {
      const newSourcePointId = action.payload;
      const { availableReceivers, selectedSourceObject, selectedReceiverObjects } = getSourcesAndReceivers(
        state.lastSolveResults as SolveTask,
        state.resultType,
        newSourcePointId,
        state.receiverPointIds
      );

      return {
        ...state,
        sourcePointId: newSourcePointId,
        availableReceivers,
        receiverPointIds: selectedReceiverObjects?.map((x) => x.pointId) || [],
        simulationData: {
          ...state.simulationData,
          selectedSourceObject,
          selectedReceiverObjects,
        },
      };
    }
    case ActionType.SELECT_RECEIVERS: {
      const receiverPointIds = action.payload;
      const { selectedSourceObject, selectedReceiverObjects } = getSourcesAndReceivers(
        state.lastSolveResults as SolveTask,
        state.resultType,
        state.sourcePointId,
        receiverPointIds
      );

      return {
        ...state,
        receiverPointIds,
        simulationData: {
          ...state.simulationData,
          selectedSourceObject,
          selectedReceiverObjects,
        },
      };
    }

    case ActionType.SELECT_GRID_SOURCE: {
      const newSourcePointId = action.payload;

      if (!state.lastGridResults) {
        return state;
      }
      const { availableGridReceivers, selectedSourceObject, selectedGridReceiverObjects } = getSourcesAndGridReceivers(
        state.lastGridResults,
        state.resultType,
        newSourcePointId,
        state.gridReceiverPointIds
      );

      return {
        ...state,
        sourcePointId: newSourcePointId,
        availableGridReceivers,
        gridReceiverPointIds: selectedGridReceiverObjects?.map((x) => x.pointId),
        simulationData: {
          ...state.simulationData,
          selectedSourceObject,
          selectedGridReceiverObjects,
        },
      };
    }

    case ActionType.SELECT_GRID_RECEIVERS: {
      const gridReceiverPointIds = action.payload;
      const { selectedSourceObject, selectedGridReceiverObjects } = getSourcesAndGridReceivers(
        state.lastGridResults || [],
        state.resultType,
        state.sourcePointId,
        gridReceiverPointIds
      );

      return {
        ...state,
        gridReceiverPointIds,
        simulationData: {
          ...state.simulationData,
          selectedSourceObject,
          selectedGridReceiverObjects,
        },
      };
    }
    case ActionType.SELECT_RESULT_TYPE: {
      // when the result type changes we need to select new sources,
      // new receivers and new simulation data
      const resultType = action.payload as string;
      const { selectedSourceObject, selectedReceiverObjects, availableSources, availableReceivers } =
        getSourcesAndReceivers(
          state.lastSolveResults as SolveTask,
          resultType,
          state.sourcePointId,
          state.receiverPointIds
        );

      return {
        ...state,
        resultType,
        availableSources,
        sourcePointId: selectedSourceObject.sourcePointId,
        availableReceivers,
        receiverPointIds: selectedReceiverObjects?.map((x) => x.pointId) || [],
        simulationData: {
          ...state.simulationData,
          selectedSourceObject,
          selectedReceiverObjects,
        },
      };
    }
  }
};
