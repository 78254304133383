import { FC, useEffect, useState } from 'react';

import { useResultsContext } from '@/components/Results/context/ResultsContext';
import { ActionType, useEditorContext } from '@/context/EditorContext';

import { Box } from '@mui/material';

import { TabButton, TabButtons } from '@/components/Shared/TabButtons';
import { useFeatureFlags } from '@/components/FeatureToggles';

import { ResultsView } from '@/context/EditorContext/types';

import styles from './styles.module.scss';

export const ResultsHeader: FC = () => {
  const { dispatch, resultsView } = useEditorContext();
  const { availableComparisons } = useResultsContext();

  const [active, setActive] = useState(false);
  const [canAccessGridreceivers, setCanAccessGridreceivers] = useState(false);
  const [canAccessReflectionResults, setCanAccessReflectionResults] = useState(false);

  const { reflectogramFeature } = useFeatureFlags();

  useEffect(() => {
    setActive(true);
  }, []);

  useEffect(() => {
    const resultsHaveGridRecevierResults = availableComparisons.some(
      (comparison) => comparison.formState?.selectedSimulation?.lastSimulationRun?.gridReceivers?.length
    );
    const resultsHaveReflectionResults = availableComparisons.some((comparison) =>
      comparison.formState?.lastSolveResults?.sourceResults
        .find((x) => x.resultType === 'GA')
        ?.taskResultForReceiver[0].receiverResults?.some((x) => x.resultType === 'reflection')
    );
    setCanAccessGridreceivers(resultsHaveGridRecevierResults);
    setCanAccessReflectionResults(resultsHaveReflectionResults);
  }, [availableComparisons]);

  const handleTabChanged = (selectedTab: string) => {
    if (Object.values(ResultsView).includes(selectedTab as ResultsView)) {
      dispatch({
        type: ActionType.SET_RESULTS_VIEW,
        payload: selectedTab as ResultsView,
      });
    }
  };

  const tabOptions: TabButton[] = [
    { key: ResultsView.ResultsReportView, icon: 'analytics', tooltip: 'Report view' },
    { key: ResultsView.ResultsModelView, icon: 'box3D', tooltip: 'Model view' },
    {
      key: ResultsView.ResultsGridReceiversView,
      disabled: !canAccessGridreceivers,
      icon: 'heatmap',
      tooltip: canAccessGridreceivers
        ? 'Surface receivers view'
        : 'Surface receivers view (simulation does not have any surface receivers)',
    },
  ];

  if (reflectogramFeature) {
    tabOptions.push({
      key: ResultsView.ResultsReflectogramView,
      disabled: !canAccessReflectionResults,
      icon: 'reflection',
      tooltip: canAccessReflectionResults
        ? 'Reflection results view'
        : 'Reflection results view (simulation does not have any reflection results)',
    });
  }

  return (
    <div className={`${styles['results-header']}  ${active ? styles['active'] : ''}`}>
      <div className={styles['left-content']}></div>
      <div className={styles['right-content']}>
        <Box component="div" width={reflectogramFeature ? '320px' : '240px'}>
          <TabButtons options={tabOptions} selectedValue={resultsView} onChange={handleTabChanged} />
        </Box>
      </div>
    </div>
  );
};
