import { FC } from 'react';

import { IconProps } from '@/types';

export const TrblAddIcon: FC<IconProps> = ({ fill = '#DADADA', width = '14', height = '14' }) => (
  <svg width={width} height={height} viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="14" height="14" rx="7" fill={fill} />
    <path d="M7 4V10M10 7L4 7" stroke="#171717" strokeWidth="1.5" strokeLinecap="round" />
  </svg>
);
