import { TrblTooltip } from '@/components/Shared';
import { PrimaryButton } from '@/components/Shared/Buttons';

import { usePerformLambdaMaterialFitting } from '@/hooks';

import { getValuesByPropName } from '../utils/getValuesByPropName';

import { ComplexData } from '../types';
import { MaterialDto } from '@/types';

export const LambdaMaterialFit = ({
  data,
  inputType,
  disabled,
  formDisabled,
  setFormDisabled,
  setFittedData,
  specificImpedance,
}: {
  data: ComplexData;
  inputType: string;
  disabled: boolean;
  formDisabled: boolean | undefined;
  setFormDisabled: React.Dispatch<React.SetStateAction<boolean | undefined>>;
  setFittedData: React.Dispatch<React.SetStateAction<MaterialDto | null>>;
  specificImpedance?: boolean;
}) => {
  const { mutate: fitMaterial } = usePerformLambdaMaterialFitting();

  const calculateAbsorption = () => {
    setFormDisabled(true);
    const realValues = getValuesByPropName('real', data);
    const imagValues = getValuesByPropName('imag', data);
    fitMaterial(
      {
        inputType,
        coefficientsReal: realValues,
        coefficientsImaginary: imagValues,
        ...(specificImpedance && { specificImpedance }),
      },
      {
        onSuccess: (fitResponse) => {
          setFittedData(fitResponse);
          setFormDisabled(false);
        },
        onError: () => {
          setFormDisabled(false);
        },
      }
    );
  };

  return (
    <TrblTooltip
      title={
        disabled
          ? 'You need to provide all 24 frequencies'
          : formDisabled
          ? 'Calculating absorption'
          : 'Calculate absorption'
      }>
      <span>
        <PrimaryButton
          width="fit-content"
          label="Calculate absorption"
          disabled={disabled || formDisabled}
          onClick={calculateAbsorption}
        />
      </span>
    </TrblTooltip>
  );
};
