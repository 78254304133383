import { ClickAwayListener, IconButton, Stack } from '@mui/material';

import { TrblIcon } from '@/components/Icons';
import { useLibraryPanelContext } from '@/components/LibraryPanel/LibraryPanelContext';
import { GridReceiverRow } from './GridReceiverRow';

import { useGridReceivers } from '../hooks/useGridReceivers';
import { usePoints } from '../hooks/usePoints';

import { GridReceiver } from '@/types';

import styles from '../styles.module.scss';

export const SurfaceReceiversContent = ({
  gridReceivers,
  isDisabled,
  readonly,
}: {
  gridReceivers: GridReceiver[];
  isDisabled: boolean;
  readonly: boolean;
}) => {
  const { selectedPointId, newPointId } = useGridReceivers();
  const { handleSelectionChange } = usePoints();
  const { selectedSource } = useLibraryPanelContext();
  const {
    handleAddGridReceiver,
    handleGridReceiverVisibility,
    handleGridReceiverCoordinateChange,
    handleGridReceiverParamChange,
    handleGridReceiverSizeChange,
    handleGridReceiverLabelChange,
    handleRemoveGridReceiver,
  } = useGridReceivers();

  return (
    <Stack spacing={'3px'} mb={'15px'} overflow="auto" flex="1 1 0">
      <div className={`${styles.add_receivers} ${readonly ? 'hidden' : ''}`}>
        <IconButton
          disabled={gridReceivers.length >= 100 || isDisabled}
          onClick={handleAddGridReceiver}
          title="Add new surface receiver"
          aria-label="Add new surface receiver">
          <TrblIcon icon="add" color={gridReceivers.length >= 100 ? '#474747' : undefined} hoverColor="#f5f5f5" />
        </IconButton>
      </div>
      <>
        {gridReceivers.length > 0 ? (
          <>
            {gridReceivers.map((gr, index) => {
              return (
                <ClickAwayListener
                  key={gr.id}
                  mouseEvent={selectedPointId === gr.id ? undefined : false}
                  onClickAway={(e) => {
                    if (!selectedSource && !(e.target instanceof Element && e.target.tagName === 'CANVAS')) {
                      handleSelectionChange(undefined);
                    }
                  }}>
                  <GridReceiverRow
                    label={gr.label}
                    userInput={gr.userInput}
                    index={index}
                    isSelected={selectedPointId === gr.id}
                    isDisabled={isDisabled}
                    readonly={readonly}
                    isNewPoint={newPointId === gr.id}
                    onSelect={() => handleSelectionChange(gr.id, 'GridReceiver')}
                    onDelete={() => handleRemoveGridReceiver(gr.id)}
                    onChangeAxis={(axis, value) =>
                      handleGridReceiverCoordinateChange(
                        index,
                        axis === 'x' ? value : gr.userInput.centerPoint.x,
                        axis === 'y' ? value : gr.userInput.centerPoint.y,
                        axis === 'z' ? value : gr.userInput.centerPoint.z
                      )
                    }
                    onChangeParam={(param, value) => handleGridReceiverParamChange(index, param, value)}
                    onChangeSize={(value) => handleGridReceiverSizeChange(index, value)}
                    onChangeLabel={(value) => handleGridReceiverLabelChange(index, value)}
                    onChangingGridReceiverVisibility={handleGridReceiverVisibility}
                  />
                </ClickAwayListener>
              );
            })}
            <p className={styles['small-text']}>
              Length and width are rounded to a multiple of the spacing value, changing it can affect the size of the
              receiver.
            </p>
          </>
        ) : (
          <p className={styles.no_receivers__message}> You have no surface receivers</p>
        )}
      </>
    </Stack>
  );
};
